import { Component, OnInit ,HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map, mergeMap } from 'rxjs/operators';
import { RouterOutlet } from '@angular/router';
import { AuthService } from './services/auth.service';


declare const liff: any;

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'oneagripro_webapp';

  constructor(
    private router: Router, 
    private activatedRoute: ActivatedRoute, 
    private titleService: Title,
    private authService: AuthService
  ) {}

  ngOnInit() {
    // ตรวจสอบสถานะการล็อกอินหรือเซสชันหมดอายุ
    if (!this.authService.isLoggedIn()) {
      
      setTimeout(() => {
        const uuid = this.router.routerState.snapshot.root.queryParams['uuid'];
        console.log("UUID (after delay):", uuid);
        
          
        // if (!liff.isLoggedIn()) {
        //   liff.login(); // Redirect ผู้ใช้ไปหน้า Login
        // }
        

        if (this.router.url.includes('login-confirm-terms-conditions')) {
          // alert('123')
        } else {
          localStorage.setItem('url_redirect', this.router.url);
          this.router.navigate(['/login']);
        }
      }, 150);
    }
    // else{
    //   //   localStorage.clear();
    //   if(this.router.url != ''){
    //     localStorage.setItem('url_redirect', this.router.url);
    //     this.router.navigate(['/login']);
    //   }else{
    //     this.router.navigate(['/login-confirm-terms-conditions']);
    //   }
      
    // }

    // ฟังการเปลี่ยนแปลงของ route เพื่ออัปเดต title
    this.router.events.pipe(
      filter(event => event.constructor.name === 'NavigationEnd'),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data)
    )
    .subscribe((data) => {
      if (data['title']) {
        this.titleService.setTitle(data['title']);
      }
    });
  }

  redirectToSavedUrl() {
    const redirectUrl = localStorage.getItem('url_redirect');
    if (redirectUrl) {
      localStorage.removeItem('url_redirect'); // ลบค่า URL หลังจากใช้งานเสร็จ
      this.router.navigateByUrl(redirectUrl);
    } else {
      this.router.navigate(['/login-confirm-success']);
    }
  }

  // ปิดการ Multi-Touch หรือ Gesture
  // @HostListener('touchstart', ['$event'])
  // onTouchStart(event: TouchEvent): void {
  //   if (event.touches.length > 1) {
  //     event.preventDefault(); // ป้องกัน Multi-Touch
  //   }
  // }

  // ปิดการ Context Menu
  // @HostListener('contextmenu', ['$event'])
  // onContextMenu(event: MouseEvent): void {
  //   event.preventDefault(); // ป้องกันการแตะค้างเพื่อเปิดเมนู
  // }
}
