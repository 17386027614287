import { Component, OnInit, Inject, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { CalendarOptions, DatesSetArg } from '@fullcalendar/core';

import { isPlatformBrowser, CommonModule } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-fullcalendar-cane',
  standalone: true,
  imports: [FullCalendarModule, CommonModule],
  templateUrl: './fullcalendar-cane.component.html',
  styleUrls: ['./fullcalendar-cane.component.css'],
  encapsulation: ViewEncapsulation.None  // ปิด encapsulation เพื่อให้ CSS ใช้ได้กับ FullCalendar
})
export class FullcalendarCaneComponent implements OnInit {
  id: string | null = null;
  calendarOptions: any;
  bp_role_data: any[] = [];

  isLoadingAPI: boolean = false;
  data_list: Array<{ date: string; data: any[] }> = [];
  html_list: string | null = null;
  token: string | null = null;

  data: any;

  selectedDate: string = '';
  currentMonth: string = '';
  currentYear: string = '';
  previousMonth: string = '';
  previousYear: string = '';

  text_html: string = '';

  isLoadingAPIdashboard: boolean = false;


  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');

    // Only initialize the calendar options if running in the browser
    if (isPlatformBrowser(this.platformId)) {
      this.initializeCalendar();
      const storedbp_role_data = localStorage.getItem('bp_role_data');
      this.bp_role_data = storedbp_role_data ? JSON.parse(storedbp_role_data) : [];
      console.log(this.bp_role_data);
    }

    setTimeout(() => {
      this.isLoadingAPIdashboard = true;
    }, 3000);
  }

  initializeCalendar() {
    this.calendarOptions = {
      plugins: [dayGridPlugin, interactionPlugin],
      initialView: 'dayGridMonth',
      headerToolbar: {
        left: 'prev,next',
        center: 'title',
        right: ''
      },
      events: [],
      eventColor: '#378006',
      locale: 'th',
      showNonCurrentDates: false,
      fixedWeekCount: false,
      height: 'auto',
      dateClick: this.handleDateClick.bind(this), // เพิ่ม event handler
      datesSet: this.handleDatesSet.bind(this),
      displayEventTime: false, // ปิดการแสดงเวลาในอีเวนต์ทั้งหมด


    };
  }

  async handleDateClick(arg: any) {
    try {
      this.isLoadingAPI = false;

      // ลบคลาส 'active' ออกจากวันที่ก่อนหน้า
      const prevActiveDate = document.querySelector('.fc-daygrid-day.active');
      if (prevActiveDate) {
        prevActiveDate.classList.remove('active');
      }

      // เพิ่มคลาส 'active' ให้กับวันที่ที่ถูกเลือก
      if (arg.dayEl) {
        arg.dayEl.classList.add('active');
      }

      // อัปเดตวันที่ที่เลือก
      this.selectedDate = arg.dateStr; // รูปแบบ 'YYYY-MM-DD'

      // ดึงเดือนและปีจาก dateStr
      const [year, month] = this.selectedDate.split('-');

      // ตรวจสอบว่าเป็นเดือนและปีเดียวกันหรือไม่
      if (this.currentMonth !== month || this.currentYear !== year) {
        console.log('New month/year detected. Calling API...');
        this.currentMonth = month;
        this.currentYear = year;

        // เรียก API เพื่อโหลดข้อมูลใหม่
        await this.getCalendar(this.currentMonth, this.currentYear);
      }

      // กรองข้อมูลตามวันที่ที่เลือก
      this.processEventsForSelectedDate();
    } catch (error) {
      console.error('Error handling date click:', error);
    }
  }

  async handleDatesSet(arg: DatesSetArg) {
    const startDate = arg.start;
    this.currentMonth = (startDate.getMonth() + 1).toString().padStart(2, '0');
    this.currentYear = startDate.getFullYear().toString();

    console.log('Current Month:', this.currentMonth);
    console.log('Current Year:', this.currentYear);

    try {
      if (!this.selectedDate) {
        const today = new Date();
        this.selectedDate = [
          today.getFullYear(),
          (today.getMonth() + 1).toString().padStart(2, '0'),
          today.getDate().toString().padStart(2, '0')
        ].join('-');
        console.log('First Load - Default Selected Date:', this.selectedDate);
      } else {
        this.selectedDate = [
          startDate.getFullYear(),
          (startDate.getMonth() + 1).toString().padStart(2, '0'),
          '01'
        ].join('-');
        console.log('Changed Month - Default Selected Date:', this.selectedDate);
      }

      // เรียก API
      await this.getCalendar(this.currentMonth, this.currentYear);

      // กรองข้อมูลตามวันที่ที่เลือก
      this.processEventsForSelectedDate();
    } catch (error) {
      console.error('Error handling dates set:', error);
    }
  }

  processEventsForSelectedDate() {
    try {
      const eventsForSelectedDate = this.filterEventsByDate(this.selectedDate);
      console.log(`Events for selected date (${this.selectedDate}):`, eventsForSelectedDate);
      this.text_html = '';

      eventsForSelectedDate.forEach((event: any) => {
        const data = event.data;
        data.forEach((item: any) => {
          const plotString = item.plotString ?? '-';
          const queueName = item.queueName ?? '-';
          const queueNo = item.queueNo ?? '-';
          const dlvLicensePlate = item.dlvLicensePlate ?? '-';
          const bpDlv = item.bpDlv ?? '-';
          const status_css = item.status_css ?? '-';
          const crateDate = this.convertDateTime(item.crateDate) ?? '-';
          const detail_to = item.detail_to ?? '-';
          const harvestNo = item.harvestNo ?? '-';
          const faccode = item.faccode ?? '-';
          const beYear = item.beYear ?? '-';

          let firstname = '';
          if (bpDlv) {
            const bpDlvParts = bpDlv.split(' ');
            firstname = bpDlvParts[1] ?? '';
          }

          const url_href =
            detail_to === 'cr'
              ? `order-cutting-cane-detail?faccode=${faccode}&harvestNo=${harvestNo}&by_year=${beYear}`
              : `order-cutting-cane?faccode=${faccode}&harvestNo=${harvestNo}`;

          this.text_html += `
          <div class="col-md-6">
            <a href="${url_href}">
              <div class="box-data-order-cutting">
                <div class="row">
                  <div class="col-12 text-start p-header">
                    <span class="dot-${status_css}"></span> ${crateDate}
                  </div>
                </div>
                <div class="row">
                  <div class="col-7 text-start p-header">
                    <img class="img-icon-cane" src="image/icon/icon-cane.svg"> แปลง ${plotString}
                  </div>
                  <div class="col text-end p-header">${queueName}</div>
                </div>
                <div class="row">
                  <div class="col text-start p-lable">ผู้รับเหมา : ${firstname}</div>
                  <div class="col text-end p-lable">${queueNo}</div>
                </div>
                <div class="row">
                  <div class="col-8 text-start p-lable">
                    <img class="img-icon-cane" src="image/icon/icon-truck-cane.svg"> ${dlvLicensePlate}
                  </div>
                </div>
              </div>
            </a>
          </div>`;
        });
      });

      this.isLoadingAPI = true;
    } catch (error) {
      console.error('Error processing events for selected date:', error);
    }
  }


  filterEventsByDate(selectedDate: string): any[] {
    // ตรวจสอบว่า data_list มีข้อมูลและเป็น Array ก่อนกรอง
    if (!Array.isArray(this.data_list)) {
      console.warn('Data list is not an array or is undefined');
      return [];
    }
    console.log('selectedDate: ' + this.selectedDate);
    console.log(this.data_list);
    // กรองข้อมูลตาม selectedDate โดยระบุชนิดของ item
    return this.data_list.filter((item: { date: string; data: any[] }) => item.date === selectedDate);
  }

  // processDataToEvents(dataList: any[]): any[] {
  //   const events: any[] = [];
  
  //   dataList.forEach(item => {
  //     const date = item.date; // ดึงวันที่จาก item
  //     const data = item.data; // ดึงข้อมูลใน data
  
  //     if (date && Array.isArray(data)) {
  //       data.forEach(subitem => {
  //         let eventColor = '';
  
  //         // ตรวจสอบสีตาม status_css
  //         if (subitem.status_css === 'blue') {
  //           eventColor = '#4C6EF5'; // สีสำหรับ 'รอขนส่ง'
  //         } else if (subitem.status_css === 'success') {
  //           eventColor = '#4CAF50'; // สีสำหรับ 'เสร็จสิ้น'
  //         }
  
  //         // เพิ่มข้อมูลลงใน Event
  //         events.push({
  //           title: subitem.status_name, // ชื่อเหตุการณ์
  //           start: `${date}T${subitem.start ?? '08:00:00'}`, // วันที่และเวลาเริ่มต้น
  //           end: `${date}T${subitem.end ?? '17:00:00'}`, // วันที่และเวลาสิ้นสุด (ถ้ามี)
  //           allDay: false, // เหตุการณ์ที่ไม่ได้ครอบคลุมทั้งวัน
  //           color: eventColor, // สีของเหตุการณ์
  //         });
  //       });
  //     }
  //   });
  
  //   return events;
  // }

  processDataToEvents(dataList: any[]): any[] {
    const events: any[] = [];
    
    // กรองข้อมูลจาก subitem.status_name โดยเลือกเฉพาะตัวแรกของแต่ละกลุ่ม
    const filteredDataList = dataList.map(item => {
      const date = item.date; // ดึงวันที่จาก item
      const data = item.data; // ดึงข้อมูลใน data
      
      if (date && Array.isArray(data)) {
        // ใช้ Map เพื่อกรองข้อมูลตาม status_name
        const statusMap = new Map<string, any>();
        data.forEach(subitem => {
          if (!statusMap.has(subitem.status_name)) {
            statusMap.set(subitem.status_name, subitem); // เก็บ subitem ตัวแรกของแต่ละ status_name
          }
        });
  
        // แปลง Map กลับเป็น Array
        return {
          date,
          data: Array.from(statusMap.values()),
        };
      }
  
      return item; // ถ้าไม่มี date หรือ data ไม่เป็น Array ให้คืนค่าตามเดิม
    });
  
    // แปลงข้อมูลที่กรองแล้วเป็น events
    filteredDataList.forEach(item => {
      const date = item.date; // ดึงวันที่จาก item
      const data = item.data; // ดึงข้อมูลใน data
      
      if (date && Array.isArray(data)) {
        data.forEach(subitem => {
          let eventColor = '';
  
          // ตรวจสอบสีตาม status_css
          if (subitem.status_css === 'blue') {
            eventColor = '#4C6EF5'; // สีสำหรับ 'รอขนส่ง'
          } else if (subitem.status_css === 'success') {
            eventColor = '#4CAF50'; // สีสำหรับ 'เสร็จสิ้น'
          }
  
          // เพิ่มข้อมูลลงใน Event
          events.push({
            title: subitem.status_name, // ชื่อเหตุการณ์
            start: `${date}T${subitem.start ?? '08:00:00'}`, // วันที่และเวลาเริ่มต้น
            end: `${date}T${subitem.end ?? '17:00:00'}`, // วันที่และเวลาสิ้นสุด (ถ้ามี)
            allDay: false, // เหตุการณ์ที่ไม่ได้ครอบคลุมทั้งวัน
            color: eventColor, // สีของเหตุการณ์
          });
        });
      }
    });
  
    return events;
  }
  
  

  getCalendar(month: string, year: string): Promise<void> {
    return new Promise((resolve, reject) => {
      let storedbp_role_data: string | null = null;
      this.isLoadingAPI = false;

      if (isPlatformBrowser(this.platformId)) {
        storedbp_role_data = localStorage.getItem('bp_role_data');
        this.token = localStorage.getItem('token');
      }

      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'token': String(this.token),
      });

      const body = {
        month: month,
        year: year,
        bp_role: storedbp_role_data || '',
      };

      this.apiService.post('getCalendar', body, headers).subscribe(
        data => {
          this.data = data;

          if (this.data.code === 1) {
            this.isLoadingAPI = true;
            this.data_list = this.data.data || [];
            console.log('Data list loaded:', this.data_list);

            // ประมวลผล data_list เป็น events
            const events = this.processDataToEvents(this.data_list);
            console.log('Events processed:', events);

            this.calendarOptions = {
              ...this.calendarOptions, // เก็บค่าที่มีอยู่เดิม
              events: events, // เพิ่ม events ใหม่
            };

            resolve(); // การทำงานเสร็จสิ้น
          } else {
            console.error('Error from API:', this.data.message);
            reject(this.data.message);
          }
        },
        error => {
          console.error('API call failed:', error);
          reject(error);
        }
      );
    });
  }

  loadEvents() {


    return [
      {
        title: 'รอขนส่ง',
        start: '2024-10-21',
        end: '2024-10-21',
        color: '#4C6EF5'
      },
      {
        title: 'เสร็จสิ้น',
        start: '2024-10-21',
        end: '2024-10-21',
        color: '#4CAF50'
      },

    ];
  }


  convertDateTime(dateTimeString: string): string {
    if (dateTimeString && dateTimeString.trim() !== '') {
      // แยกส่วนวันที่ (DD/MM/BBBB) และเวลา (HH.MM) โดยใช้ substring
      const day = dateTimeString.substring(8, 10); // ดึงวันที่ (DD)
      const month = dateTimeString.substring(5, 7); // ดึงเดือน (MM)
      const year = String(Number(dateTimeString.substring(0, 4)) + 543); // แปลงปี ค.ศ. เป็น พ.ศ. (BBBB)
      const date = `${day}/${month}/${year}`;

      const hours = dateTimeString.substring(11, 13); // ดึงชั่วโมง (HH)
      const minutes = dateTimeString.substring(14, 16); // ดึงนาที (MM)
      const time = `${hours}.${minutes}`;

      return `${date} ${time}`;
    } else {
      return '';
    }

  }
}
