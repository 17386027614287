<div class="main-container-home">
  <div class="backdrop-loading" *ngIf="!isLoadingAPIdashboard">
    <div class="row">
      <div class="col text-center">
        <div class="loader"></div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row bg-white">
      <div class="col">
        <div class="div-hearder">
          สวัสดี, {{ firstName }}
          <span class="text_bpRole">({{ text_bpRole }})</span>
        </div>
      </div>
      <!-- <div class="col-auto text-end">
        <div class="div-hearder-logout">
          <a href="javascript:void(0)" (click)="logout()">ออกจากระบบ</a>
        </div>
      </div> -->
    </div>
  </div>
  <div class="bg-sky-blue">
    <div class="container">
      <!-- เฉพาะ ผรม -->
      <div class="row" *ngIf="contractors && !quota">
        <div class="col-4">
          <a href="order-cutting-cane-list">
            <div class="box-order-cutting">
              <div class="row">
                <div class="col-12 text-center">
                  <img src="image/icon/icon-order-cutting-contractors.svg" />
                </div>
                <div class="col-12 text-center mt-2">
                  <p class="p-header-order-cutting">ใบสั่ง<br />ตัดอ้อย</p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="col-4">
          <a href="q-to-weight-in-cane">
            <div class="box-order-cutting">
              <div class="row">
                <div class="col-12 text-center">
                  <img src="image/icon/icon-truck-contractors.svg" />
                </div>
                <div class="col-12 text-center mt-2">
                  <p class="p-header-order-cutting">คิว<br />ลานนอก</p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="col-4">
          <a href="callweightqueue">
            <div class="box-order-cutting">
              <div
                class="d-flex align-items-center justify-content-center"
                style="flex-direction: column"
              >
                <div class="">
                  <img src="image/icon/icon-truck-full-contractors.svg" />
                </div>
                <div
                  class=""
                  style="
                    min-height: 40px;
                    display: inline-flex;
                    align-items: flex-end;
                  "
                >
                  <p class="p-header-order-cutting text-center">คิวเข้าชั่ง</p>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="row" style="margin-top: 15px" *ngIf="contractors && !quota">
        <div class="col-4">
          <a href="history-transport-cane">
            <div class="box-order-cutting">
              <div class="row">
                <div class="col-12 text-center">
                  <img
                    src="image/icon/icon-order-cutting-list-contractors.svg"
                  />
                </div>
                <div class="col-12 text-center mt-2">
                  <p class="p-header-order-cutting">ประวัติ<br />ส่งอ้อย</p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="col-4">
          <a href="#">
            <div class="box-order-cutting">
              <div class="row">
                <div class="col-12 text-center">
                  <img src="image/icon/driver-contractors.svg" />
                </div>
                <div class="col-12 text-center mt-2">
                  <p class="p-header-order-cutting">กิจกรรม<br />รับเหมา</p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="col-4">
          <a href="fullcalendar">
            <div class="box-order-cutting">
              <div
                class="d-flex align-items-center justify-content-center"
                style="flex-direction: column"
              >
                <div class="">
                  <img src="image/icon/icon-calendar-contractors.svg" />
                </div>
                <div
                  class=""
                  style="
                    min-height: 40px;
                    display: inline-flex;
                    align-items: flex-end;
                  "
                >
                  <p class="p-header-order-cutting text-center">ปฏิทินงาน</p>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="row" style="margin-top: 15px" *ngIf="contractors && !quota">
        <div class="col-6">
          <a href="#">
            <div class="box-order-cutting">
              <div class="d-flex align-items-center justify-content-center">
                <div class="" style="padding-right: 15px">
                  <img src="image/icon/doc-contractors.svg" />
                </div>
                <div class="">
                  <p class="p-header-order-cutting text-center">
                    เอกสาร<br />การเงิน
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="col-6">
          <a href="dashboard">
            <div class="box-order-cutting">
              <div class="d-flex align-items-center justify-content-center">
                <div class="" style="padding-right: 15px">
                  <img src="image/icon/dashboard-contractors.svg" />
                </div>
                <div class="">
                  <p class="p-header-order-cutting text-center">
                    ภาพรวม<br />ของฉัน
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <!-- เฉพาะ ผรม -->

      <!-- เฉพาะ ชาวไร่ -->
      <div class="row align-items-center" *ngIf="!contractors && quota">
        <div class="col-4">
          <a href="order-cutting-cane-list">
            <div class="box-order-cutting">
              <div class="row">
                <div class="col-12 text-center">
                  <img src="image/icon/icon-order-cutting-quota.svg" />
                </div>
                <div class="col-12 text-center mt-2">
                  <p class="p-header-order-cutting">ใบสั่ง<br />ตัดอ้อย</p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="col-4">
          <a href="q-to-weight-in-cane">
            <div class="box-order-cutting">
              <div class="row">
                <div class="col-12 text-center">
                  <img src="image/icon/icon-truck-quota.svg" />
                </div>
                <div class="col-12 text-center mt-2">
                  <p class="p-header-order-cutting">คิว<br />ลานนอก</p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="col-4">
          <a href="callweightqueue">
            <div class="box-order-cutting">
              <div class="row">
                <div class="col-12 text-center">
                  <img src="image/icon/icon-truck-full-quota.svg" />
                </div>
                <div class="col-12 text-center mt-2">
                  <p class="p-header-order-cutting">คิว<br />เข้าชั่ง</p>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div
        class="row align-items-center"
        style="margin-top: 15px"
        *ngIf="!contractors && quota"
      >
        <div class="col-6">
          <a href="billordercane">
            <div class="box-order-cutting">
              <div class="d-flex align-items-center justify-content-center">
                <div class="" style="padding-right: 15px">
                  <img src="image/icon/bill-quota.svg" />
                </div>
                <div class="">
                  <p class="p-header-order-cutting text-center">
                    บิล<br />รับอ้อย
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="col-6">
          <a href="fullcalendar">
            <div class="box-order-cutting">
              <div class="d-flex align-items-center justify-content-center">
                <div class="" style="padding-right: 15px">
                  <img src="image/icon/calendar-quota.svg" />
                </div>
                <div
                  class=""
                  style="
                    min-height: 40px;
                    display: inline-flex;
                    align-items: center;
                  "
                >
                  <p class="p-header-order-cutting text-center">ปฏิทินงาน</p>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div
        class="row align-items-center"
        style="margin-top: 15px"
        *ngIf="!contractors && quota"
      >
        <div class="col-6">
          <a href="#">
            <div class="box-order-cutting">
              <div class="d-flex align-items-center justify-content-center">
                <div class="" style="padding-right: 15px">
                  <img src="image/icon/doc-quota.svg" />
                </div>
                <div class="">
                  <p class="p-header-order-cutting text-center">
                    เอกสาร<br />การเงิน
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="col-6">
          <a href="dashboard">
            <div class="box-order-cutting">
              <div class="d-flex align-items-center justify-content-center">
                <div class="" style="padding-right: 15px">
                  <img src="image/icon/dashboard-quota.svg" />
                </div>
                <div class="">
                  <p class="p-header-order-cutting text-center">
                    ภาพรวม<br />ของฉัน
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <!-- เฉพาะ ชาวไร่ -->

      <!-- เฉพาะ ผรม / ชาวไร่ -->
      <div class="row" *ngIf="contractors && quota">
        <div class="col-4">
          <a href="order-cutting-cane-list">
            <div class="box-order-cutting">
              <div class="row">
                <div class="col-12 text-center">
                  <img src="image/icon/icon-order-cutting-cont-quo.svg" />
                </div>
                <div class="col-12 text-center mt-2">
                  <p class="p-header-order-cutting">ใบสั่ง<br />ตัดอ้อย</p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="col-4">
          <a href="q-to-weight-in-cane">
            <div class="box-order-cutting">
              <div class="row">
                <div class="col-12 text-center">
                  <img src="image/icon/icon-truck-cont-quo.svg" />
                </div>
                <div class="col-12 text-center mt-2">
                  <p class="p-header-order-cutting">คิว<br />ลานนอก</p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="col-4">
          <a href="callweightqueue">
            <div class="box-order-cutting">
              <div
                class="d-flex align-items-center justify-content-center"
                style="flex-direction: column"
              >
                <div class="">
                  <img src="image/icon/icon-truck-full-cont-quo.svg" />
                </div>
                <div
                  class=""
                  style="
                    min-height: 40px;
                    display: inline-flex;
                    align-items: flex-end;
                  "
                >
                  <p class="p-header-order-cutting text-center">คิวเข้าชั่ง</p>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="row" style="margin-top: 15px" *ngIf="contractors && quota">
        <div class="col-4">
          <a href="billordercane">
            <div class="box-order-cutting">
              <div class="row">
                <div class="col-12 text-center">
                  <img src="image/icon/bill-cont-quo.svg" />
                </div>
                <div class="col-12 text-center mt-2">
                  <p class="p-header-order-cutting">บิล<br />รับอ้อย</p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="col-4">
          <a href="#">
            <div class="box-order-cutting">
              <div class="row">
                <div class="col-12 text-center">
                  <img src="image/icon/driver-cont-quo.svg" />
                </div>
                <div class="col-12 text-center mt-2">
                  <p class="p-header-order-cutting">กิจกรรม<br />รับเหมา</p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="col-4">
          <a href="fullcalendar">
            <div class="box-order-cutting">
              <div
                class="d-flex align-items-center justify-content-center"
                style="flex-direction: column"
              >
                <div class="">
                  <img src="image/icon/calender-cont-quo.svg" />
                </div>
                <div
                  class=""
                  style="
                    min-height: 40px;
                    display: inline-flex;
                    align-items: flex-end;
                  "
                >
                  <p class="p-header-order-cutting text-center">ปฏิทินงาน</p>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="row" style="margin-top: 15px" *ngIf="contractors && quota">
        <div class="col-6">
          <a href="#">
            <div class="box-order-cutting">
              <div class="d-flex align-items-center justify-content-center">
                <div class="" style="padding-right: 15px">
                  <img src="image/icon/doc-cont-quo.svg" />
                </div>
                <div class="">
                  <p class="p-header-order-cutting text-center">
                    เอกสาร<br />การเงิน
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="col-6">
          <a href="dashboard">
            <div class="box-order-cutting">
              <div class="d-flex align-items-center justify-content-center">
                <div class="" style="padding-right: 15px">
                  <img src="image/icon/dashboard-cont-quo.svg" />
                </div>
                <div class="">
                  <p class="p-header-order-cutting text-center">
                    ภาพรวม<br />ของฉัน
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <!-- เฉพาะ ผรม / ชาวไร่ -->
    </div>
  </div>

  <div class="container">
    <!-- ประวัติส่งอ้อย -->
    <div class="row bg-white row-history-send-cane">
      <div class="col text-start">
        <span class="p-history-send-cane">ประวัติการส่งอ้อย</span>
      </div>
      <div class="col text-end">
        <a href="history-transport-cane" class="a-all-history-send-cane"
          >ดูทั้งหมด
          <img src="image/icon/icon-click-next.svg" class="icon-click-next"
        /></a>
      </div>
    </div>
    <div class="row bg-white">
      <div class="col-12">
        <span style="font-size: 16px; color: #545454; margin-right: 5px"
          >สถานะ:</span
        >
        <span style="font-size: 16px; color: #000; margin-right: 5px"
          ><img src="image/icon/grid-blud.svg" /> รอดำเนินการ</span
        >
        <span style="font-size: 16px; color: #000"
          ><img src="image/icon/grid-green.svg" /> เสร็จสิ้น</span
        >
      </div>
    </div>
    <!-- ประวัติส่งอ้อย -->

    <div class="row mt-5" *ngIf="!isLoadingAPI">
      <div class="col text-center">
        <div class="loader"></div>
      </div>
    </div>
    <div
      class="row bg-white html_list"
      [innerHTML]="html_list"
      *ngIf="isLoadingAPI"
    >
      <!-- <div class="col-md-6">
                  <a href="order-cutting-cane-detail">
                      <div class="box-history-cutting-cane-list">
                          <div class="row">
                              <div class="col-6 text-start p-lable pr-0"><img class="img-icon-cane" src="image/icon/icon-calendar.svg">วันที่ส่ง: 21/10/2024</div>
                              <div class="col text-end p-lable pl-0">หนองคอนไทย หมู่ 7</div>
                          </div>
                          <div class="row">
                              <div class="col-4 text-start p-lable"><img class="img-icon-cane" src="image/icon/icon-clock.svg">08:00</div>
                              <div class="col-8 text-end p-header">แปลงอ้อย 101156789</div>
                          </div>
                          <div class="row">
                              <div class="col-8 text-start p-lable">ชาวไร่ : ประสงค์  อ้อยสวยงาม</div>
                              <div class="col-4 text-end p-lable"><span class="badge bg-blue">รอดำเนินการ</span></div>
                          </div>
                      </div>
                  </a>
              </div> -->
    </div>
    <div class="row mt-3 mb-5" *ngIf="count_data_list == 25">
      <span class="text-center">
        <a href="history-transport-cane" class="a-all-history-send-cane border" style="
            padding: 15px;
            border-radius: 12px;
            background-color: #fff;"> ดูทั้งหมด <img src="image/icon/icon-click-next.svg" class="icon-click-next"/>
        </a>
      </span>
    </div>
  </div>
</div>
