import { Component, OnInit, ViewChild, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { ApiService } from '../services/api.service';
import { Router } from '@angular/router';
import { isPlatformBrowser, CommonModule } from '@angular/common';
import { AuthService } from '../services/auth.service';
import liff from '@line/liff';
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-login-confirm',
  standalone: true,
  templateUrl: './login-confirm.component.html',
  styleUrls: ['./login-confirm.component.css'],
  imports: [CommonModule] // เพิ่ม CommonModule ใน imports
})
export class LoginConfirmComponent implements OnInit {
  @ViewChild('numberOtp') numberOtp!: ElementRef;

  private liffid = environment.liffid;

  data: any;
  otp_token: string | null = null;
  user_id: string | null = null;
  phone: string | null = null;
  isOtpInvalid: boolean = false;
  isLoading: boolean = false;
  isError: boolean = false;
  uuid: string | null = null;
  status_relogin: string | null = null;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const rawPhone = localStorage.getItem('phone');
      console.log(rawPhone);
      if (rawPhone) {
        this.phone = this.formatPhone(rawPhone);
      }
      this.status_relogin = localStorage.getItem('status_relogin');
    }
  }

  formatPhone(phone: string): string {
    return phone.substring(0, 3) + '-' + phone.substring(3, 6) + '-xxxx';
  }

  validateOTP() {
    if (isPlatformBrowser(this.platformId)) {
      this.otp_token = localStorage.getItem('otp_token');
      this.user_id = localStorage.getItem('uuid');
    }

    const rawPhone = localStorage.getItem('phone');
    this.isLoading = true; 
    this.isOtpInvalid = false;
    const otp_code = this.numberOtp.nativeElement.value.trim();
    if(this.status_relogin == 'true'){
      if (this.otp_token && otp_code.length == 6) {
        const body = { otp_token: this.otp_token, otp_code, telephone: rawPhone };
        this.apiService.post('checkLoginbyOTP', body).subscribe(
          data => {
            this.isLoading = false;
            this.data = data;
            if (this.data.code == 1) {
              this.isOtpInvalid = false;
              const token = this.data.token;
              const idCard = this.data.idcard;
              const result = this.data.data;
              if (Array.isArray(result)) {
                result.forEach((item: any) => {
                });
                if (navigator.userAgent.includes("Line")) {
                  // รันใน LINE Browser
                  liff.init({ liffId: this.liffid }); // แทนด้วย LIFF ID ของคุณ
                  console.log('Running in LIFF');
                  
                  // บันทึกข้อมูลลง localStorage
                  localStorage.setItem('bp_role_data', JSON.stringify(this.data.data));
                  // alert('LIFF: bp_role_data: ' + JSON.stringify(this.data.data));
                  localStorage.setItem('token', token);
                  localStorage.setItem('people_id', this.data.people_id);
                  localStorage.setItem('idCard', idCard!);
                } else if (typeof window !== 'undefined' && window.localStorage) {
                  // รันใน Browser ทั่วไป
                  console.log('Running in general browser');
                
                  // บันทึกข้อมูลลง localStorage
                  localStorage.setItem('bp_role_data', JSON.stringify(this.data.data));
                  // alert('Browser: bp_role_data: ' + JSON.stringify(this.data.data));
                  localStorage.setItem('token', token);
                  localStorage.setItem('people_id', this.data.people_id);
                  localStorage.setItem('idCard', idCard!);
                } else {
                  console.error('localStorage is not available in this environment');
                }
              }

              this.authService.login(idCard!);
            } else {
              this.isOtpInvalid = true;
            }
          },
          error => {
            this.isOtpInvalid = true;
            this.isLoading = false; 
            console.error('OTP validation failed', error);
          }
        );
      }
    }else{
      if (this.otp_token && otp_code.length == 6 && this.user_id) {
        
        const body = { otp_token: this.otp_token, otp_code, telephone: rawPhone , user_id: this.user_id };
        this.apiService.post('OTPValidate', body).subscribe(
          data => {
            this.isLoading = false;
            this.data = data;
            // alert('data :'+this.data);
            if (this.data.code == 1) {
              
              this.isOtpInvalid = false;
              const token = this.data.token;
              const idCard = localStorage.getItem('idCard');
              const result = this.data.data;
              if (Array.isArray(result)) {
                result.forEach((item: any) => {
                });
                // if (isPlatformBrowser(this.platformId)) {
                //   localStorage.setItem('bp_role_data', JSON.stringify(this.data.data));
                //   localStorage.setItem('token', token);
                //   localStorage.setItem('people_id', this.data.people_id);
                //   localStorage.setItem('idCard', idCard!);
                // }
                if (navigator.userAgent.includes("Line")) {
                  // รันใน LINE Browser
                  liff.init({ liffId: this.liffid }); // แทนด้วย LIFF ID ของคุณ
                  console.log('Running in LIFF');
                  
                  // บันทึกข้อมูลลง localStorage
                  localStorage.setItem('bp_role_data', JSON.stringify(this.data.data));
                  // alert('LIFF: bp_role_data: ' + JSON.stringify(this.data.data));
                  localStorage.setItem('token', token);
                  localStorage.setItem('people_id', this.data.people_id);
                  localStorage.setItem('idCard', idCard!);
                } else if (typeof window !== 'undefined' && window.localStorage) {
                  // รันใน Browser ทั่วไป
                  console.log('Running in general browser');
                
                  // บันทึกข้อมูลลง localStorage
                  localStorage.setItem('bp_role_data', JSON.stringify(this.data.data));
                  // alert('Browser: bp_role_data: ' + JSON.stringify(this.data.data));
                  localStorage.setItem('token', token);
                  localStorage.setItem('people_id', this.data.people_id);
                  localStorage.setItem('idCard', idCard!);
                } else {
                  console.error('localStorage is not available in this environment');
                }
              }
              this.authService.login(idCard!);
            } else {
              this.isOtpInvalid = true;
            }
          },error => {
            this.isOtpInvalid = true;
            this.isLoading = false; 
            console.error('OTP validation failed', error);
          }
        );
      }
    }
    
  }

  requestOTP() {
    this.isLoading = true;
    this.isError = false;
    const phone = localStorage.getItem('phone');
    
  
    if (phone) {
      if(this.status_relogin == 'true'){
        const body = { phone: phone};
        this.apiService.post('checkLoginbyTelephone', body).subscribe(
          data => {
            this.isLoading = false;
            this.data = data;
    
            if (isPlatformBrowser(this.platformId)) {
              localStorage.setItem('otp_token', this.data.data.otp_token);
              
              this.phone = this.formatPhone(phone);
            }
          },
          error => {
            this.isLoading = false;
            this.isError = true;
            console.error('checkLoginbyTelephone failed', error);
          }
        );
      }else{
        const body = { phone: phone };
        this.apiService.post('OTPSend', body).subscribe(data => {
          console.log(data);
          this.data = data; // เก็บข้อมูลจาก API
          
          if (isPlatformBrowser(this.platformId)&& this.data.data.otp_token) {
            localStorage.setItem('otp_token', this.data.data.otp_token);
          }
        },
        error => {
          this.isLoading = false;
          this.isError = true;
          console.error('OTPSend failed', error);
        });
      }
      
    }
  }
}
