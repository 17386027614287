import { Component, OnInit, ViewChild, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ApiService } from '../services/api.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformBrowser, CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

interface ApiResponse {
  code: number;
  message: string;
  data: [];
}

@Component({
  selector: 'app-callweightqueue',
  standalone: true,
  imports: [CommonModule,FormsModule],
  templateUrl: './callweightqueue.component.html',
  styleUrl: './callweightqueue.component.css'
})
export class CallweightqueueComponent implements OnInit  {

  @ViewChild('factory_code') factory_code!: ElementRef;



  data: any; 
  bp_role_data: string | null = null;
  firstName: string = 'ไม่มีชื่อ';
  
  isLoadingAPI: boolean = false;

  
  data_list: any; 
  html_list: string | null = null;
  token: string | null = null;

  cuttocrush_total: number | null = 0;
  cr_total: number | null = 0;

  factoryList: any;

  selectedFactoryCode: string = '';
  message: string = '';

  isLoadingAPIdashboard: boolean = false;


  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: object // ตรวจสอบ Platform
  ) {}


  async ngOnInit(): Promise<void> {
    await this.getDDLWeightQueue();
    
    
  }

  async getDDLWeightQueue(): Promise<void> {
    let people_id: string | null = null;
    people_id = localStorage.getItem('people_id');


    const params = new HttpParams().set('people_id', people_id || '');
    try {
      // renderWeightQue //getDDLWeightQueue
      this.apiService.get<ApiResponse>('getDDLWeightQueue', params).subscribe((response) => {
        this.data = response;
        console.log(response);
        if(response.code == 1){
          this.factoryList = response.data;
          console.log(this.factoryList);

          this.selectedFactoryCode = this.factoryList[0].value;
          console.log('Default Selected Factory Code:', this.selectedFactoryCode);
          
          // Trigger onchange manually for the first load
          this.getDataWeightQueue(this.selectedFactoryCode);
        }
    
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  getDataWeightQueue(factoryCode: string): void {
    console.log(`Fetching data for factory code: ${factoryCode}`);
    this.isLoadingAPI = false;
    this.data_list  = [];
  
    if (isPlatformBrowser(this.platformId)) {
      this.token = localStorage.getItem('token');
    }
  
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'token': String(this.token),
    });
  
    const body = { 
      faccode: factoryCode || '' 
    };
    //getWeightQue //getDataWeightQueue
    this.apiService.post('getDataWeightQueue', body, headers).subscribe(
      data => {
        this.data = data;
        console.log(this.data);
        if (this.data.code === 1) {
          console.log(this.data.data);

          if(this.data.data){
            this.data_list = this.data.data;
          }
          
          this.message = this.data.message;

          setTimeout(() => {
            this.isLoadingAPIdashboard = true;
          }, 1200);
    
        } else if (this.data.code === 2) {
          const message = this.data.message;
          console.log(message);
  
          if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem('msgError', message);
          }
  
          this.router.navigate(['/error-view']);
        }
        this.isLoadingAPI = true;
      },
      error => {
        this.isLoadingAPI = true;
        console.error('API call failed', error);
      }
    );
  }

  onFactoryChange(event: Event): void {
    const selectedValue = (event.target as HTMLSelectElement).value;
    this.selectedFactoryCode = selectedValue;
    console.log('Selected Factory Code:', this.selectedFactoryCode);

    // You can now use the selected value to fetch data or perform other actions
    this.getDataWeightQueue(this.selectedFactoryCode);
  }
}
