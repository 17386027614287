<div class="main-dashboard-container">
  <div class="backdrop-loading" *ngIf="!isLoadingAPIdashboard">
  <!-- <div class="backdrop-loading"> -->
    <div class="row">
      <div class="col text-center">
          <div class="loader"></div>
      </div>
    </div>
  </div>
  <div class="container">

    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label for="" class="p-label">โรงงาน</label>
          <select name="facecode" id="facecode" class="form-select" #facecodeSelect   (change)="onFacecodeChange()">
            <option *ngFor="let item of list_facecode" [value]="item.value">{{item.name}}</option>
          </select>
        </div>
      </div>
      <div class="col-12 mt-3">
        <div class="form-group">
          <label for="" class="p-label">ปีรับซื้ออ้อย</label>
          <select name="beyear" id="beyear" class="form-select" #beyearSelect (change)="onBeyearChange()">
            <option *ngFor="let item of list_beyear" [value]="item.value">{{item.name}}</option>
          </select>
        </div>
      </div>
      <div class="col-12 mt-3" *ngIf="legacyBpType == 'QUOTA' && list_bpcode_quota.length > 1">
        <div class="form-group">
          <label for="" class="p-label">เลือก BP Code</label>
          <select name="bpcode_quota" id="bpcode_quota" class="form-select" #bpcode_quota (change)="onbpCodeQuotaChange()">
            <option *ngFor="let item of list_bpcode_quota" [value]="item.value">{{item.name}}</option>
          </select>
        </div>
      </div>
      <div class="col-12 mt-3" *ngIf="legacyBpType == 'QUOTA' && list_bpcode_quota.length == 1">
        <div class="form-group">
          <label for="" class="p-label">เลือก BP Code</label>
          <input type="text" class="form-control" value="{{list_bpcode_quota[0].name}}" readonly>
        </div>
      </div>
      <div class="col-12 mt-3" *ngIf="legacyBpType == 'CONTRACTOR' && list_bpcode_quota.length > 1">
        <div class="form-group">
          <label for="" class="p-label">เลือก BP Code</label>
          <select name="bpcode_contractor" id="bpcode_contractor" class="form-select" #bpcode_contractor (change)="onbpCodeConChange()">
            <option *ngFor="let item of list_bpcode_contractor" [value]="item.value">{{item.name}}</option>
          </select>
        </div>
      </div>
      <div class="col-12 mt-3" *ngIf="legacyBpType == 'CONTRACTOR' && list_bpcode_contractor.length == 1">
        <div class="form-group">
          <label for="" class="p-label">เลือก BP Code</label>
          <input type="text" class="form-control" value="{{list_bpcode_contractor[0].name}}" readonly>
        </div>
      </div>
    </div>



    <!-- กรณีที่มีเฉพาะ ชาวไร่ -->
    <div class="row mt-3" *ngIf="legacyBpType == 'QUOTA'">
      <div class="col-md-12">
        <div class="card box-objective-send-cane">
          <div class="card-body">
            <div class="col-md-12">
              <div class="row">
                <div class="col-12 col-sm-6">
                  <div class="text-center p-target">
                    เป้าหมายส่งอ้อย {{ beYear }}
                  </div>
                  <div class="box-hc-pie">
                    <highcharts-chart
                      [Highcharts]="Highcharts"
                      [options]="gauge_chartOptions"
                    >
                    </highcharts-chart>
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="box-list-box-data-pie">
                    <div class="box-data-pie">
                      <div class="data-pie">
                        <span class="p-data-pie">อ้อยสด</span>
                        <span class="p-data-pie">{{ fresh_sugarcane }}%</span>
                      </div>
                      <div class="progress">
                        <div
                          class="progress-bar bg-success"
                          [style.width.%]="fresh_sugarcane"
                          role="progressbar"
                          [attr.aria-valuenow]="fresh_sugarcane"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                    <div class="box-data-pie mt-2">
                      <div class="data-pie">
                        <span class="p-data-pie">อ้อยไฟไหม้</span>
                        <span class="p-data-pie">{{ sugarcane_on_fire }}%</span>
                      </div>
                      <div class="progress">
                        <div
                          class="progress-bar bg-danger"
                          [style.width.%]="sugarcane_on_fire"
                          role="progressbar"
                          [attr.aria-valuenow]="sugarcane_on_fire"
                          aria-valuenow="50"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3" *ngIf="legacyBpType == 'QUOTA'">
      <div class="col-md-12">
        <div class="card card-total-custocush">
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-sm-6">
                <div class="box-hc-line">
                  <highcharts-chart
                    [Highcharts]="Highcharts"
                    [options]="gauge_css_chartOptions"
                  >
                  </highcharts-chart>
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="box-list-box-data-css">
                  <div class="row">
                    <div class="box-list-box-data-pie">
                      <div class="box-data-ccs">
                        <span class="p-data-ccs">CCS สูงสุด</span>
                        <span class="p-data-ccs"
                          > <img src="image/icon/icon-polygon-blue.svg" /> {{maxccs}}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="box-list-box-data-pie">
                      <div class="box-data-ccs">
                        <span class="p-data-ccs">CCS ต่ำสุด</span>
                        <span class="p-data-ccs"
                          > <img src="image/icon/icon-polygon-red.svg" /> {{minccs}}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3 mb-5" *ngIf="legacyBpType == 'QUOTA'">
      <div class="col-12">
        <div class="card card-total-custocush">
          <div class="card-body">
            <div class="row">
              <div class="col-6 text-left">
                <label for="" class="p-label-ccs">ค่า ccs รายแปลง</label>
              </div>
              <div class="col-6 text-end">
                <label for="" class="p-label-ccs">10 ลำดับแรก</label>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12">
                <div class="table-responsive">
                  <table class="table" #dataTable id="dataTable">
                    <thead>
                      <tr>
                        <th class="border-right-white">
                          เลขแปลงอ้อย
                        </th>
                        <th (click)="sortTable('cane_weight')" [class.sortable]="true" class="border-right-white">
                          <span class="sort-icon"  *ngIf="sortKey === 'cane_weight'">
                            {{ sortDirection === 'asc' ? '▲': '▼' }}
                          </span>
                          ตันอ้อยรวม 
                       
                        </th>
                        <th (click)="sortTable('avg_ccs')" [class.sortable]="true">
                          <span class="sort-icon"  *ngIf="sortKey === 'avg_ccs'">
                            {{ sortDirection === 'asc' ?  '▲': '▼' }}
                          </span>
                          CCS
                       
                        </th>
                      </tr>
                    </thead>
                    <tbody *ngIf="sortedData.length > 0">
                      <tr *ngFor="let item of sortedData; let i = index">
                        <td class="border-right-white">
                          <div class="index-circle"><img src="image/icon/{{i+1}}.svg"> {{ item.plot_code }}</div> 
                        </td>
                        <td class="border-right-white text-end">{{ item.cane_weight }} ตัน</td>
                        <td class="text-end" >{{ item.avg_ccs }}</td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="sortedData.length == 0">
                      <tr>
                        <td class="text-center" colspan="3">ไม่พบข้อมูล</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- กรณีที่มีเฉพาะ ชาวไร่ -->

    <!-- กรณีที่มีเฉพาะ ชาวไร่/ผรม -->
    <div class="row" *ngIf="legacyBpType == 'CONTRACTOR_QUOTA'">
      <div class="col-12">
        <ul class="nav nav-pills mt-3 mb-3" id="pills-main-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link {{ nav_link_active_quota }}"
              id="pills-profile-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-profile"
              type="button"
              role="tab"
              aria-controls="pills-profile"
              aria-selected="false"
              (click)="clickTabs('QUOTA')"
            >
              ชาวไร่
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link {{ nav_link_active_contractor }}"
              id="pills-home-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-home"
              type="button"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
              (click)="clickTabs('CONTRUCTOR')"
            >
              ผู้รับเหมา
            </button>
          </li>
        </ul>
        <div class="tab-content" id="pills-main-tabContent">
          <div
            class="tab-pane fade {{ tab_pane_quota_show_active }}"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
          >
            <div class="row" *ngIf="list_bpcode_quota.length > 1">
              <div class="col-12">
                <div class="form-group">
                  <label for="" class="p-label">เลือก BP Code</label>
                  <select name="bpcode_quota_tap" id="bpcode_quota_tap" class="form-select" #bpcode_quota_tap (change)="onbpCodeQuotaTapChange()">
                    <option *ngFor="let item of list_bpcode_quota" [value]="item.value">{{item.name}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="list_bpcode_quota.length == 1">
              <div class="col-12" >
                <div class="form-group">
                  <label for="" class="p-label">เลือก BP Code</label>
                  <input type="text" class="form-control" value="{{list_bpcode_quota[0].name}}" readonly>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12">
                <div class="card box-objective-send-cane">
                  <div class="card-body">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-12 col-sm-6">
                          <div class="text-center p-target">
                            เป้าหมายส่งอ้อย {{ beYear }}
                          </div>
                          <div class="box-hc-pie">
                            <highcharts-chart
                              [Highcharts]="Highcharts"
                              [options]="gauge_chartOptions"
                            >
                            </highcharts-chart>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="box-list-box-data-pie">
                            <div class="box-data-pie">
                              <div class="data-pie">
                                <span class="p-data-pie">อ้อยสด</span>
                                <span class="p-data-pie"
                                  >{{ fresh_sugarcane }}%</span
                                >
                              </div>
                              <div class="progress">
                                <div
                                  class="progress-bar bg-success"
                                  [style.width.%]="fresh_sugarcane"
                                  role="progressbar"
                                  [attr.aria-valuenow]="fresh_sugarcane"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                            </div>
                            <div class="box-data-pie mt-3">
                              <div class="data-pie">
                                <span class="p-data-pie">อ้อยไฟไหม้</span>
                                <span class="p-data-pie"
                                  >{{ sugarcane_on_fire }}%</span
                                >
                              </div>
                              <div class="progress">
                                <div
                                  class="progress-bar bg-danger"
                                  [style.width.%]="sugarcane_on_fire"
                                  role="progressbar"
                                  [attr.aria-valuenow]="sugarcane_on_fire"
                                  aria-valuenow="50"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12">
                <div class="card card-total-custocush">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12 col-sm-6">
                        <div class="box-hc-line">
                          <highcharts-chart
                            [Highcharts]="Highcharts"
                            [options]="gauge_css_chartOptions"
                          >
                          </highcharts-chart>
                        </div>
                      </div>
                      <div class="col-12 col-sm-6">
                        <div class="box-list-box-data-css">
                          <div class="row">
                            <div class="box-list-box-data-pie">
                              <div class="box-data-ccs">
                                <span class="p-data-ccs">CCS สูงสุด</span>
                                <span class="p-data-ccs"
                                  ><img
                                    src="image/icon/icon-polygon-blue.svg"
                                  />{{ maxccs }}</span
                                >
                              </div>
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="box-list-box-data-pie">
                              <div class="box-data-ccs">
                                <span class="p-data-ccs">CCS ต่ำสุด</span>
                                <span class="p-data-ccs"
                                  ><img
                                    src="image/icon/icon-polygon-red.svg"
                                  />{{ minccs }}</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3 mb-5">
              <div class="col-12">
                <div class="card card-total-custocush">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-6 text-left">
                        <label for="" class="p-label-ccs">ค่า ccs รายแปลง</label>
                      </div>
                      <div class="col-6 text-end">
                        <label for="" class="p-label-ccs">10 ลำดับแรก</label>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-12">
                        <div class="table-responsive">
                          <table class="table" #dataTable id="dataTable">
                            <thead>
                              <tr>
                                <th class="border-right-white">
                                  เลขแปลงอ้อย
                                </th>
                                <th (click)="sortTable('cane_weight')" [class.sortable]="true" class="border-right-white">
                                  <span class="sort-icon"  *ngIf="sortKey === 'cane_weight'">
                                    {{ sortDirection === 'asc' ? '▲' : '▼' }}
                                  </span>
                                  ตันอ้อยรวม 
                               
                                </th>
                                <th (click)="sortTable('avg_ccs')" [class.sortable]="true">
                                  <span class="sort-icon"  *ngIf="sortKey === 'avg_ccs'">
                                    {{ sortDirection === 'asc' ? '▲' : '▼' }}
                                  </span>
                                  CCS
                                
                                </th>
                              </tr>
                            </thead>
                            <tbody *ngIf="sortedData.length > 0">
                              <tr *ngFor="let item of sortedData; let i = index">
                                <td class="border-right-white">
                                  <div class="index-circle"><img src="image/icon/{{i+1}}.svg"> {{ item.plot_code }}</div> 
                                </td>
                                <td class="border-right-white text-end">{{ item.cane_weight }} ตัน</td>
                                <td class="text-end">{{ item.avg_ccs }}</td>
                              </tr>
                            </tbody>
                            <tbody *ngIf="sortedData.length == 0">
                              <tr>
                                <td class="text-center" colspan="3">ไม่พบข้อมูล</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade {{ tab_pane_contractor_show_active }}"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <div class="row" *ngIf="list_bpcode_contractor.length > 1">
              <div class="col-12" >
                <div class="form-group">
                  <label for="" class="p-label">เลือก BP Code</label>
                  <select name="bpcode_contractor_tap" id="bpcode_contractor_tap" class="form-select" #bpcode_contractor_tap (change)="onbpCodeConTapChange()">
                    <option *ngFor="let item of list_bpcode_contractor" [value]="item.value">{{item.name}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="list_bpcode_contractor.length == 1">
              <div class="col-12" >
                <div class="form-group">
                  <label for="" class="p-label">เลือก BP Code</label>
                  <input type="text" class="form-control" value="{{list_bpcode_contractor[0].name}}" readonly>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12">
                <div class="card card">
                  <div class="card-body">
                    <div class="col-12">
                      <div class="text-left p-target">
                        เป้าหมายส่งอ้อย {{ beYear }}
                      </div>
                    </div>
                    <div class="col-12 mt-2">
                      <div class="row">
                        <div class="col-12 col-sm-6">
                          <div class="box-truck-con">
                            <div class="box-truck-con-img">
                              <img
                                class="mb-2"
                                src="image/icon/icon-truck-con.svg"
                              />
                              <br />
                              <span class="truck-con-send-cane mt-2"
                                >ส่งอ้อยแล้ว</span
                              >
                            </div>
                            <div class="box-truck-con-data">
                              <span class="truck-con-data"
                                ><b>{{ transport_contractor }}</b> ตัน</span
                              >
                              <br />
                              <span class="truck-con-data"
                                ><b>{{ round_contractor }}</b> เที่ยว</span
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="box-list-box-data-pie">
                            <div class="box-data-pie">
                              <div class="data-pie">
                                <span class="p-data-pie">อ้อยสด</span>
                                <span class="p-data-pie"
                                  >{{ fresh_sugarcane_con }}%</span
                                >
                              </div>
                              <div class="progress">
                                <div
                                  class="progress-bar bg-success"
                                  [style.width.%]="fresh_sugarcane_con"
                                  role="progressbar"
                                  [attr.aria-valuenow]="fresh_sugarcane_con"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                            </div>
                            <div class="box-data-pie mt-2">
                              <div class="data-pie">
                                <span class="p-data-pie">อ้อยไฟไหม้</span>
                                <span class="p-data-pie"
                                  >{{ sugarcane_on_fire_con }}%</span
                                >
                              </div>
                              <div class="progress">
                                <div
                                  class="progress-bar bg-danger"
                                  [style.width.%]="sugarcane_on_fire_con"
                                  role="progressbar"
                                  [attr.aria-valuenow]="sugarcane_on_fire_con"
                                  aria-valuenow="50"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12">
                <div class="card card-total-custocush">
                  <div class="card-body">
                    <div class="row">
                      <div
                        class="col"
                        style="display: inline-flex; align-items: flex-end"
                      >
                        <div class="p-header-ccs text-left">
                          การส่งอ้อยรายวัน
                        </div>
                      </div>
                      <div class="col-7 text-end">
                        <div
                          class="d-flex justify-content-end align-items-center"
                        >
                          <button (click)="prevMonth_tran()" class="btn-pev">
                            <img src="image/icon/icon-pev.svg" alt="Previous" />
                          </button>
                          <div class="months-name">
                            {{ text_months_tran }}
                          </div>
                          <button (click)="nextMonth_tran()" class="btn-next">
                            <img src="image/icon/icon-next.svg" alt="Next" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div style="font-size: 10px; color: #545454">
                          เที่ยวรถ
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="box-hc-line">
                          <highcharts-chart
                            [Highcharts]="Highcharts"
                            [options]="lineChartOptions_transport"
                          >
                          </highcharts-chart>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- กรณีที่มีเฉพาะ ชาวไร่/ผรม -->

    <!-- กรณีที่มีเฉพาะ ผรม -->
    <div class="row" *ngIf="legacyBpType == 'CONTRACTOR' && list_bpcode_contractor.length > 1">
      <div class="col-12" >
        <div class="form-group">
          <label for="" class="p-label">เลือก BP Code</label>
          <select name="bpcode_contractor_tap" id="bpcode_contractor_tap" class="form-select" #bpcode_contractor_tap (change)="onbpCodeConTapChange()">
            <option *ngFor="let item of list_bpcode_contractor" [value]="item.value">{{item.name}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row mt-3" *ngIf="legacyBpType == 'CONTRACTOR'">
      <div class="col-md-12">
        <div class="card card">
          <div class="card-body">
            <div class="col-12">
              <div class="text-left p-target">เป้าหมายส่งอ้อย {{ beYear }}</div>
            </div>
            <div class="col-12 mt-2">
              <div class="row">
                <div class="col-12 col-sm-6">
                  <div class="box-truck-con">
                    <div class="box-truck-con-img">
                      <img class="mb-2" src="image/icon/icon-truck-con.svg" />
                      <br />
                      <span class="truck-con-send-cane mt-2">ส่งอ้อยแล้ว</span>
                    </div>
                    <div class="box-truck-con-data">
                      <span class="truck-con-data"
                        ><b>{{ transport_contractor }}</b> ตัน</span
                      >
                      <br />
                      <span class="truck-con-data"
                        ><b>{{ round_contractor }}</b> เที่ยว</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="box-list-box-data-pie">
                    <div class="box-data-pie">
                      <div class="data-pie">
                        <span class="p-data-pie">อ้อยสด</span>
                        <span class="p-data-pie"
                          >{{ fresh_sugarcane_con }}%</span
                        >
                      </div>
                      <div class="progress">
                        <div
                          class="progress-bar bg-success"
                          [style.width.%]="fresh_sugarcane_con"
                          role="progressbar"
                          [attr.aria-valuenow]="fresh_sugarcane_con"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                    <div class="box-data-pie mt-2">
                      <div class="data-pie">
                        <span class="p-data-pie">อ้อยไฟไหม้</span>
                        <span class="p-data-pie"
                          >{{ sugarcane_on_fire_con }}%</span
                        >
                      </div>
                      <div class="progress">
                        <div
                          class="progress-bar bg-danger"
                          [style.width.%]="sugarcane_on_fire_con"
                          role="progressbar"
                          [attr.aria-valuenow]="sugarcane_on_fire_con"
                          aria-valuenow="50"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3" *ngIf="legacyBpType == 'CONTRACTOR'">
      <div class="col-md-12">
        <div class="card card-total-custocush">
          <div class="card-body">
            <div class="row">
              <div
                class="col"
                style="display: inline-flex; align-items: flex-end"
              >
                <div class="p-header-ccs text-left">การส่งอ้อยรายวัน</div>
              </div>
              <div class="col-7 text-end">
                <div class="d-flex justify-content-end align-items-center">
                  <button (click)="prevMonth_tran()" class="btn-pev">
                    <img src="image/icon/icon-pev.svg" alt="Previous" />
                  </button>
                  <div class="months-name">
                    {{ text_months_tran }}
                  </div>
                  <button (click)="nextMonth_tran()" class="btn-next">
                    <img src="image/icon/icon-next.svg" alt="Next" />
                  </button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div style="font-size: 10px; color: #545454">เที่ยวรถ</div>
              </div>
              <div class="col-12">
                <div class="box-hc-line">
                  <highcharts-chart
                    [Highcharts]="Highcharts"
                    [options]="lineChartOptions_transport"
                  >
                  </highcharts-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- กรณีที่มีเฉพาะ ผรม -->
  </div>
</div>
