import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ApiService } from '../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { Inject, PLATFORM_ID } from '@angular/core';
import { CommonModule } from '@angular/common'; // รองรับ *ngIf, *ngFor
import { FormsModule } from '@angular/forms'; // หากมีการใช้ Form ในเทมเพลต
import { AuthService } from '../services/auth.service';
import { environment } from '../../environments/environment';
import liff from '@line/liff';

interface ApiResponse {
  code: number;
  message: string;
  data: {
    html_text: string;
    factoryList: any[];
  };
}

// declare const liff: any;

@Component({
  selector: 'app-login-confirm-pdpa',
  standalone: true,
  templateUrl: './login-confirm-pdpa.component.html',
  styleUrls: ['./login-confirm-pdpa.component.css'],
  imports: [CommonModule],
  encapsulation: ViewEncapsulation.None
})
export class LoginConfirmPdpaComponent implements OnInit {
  data: any;
  uuid: string | null = null;
  html_text: SafeHtml | null = null;
  factoryList: any;
  isAgreeChecked: boolean = false; // ตัวแปรสำหรับตรวจสอบสถานะของ checkbox
  idCard: string | null = null;
  isLiffReady = false;

  private liffid = environment.liffid;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: object // ตรวจสอบ Platform
  ) { }

  ngOnInit(): void {
    localStorage.clear();
    // this.authService.logout();
    // this.main();

    // this.idCard = localStorage.getItem('idCard');
    // // if(this.idCard){
    // //   this.router.navigate(['/login-confirm-success']);
    // // }else{
    //   this.route.queryParamMap.subscribe(params => {
    //     this.uuid = params.get('uuid');
    //     console.log("UUID in login-confirm-pdpa:", this.uuid);
    //     if (isPlatformBrowser(this.platformId)&& this.uuid) {
    //       localStorage.setItem('uuid', this.uuid);
    //     }
    //   });
    // // }


    // this.apiService.get<ApiResponse>('getTermandCondition').subscribe(response => {
    //   this.data = response;
    //   console.log(response);
    //   const decodedHtml = this.decodeHtmlEntities(response.data.html_text);
    //   this.html_text = this.sanitizer.bypassSecurityTrustHtml(decodedHtml);
    //   this.factoryList = response.data.factoryList;
    //   localStorage.setItem('factoryList', JSON.stringify(this.factoryList));
    // });


    // const body = { line_user_id: this.uuid };
    // this.apiService.post('registerStatus', body).subscribe(
    //   data => {

    //     this.data = data;


    //     if(this.data.code == 1){
    //       const register_status = this.data.register_status;
    //       console.log(register_status);
    //       if(register_status || register_status == 'true'){//กรณีมีข้อมูลลงทะเบียนแล้ว

    //         this.router.navigate(['/login-confirm-success']);
    //       }
    //     }else{

    //     }

    //   },
    //   error => {

    //     console.error('API call failed', error);
    //   }
    // );


    this.initializeComponent();
  }

  private async initializeComponent(): Promise<void> {
    try {

      // รอให้ main() ทำงานเสร็จก่อน
      await this.main();
      this.isLiffReady = true;
      // หลังจาก LIFF ทำงานสำเร็จแล้ว เริ่มโหลดข้อมูลอื่น
      this.idCard = localStorage.getItem('idCard');
      // this.route.queryParamMap.subscribe((params) => {
      //   this.uuid = params.get('uuid');
      //   console.log('UUID in login-confirm-pdpa:', this.uuid);
      //   if (isPlatformBrowser(this.platformId) && this.uuid) {
      //     localStorage.setItem('uuid', this.uuid);
      //   }
      // });

      this.apiService.get<ApiResponse>('getTermandCondition').subscribe((response) => {
        this.data = response;
        console.log(response);
        const decodedHtml = this.decodeHtmlEntities(response.data.html_text);
        this.html_text = this.sanitizer.bypassSecurityTrustHtml(decodedHtml);
        this.factoryList = response.data.factoryList;
        localStorage.setItem('factoryList', JSON.stringify(this.factoryList));
      });

      // คุณสามารถเพิ่มโค้ดสำหรับตรวจสอบสถานะการลงทะเบียนได้ที่นี่
    } catch (error) {
      console.error('Error initializing component:', error);
    }
  }

  // ฟังก์ชัน main() สำหรับการจัดการ LIFF
  // private async main(): Promise<void> {
  //   try {
  //     await liff.init({
  //       liffId: '2006628566-r38zBj33',
  //       withLoginOnExternalBrowser: true,
  //     });

  //     console.log('LIFF Initialized Successfully');
  //     console.log('Is in LINE Client:', liff.isInClient());
  //     console.log('Is logged in:', liff.isLoggedIn());
  //     console.log('OS:', liff.getOS());
  //     await this.getUserProfile(); 
  //     if (!liff.isLoggedIn()) {
  //       liff.login(); // Redirect ผู้ใช้ไปหน้า Login
  //     }
  //   } catch (error) {
  //     console.error('Error initializing LIFF:', (error as Error).message);
  //   }
  // }
  private async main(): Promise<void> {
    try {
      await liff.init({
        liffId: this.liffid,//'2006628566-r38zBj33',
        withLoginOnExternalBrowser: true,
      });

      console.log('LIFF Initialized Successfully');
      console.log('Is in LINE Client:', liff.isInClient());
      console.log('Is logged in:', liff.isLoggedIn());
      console.log('OS:', liff.getOS());

      // ตรวจสอบ Query Parameter
      const urlParams = new URLSearchParams(window.location.search);
      const fromLogin = urlParams.get('fromLogin');

      if (!liff.isLoggedIn() && !fromLogin) {
        liff.login({
          redirectUri: `${window.location.href}?fromLogin=true`
        });
      } else {
        await this.getUserProfile();
      }
    } catch (error) {
      console.error('Error initializing LIFF:', (error as Error).message);
    }
  }

  decodeHtmlEntities(str: string): string {
    return str.replace(/&amp;/g, "&")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&quot;/g, '"')
      .replace(/&#039;/g, "'");
  }

  onCheckboxChange(event: Event): void {
    this.isAgreeChecked = (event.target as HTMLInputElement).checked;
  }

  onAcceptClick(): void {
    console.log('ปุ่มถูกคลิกแล้ว');
    this.router.navigate(['/login-confirm-info']);
  }

  private appendDebugInfo(key: string, value: any): void {
    console.log(`${key}:`, value);
  }

  private async getUserProfile(): Promise<void> {
    try {
      const profile = await liff.getProfile();
      console.log('User Profile:', profile);
      localStorage.setItem('uuid', profile.userId);
      // ตัวอย่างการใช้ข้อมูลผู้ใช้
      // alert(`Hello, ${profile.displayName}`);
    } catch (error) {
      this.appendDebugInfo('Error fetching profile', (error as Error).message);
    }
  }

  // private async main(): Promise<void> {
  //   try {
  //     // เริ่มต้น LIFF SDK
  //     await liff.init({
  //       liffId: '2006580063-3OMqkZ8k', // แทนที่ด้วย LIFF ID ของคุณ
  //       withLoginOnExternalBrowser: true // อนุญาตให้ทำงานใน External Browser
  //     });

  //     // เพิ่มข้อมูล Debugging
  //     this.appendDebugInfo('LIFF Initialized Successfully', true);
  //     this.appendDebugInfo('Is in LINE Client', liff.isInClient());
  //     this.appendDebugInfo('Is logged in', liff.isLoggedIn());
  //     this.appendDebugInfo('OS', liff.getOS());
  //     this.appendDebugInfo('Language', liff.getLanguage());
  //     this.appendDebugInfo('Version', liff.getVersion());

  //     // ตรวจสอบว่าอยู่ใน LINE Client หรือไม่
  //     if (!liff.isInClient()) {
  //       this.appendDebugInfo('Error', 'กรุณาเปิดผ่านแอป LINE');
  //       alert('กรุณาเปิดผ่านแอป LINE');
  //       return;
  //     }

  //     // ตรวจสอบสถานะการล็อกอิน
  //     if (!liff.isLoggedIn()) {
  //       this.appendDebugInfo('Login Status', 'Not logged in. Redirecting to LINE Login...');
  //       liff.login(); // Redirect ผู้ใช้ไปหน้า Login ของ LINE
  //     } else {
  //       this.appendDebugInfo('Login Status', 'User already logged in');
  //       await this.getUserProfile(); // ดึงข้อมูลโปรไฟล์ผู้ใช้
  //     }
  //   } catch (error) {
  //     this.appendDebugInfo('Error initializing LIFF', (error as Error).message);
  //   }
  // }
}
