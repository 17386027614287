import { Component, OnInit, ViewChild, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ApiService } from '../services/api.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

interface ApiResponse {
  
}

declare const liff: any;


@Component({
  selector: 'app-main-layout',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './main-layout.component.html',
  styleUrl: './main-layout.component.css'
})
export class MainLayoutComponent {

  data: any; 

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: object // ตรวจสอบ Platform
  ) {}

  logout():void{
    const UUID = localStorage.getItem('uuid');
    if(UUID){
      const body = { user_id: UUID };
      this.apiService.post('getLogout', body).subscribe(data => {
        console.log(data);
        this.data = data; 
        if(this.data.code == 1){
          
          localStorage.clear();
          
          this.router.navigate(['/login']);
        }
       
      });
    }else{
      localStorage.clear();
          
      this.router.navigate(['/login']);
    }
    liff.logout();
    
  }
}
