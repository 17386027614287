import { Component, AfterViewInit, Inject, PLATFORM_ID, ViewChild, ElementRef, OnInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import liff from '@line/liff';

@Component({
  selector: 'app-order-cutting-cane-detail',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './order-cutting-cane-detail.component.html',
  styleUrls: ['./order-cutting-cane-detail.component.css']
})
export class OrderCuttingCaneDetailComponent implements OnInit {
  id: string | null = null;
  isPopupVisible = false;
  isPopupVisiblereceipt = false;

  isLoadingAPIGET: boolean = false;

  faccode: string | null = null;
  harvestNo: string | null = null;
  by_year: string | null = null;



  data: any;


  token: string | null = null;



  bp_code: any;
  legacy_bp_type: any;
  bp_role_data: { bp_code: string; legacy_bp_type: string; company: string; fullname: string; }[] = [];
  bpCodeArray: string[] = [];
  legacyBpTypeArray: string[] = [];


  cr_status: string | null = null;
  css_cr_status_2: string | null = '';
  css_cr_status_3: string | null = '';
  queueName: string | null = '';
  queueNo: string | null = '';
  plotString: string | null = '';
  send_to: string | null = null;
  estimate_to_factory: string | null = null;
  extimate_to_wt: string | null = null;
  confirm_time: string | null = null;
  confirm_datetime: string | null = null;
  date_confirm: string | null = null;
  time_confirm: string | null = null;
  harvest_photo: string | null = null;
  dlv_vehicle_license_plate: string | null = '-';
  dlvName: string | null = '-';
  all_weight_timestamp: string | null = '-';
  all_weight: string | null = '-';
  vehicle_weight_timestamp: string | null = '-';
  dlv_vehicle_weight: string | null = '-';
  caneweight: string | null = '-';
  receipt_no: string | null = '-';
  cane_graden: string | null = '-';
  ccs: string | null = null;
  ccs_css: string | null = '';
  datetime_confirm: string | null = '-';
  loading_method: string | null = '-';
  plot_location: string | null = '-';
  zone: string | null = '-';
  sub_zone: string | null = '-';
  bpName: string | null = '-';

  contractors: string | null = null;
  quota: string | null = null;
  receipt_no_photo: string | null = '';


  constructor(private http: HttpClient,
    private apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  // ngOnInit(): void {
  //   this.route.queryParamMap.subscribe(params => {
  //     this.faccode = params.get('faccode');
  //     console.log("faccode:", this.faccode);
  //     this.harvestNo = params.get('harvestNo');
  //     console.log("harvestNo:", this.harvestNo);
  //     this.by_year = params.get('by_year');
  //     console.log("by_year:", this.by_year);
  //   });

  //   this.getBillHarvestOrderNo();
  // }
  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      const liffState = params.get('liff.state');
      if (liffState) {
        try {
          // ถอดรหัส liff.state สองครั้ง
          const decodedState = decodeURIComponent(decodeURIComponent(liffState));
          console.log('Decoded liff.state:', decodedState);

          // ตรวจสอบว่า decodedState มี query string หรือไม่
          if (decodedState.includes('?')) {
            // แยก query string จาก decodedState
            const queryString = decodedState.split('?')[1]; // ตัดเอาเฉพาะพารามิเตอร์
            const stateParams = new URLSearchParams(queryString); // ใช้ URLSearchParams อ่านค่าพารามิเตอร์

            // ดึงค่าพารามิเตอร์
            this.faccode = stateParams.get('faccode');
            this.harvestNo = stateParams.get('harvestNo');
            this.by_year = stateParams.get('by_year');

            // แสดงค่าผ่าน console สำหรับตรวจสอบ
            console.log("faccode:", this.faccode || 'ไม่มีข้อมูล');
            console.log("harvestNo:", this.harvestNo || 'ไม่มีข้อมูล');
            console.log("by_year:", this.by_year || 'ไม่มีข้อมูล');

            // เรียกฟังก์ชันประมวลผลหลังจากดึงค่าพารามิเตอร์สำเร็จ
            this.getBillHarvestOrderNo();
          } else {
            console.error('liff.state ไม่มี query string');
          }
        } catch (error) {
          console.error('Error decoding liff.state:', error);
        }
      } else {
        // กรณีไม่มี liff.state ให้ดึงค่าพารามิเตอร์จาก URL ปกติ
        this.faccode = params.get('faccode');
        this.harvestNo = params.get('harvestNo');
        this.by_year = params.get('by_year');

        console.log("faccode (direct):", this.faccode || 'ไม่มีข้อมูล');
        console.log("harvestNo (direct):", this.harvestNo || 'ไม่มีข้อมูล');
        console.log("by_year (direct):", this.by_year || 'ไม่มีข้อมูล');

        this.getBillHarvestOrderNo();
      }
    });
  }

  showPopup() {
    this.isPopupVisible = true;
  }

  closePopup() {
    this.isPopupVisible = false;
    this.isPopupVisiblereceipt = false;
  }

  showPopupReceipt() {
    this.isPopupVisiblereceipt = true;
  }


  // shareImage() {
  //   if (navigator.share) {
  //     if (this.harvest_photo) {
  //       navigator.share({
  //         title: 'ใบสั่งตัด',
  //         text: 'แชร์ภาพใบสั่งตัด',
  //         url: this.harvest_photo
  //       })
  //       .then(() => console.log('Successful share'))
  //       .catch((error) => console.error('Error sharing:', error));
  //     } else {
  //       console.error('No URL to share.');
  //       alert('ไม่มี URL สำหรับการแชร์');
  //     }
  //   } else {
  //     console.error('Web Share API is not supported in this browser.');
  //     alert('การแชร์ไม่รองรับในเบราว์เซอร์นี้');
  //   }
  // }

  // shareImage() {
  //   const userAgent = navigator.userAgent || navigator.vendor;

  //   // ตรวจสอบว่าเป็น LINE Browser หรือไม่
  //   const isLineBrowser = /Line/i.test(userAgent);
  //   // ตรวจสอบว่าเป็น Safari หรือไม่
  //   const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);
  //   // ตรวจสอบว่าเป็น Chrome หรือไม่
  //   const isChrome = /Chrome/i.test(userAgent);

  //   console.log('User Agent:', userAgent);
  //   console.log('isLineBrowser:', isLineBrowser);
  //   console.log('isSafari:', isSafari);
  //   console.log('isChrome:', isChrome);

  //   if (this.harvest_photo) {
  //     if (isLineBrowser) {
  //       console.log('Sharing via LINE Browser');
  //       this.shareImageViaLine();
  //     } else if ('share' in navigator) {
  //       console.log('Sharing via Web Share API');
  //       this.shareViaWebAPI();
  //     } else {
  //       console.log('Sharing is not supported. Falling back to download.');
  //       alert('เบราว์เซอร์นี้ไม่รองรับการแชร์ เราจะเพิ่มตัวเลือกสำหรับการบันทึกภาพแทน');
  //       this.downloadImage();
  //     }
  //   } else {
  //     console.error('No image to share.');
  //     alert('ไม่มีรูปภาพสำหรับการแชร์');
  //   }
  // }

  // shareImageViaLine() {
  //   // กรณี LINE Browser: สร้าง URL สำหรับหน้าแสดงภาพ
  //   fetch(this.harvest_photo!)
  //     .then(response => response.blob())
  //     .then(blob => {
  //       // แปลง Blob เป็น Object URL
  //       const imageUrl = URL.createObjectURL(blob);

  //       // แชร์ URL ใน LINE Browser
  //       const lineShareUrl = `https://social-plugins.line.me/lineit/share?url=${encodeURIComponent(imageUrl)}`;
  //       window.open(lineShareUrl, '_blank'); // เปิดลิงก์แชร์ในหน้าต่างใหม่
  //     })
  //     .catch(error => {
  //       console.error('Error fetching image for LINE Browser:', error);
  //       alert('ไม่สามารถดึงรูปภาพมาแชร์ใน LINE ได้');
  //     });
  // }

  // shareViaWebAPI() {
  //   // แชร์รูปภาพโดยตรงผ่าน Web Share API
  //   fetch(this.harvest_photo!)
  //     .then(response => response.blob())
  //     .then(blob => {
  //       const file = new File([blob], 'ใบสั่งตัด.png', { type: blob.type });

  //       navigator.share({
  //         title: 'ใบสั่งตัด',
  //         text: 'แชร์ภาพใบสั่งตัด',
  //         files: [file] // ส่งไฟล์รูปภาพโดยตรง
  //       })
  //       .then(() => console.log('Successful share'))
  //       .catch(error => {
  //         // console.error('Error sharing:', error);
  //         // alert('เกิดข้อผิดพลาดในการแชร์ กรุณาลองใหม่อีกครั้ง');
  //       });
  //     })
  //     .catch(error => {
  //       console.error('Error fetching image:', error);
  //       alert('ไม่สามารถดึงรูปภาพมาแชร์ได้');
  //     });
  // }

  // downloadImage() {
  //   // ดาวน์โหลดรูปภาพลงเครื่อง
  //   if (this.harvest_photo) {
  //     const link = document.createElement('a');
  //     link.href = this.harvest_photo || '';
  //     link.download = 'ใบสั่งตัด.png'; // ชื่อไฟล์ที่ต้องการดาวน์โหลด
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //     console.log('Image download started');
  //   } else {
  //     console.error('No URL to download.');
  //     alert('ไม่มี URL สำหรับการดาวน์โหลด');
  //   }
  // }

  shareImage() {
    if (this.harvest_photo) {
      const isAndroid = /android/i.test(navigator.userAgent); // ตรวจสอบว่าเป็น Android หรือไม่
  
      if (isAndroid) {
        // ดาวน์โหลดไฟล์โดยตรงสำหรับ Android
        this.downloadImage();
      } else {
        // ใช้ Web Share API สำหรับแพลตฟอร์มอื่น
        fetch(this.harvest_photo)
          .then((response) => {
            if (!response.ok) {
              throw new Error('ไม่สามารถโหลดรูปภาพได้');
            }
            return response.blob();
          })
          .then((blob) => {
            const file = new File([blob], 'harvest_photo.png', { type: blob.type });
            if (navigator.share) {
              navigator
                .share({
                  title: 'ใบสั่งตัด',
                  text: 'แชร์ภาพใบสั่งตัดไปยังผู้ติดต่อ',
                  files: [file], // แชร์ไฟล์โดยตรง
                })
                .then(() => console.log('แชร์สำเร็จ'))
                .catch((error) => console.error('เกิดข้อผิดพลาดในการแชร์:', error));
            } else {
              console.warn('Web Share API ไม่รองรับบนอุปกรณ์นี้');
              this.downloadImage(); // ดาวน์โหลดรูปภาพแทน
            }
          })
          .catch((error) => {
            console.error('เกิดข้อผิดพลาดในการโหลดรูปภาพ:', error);
            alert('ไม่สามารถโหลดรูปภาพได้');
          });
      }
    } else {
      console.error('ไม่มี URL ของรูปภาพที่จะใช้แชร์');
      alert('ไม่มีรูปภาพสำหรับแชร์');
    }
  }

  shareImagereceipt() {
    if (this.harvest_photo) {
      const isAndroid = /android/i.test(navigator.userAgent); // ตรวจสอบว่าเป็น Android หรือไม่
  
      if (isAndroid) {
        // ดาวน์โหลดไฟล์โดยตรงสำหรับ Android
        this.downloadImage();
      } else {
        // ใช้ Web Share API สำหรับแพลตฟอร์มอื่น
        fetch(this.harvest_photo)
          .then((response) => {
            if (!response.ok) {
              throw new Error('ไม่สามารถโหลดรูปภาพได้');
            }
            return response.blob();
          })
          .then((blob) => {
            const file = new File([blob], 'harvest_photo.png', { type: blob.type });
            if (navigator.share) {
              navigator
                .share({
                  title: 'ใบสั่งตัด',
                  text: 'แชร์ภาพใบสั่งตัดไปยังผู้ติดต่อ',
                  files: [file], // แชร์ไฟล์โดยตรง
                })
                .then(() => console.log('แชร์สำเร็จ'))
                .catch((error) => console.error('เกิดข้อผิดพลาดในการแชร์:', error));
            } else {
              console.warn('Web Share API ไม่รองรับบนอุปกรณ์นี้');
              this.downloadImage(); // ดาวน์โหลดรูปภาพแทน
            }
          })
          .catch((error) => {
            console.error('เกิดข้อผิดพลาดในการโหลดรูปภาพ:', error);
            alert('ไม่สามารถโหลดรูปภาพได้');
          });
      }
    } else {
      console.error('ไม่มี URL ของรูปภาพที่จะใช้แชร์');
      alert('ไม่มีรูปภาพสำหรับแชร์');
    }
  }

  // shareImage() {
  //   const userAgent = navigator.userAgent || navigator.vendor;

  //   // ตรวจสอบว่าเป็น LINE Browser หรือไม่
  //   const isLineBrowser = /Line/i.test(userAgent);

  //   console.log('User Agent:', userAgent);
  //   console.log('isLineBrowser:', isLineBrowser);

  //   if (this.harvest_photo) {
  //     if (isLineBrowser) {
  //       console.log('Sharing via LINE Browser');
  //       this.shareImageToLine(); // แชร์ใน LINE Browser ผ่าน LIFF
  //     } else if (typeof navigator.share === 'function' && navigator.canShare({ files: [] })) {
  //       console.log('Sharing via Web Share API');
  //       this.shareViaWebAPI(); // ใช้ Web Share API สำหรับเบราว์เซอร์อื่น
  //     } else {
  //       console.log('Sharing is not supported. Falling back to download.');
  //       alert('เบราว์เซอร์นี้ไม่รองรับการแชร์ เราจะเพิ่มตัวเลือกสำหรับการบันทึกภาพแทน');
  //       this.downloadImage(); // ดาวน์โหลดภาพแทน
  //     }
  //   } else {
  //     console.error('No image to share.');
  //     alert('ไม่มีรูปภาพสำหรับการแชร์');
  //   }
  // }

  async shareImageToLine() {
    try {

      // ตรวจสอบว่า LIFF ได้ถูกใช้งานใน LINE App หรือไม่
      if (!liff.isInClient()) {
        alert('กรุณาเปิดในแอป LINE เพื่อแชร์');
        return;
      }

      await liff.init({
        liffId: '2006560111-XaoQ6rVK'
      });

      // ตรวจสอบว่า URL ของรูปภาพถูกต้อง
      if (!this.harvest_photo || !/^https?:\/\/.+\.(jpg|jpeg|png|webp|gif)$/i.test(this.harvest_photo)) {
        throw new Error('URL ของรูปภาพไม่ถูกต้องหรือไม่ได้รับการสนับสนุน');
      }

      // แชร์ผ่าน Share Target Picker
      const result = await liff.shareTargetPicker([
        {
          type: 'image',
          originalContentUrl: this.harvest_photo,
          previewImageUrl: this.harvest_photo,
        },
      ]);

      if (result) {
        console.log('แชร์สำเร็จ:', result);
        alert('แชร์รูปภาพสำเร็จ');
      } else {
        alert('ผู้ใช้ยกเลิกการแชร์');
      }
    } catch (error: any) {
      const errorMessage = error?.message || 'เกิดข้อผิดพลาดที่ไม่ทราบสาเหตุ';
      alert(`ไม่สามารถแชร์รูปภาพได้ กรุณาลองใหม่\nรายละเอียด: ${errorMessage}`);
      console.error('เกิดข้อผิดพลาดในการแชร์:', error);
    }
  }



  shareViaWebAPI() {
    // แชร์รูปภาพโดยตรงผ่าน Web Share API
    fetch(this.harvest_photo!)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then(blob => {
        const file = new File([blob], 'share-image.png', { type: blob.type });

        navigator.share({
          title: 'แชร์ภาพใบสั่งตัด',
          text: 'แชร์ภาพไปยังผู้ติดต่อ',
          files: [file], // ส่งไฟล์รูปภาพโดยตรง
        })
          .then(() => console.log('Successful share'))
          .catch(error => {
            console.error('Error sharing:', error);
            alert('เกิดข้อผิดพลาดในการแชร์ กรุณาลองใหม่อีกครั้ง');
          });
      })
      .catch(error => {
        console.error('Error fetching image:', error);
        alert('ไม่สามารถดึงรูปภาพมาแชร์ได้');
      });
  }

  downloadImage() {
    const link = document.createElement('a');
    link.href = this.harvest_photo!;
    link.download = 'harvest_photo.png'; // กำหนดชื่อไฟล์
    link.click();
  }



  getBillHarvestOrderNo(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.token = localStorage.getItem('token');
      const storedbp_role_data = localStorage.getItem('bp_role_data');
      this.bp_role_data = storedbp_role_data ? JSON.parse(storedbp_role_data) : [];
      console.log(this.bp_role_data);
      console.log(this.token);

      const bpRoleData = localStorage.getItem('bp_role_data'); 
      if(bpRoleData){
        const bpRoleDataArray = JSON.parse(bpRoleData);
        this.quota = bpRoleDataArray.some((item: any) => item.legacy_bp_type === "QUOTA");
        console.log(this.quota);
      }


      this.bpCodeArray = this.bp_role_data
        .filter((data: { legacy_bp_type: string }) => data.legacy_bp_type === 'QUOTA')
        .map((data: { bp_code: string }) => data.bp_code);

      

    }
    const bpCodeHeader = this.bpCodeArray; // เช่น "101000"
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'token': String(this.token),
      'bp_code': bpCodeHeader,
    });
    const body = { harvestNo: this.harvestNo, faccode: this.faccode, by_year: this.by_year };
    this.apiService.post('getBillHarvestOrderNo', body, headers).subscribe(
      data => {
        this.isLoadingAPIGET = true;
        this.data = data;


        if (this.data.code == 1) {
          console.log(this.data);
          console.log(this.data.data);


          this.cr_status = this.data.data.cr_status;
          if (this.cr_status == '2') {
            this.css_cr_status_2 = 'active';
          } else if (this.cr_status == '3') {
            this.css_cr_status_2 = 'active';
            this.css_cr_status_3 = 'active';
          }

          this.bpName = this.data.data.quota_name ?? '-';
          this.queueName = this.data.data.queueName ?? '-';
          this.queueNo = this.data.data.queueNo ?? '-';
          this.plotString = this.data.data.plotString ?? '-';
          this.send_to = this.data.data.send_to ?? '-';
          this.estimate_to_factory = this.data.data.estimate_to_factory ?? '-';
          this.extimate_to_wt = this.data.data.extimate_to_wt ?? '-';
          this.confirm_time = this.data.data.confirm_time ?? '-';
          if (this.data.data.confirm_time) {
            this.convertGetDateTime_confirm(this.data.data.confirm_time);
          }


          this.harvest_photo = this.data.data.harvest_photo ?? '-';
          this.dlv_vehicle_license_plate = this.data.data.dlv_vehicle_license_plate ?? '-';
          this.dlvName = this.data.data.dlvName;

          this.all_weight_timestamp = this.data?.data?.all_weight_timestamp ? this.convertDateTime(this.data.data.all_weight_timestamp) : '-';
          this.all_weight = this.data.data.all_weight;
          this.vehicle_weight_timestamp = this.data?.data?.vehicle_weight_timestamp ? this.convertDateTime(this.data.data.vehicle_weight_timestamp) : '-';
          this.dlv_vehicle_weight = this.data.data.dlv_vehicle_weight ?? '-';
          this.caneweight = this.data.data.caneweight ?? '-';
          this.receipt_no = this.data.data.receipt_no ?? '-';
          this.receipt_no_photo = this.data.data.receipt_no_photo ?? '';

          this.cane_graden = this.data.data.cane_graden ?? '-';
          this.ccs = this.data.data.ccs;
          if (this.data.data.ccs > 0) {
            this.ccs_css = 'd-none';
          }

          this.datetime_confirm = this.convertDateTime(this.data.data.confirm_time) ?? '-';
          this.loading_method = this.data.data.loading_method ?? '-';
          this.plot_location = this.data.data.plot_location ?? '-';
          this.zone = this.data.data.zone ?? '-';
          this.sub_zone = this.data.data.sub_zone ?? '-';


        } else if (this.data.code == 2) {
          const message = this.data.message;
          console.log(message);
          if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem('msgError', message);
          }
          this.router.navigate(['/error-view']);
        }


      },
      error => {

        console.error('API call failed', error);
      }
    );
  }

  convertGetDateTime_confirm(dateTimeString: string): void {
    // สร้างวัตถุ Date จาก string
    const dateObj = new Date(dateTimeString);

    // แปลงวันที่เป็นรูปแบบ DD/MM/BBBB (ในปีพุทธศักราช)
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = dateObj.getFullYear() + 543; // แปลงเป็นปีพุทธศักราช
    this.date_confirm = `${day}/${month}/${year}`;

    // แปลงเวลาเป็นรูปแบบ HH.MM
    const hours = String(dateObj.getHours()).padStart(2, '0');
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    this.time_confirm = `${hours}.${minutes}`;


    const DateTime = `${day}/${month}/${year}` + ' ' + `${hours}.${minutes}`;
  }

  convertDateTime(dateTimeString: string): string {
    if (dateTimeString && dateTimeString.trim() !== '') {
      // แยกส่วนวันที่ (DD/MM/BBBB) และเวลา (HH.MM) โดยใช้ substring
      const day = dateTimeString.substring(8, 10); // ดึงวันที่ (DD)
      const month = dateTimeString.substring(5, 7); // ดึงเดือน (MM)
      const year = String(Number(dateTimeString.substring(0, 4)) + 543); // แปลงปี ค.ศ. เป็น พ.ศ. (BBBB)
      const date = `${day}/${month}/${year}`;
  
      const hours = dateTimeString.substring(11, 13); // ดึงชั่วโมง (HH)
      const minutes = dateTimeString.substring(14, 16); // ดึงนาที (MM)
      const time = `${hours}.${minutes}`;
  
      return `${date} ${time}`;
    } else {
      return '';
    }
   
  }
}
