<div class="login-container" *ngIf="isLiffReady">
  <div class="content">
    <div class="container">
      <div class="row justify-content-center">
        <div class="">
          <div class="terms-title">ข้อกำหนดและเงื่อนไข</div>
          <div class="terms-subtitle">
            นโยบายความเป็นส่วนตัว<br />นโยบายความเป็นส่วนตัวของ มิตรชาวไร่
          </div>
  
          <div class="terms-content" [innerHTML]="html_text"></div>
  
          
        </div>
      </div>
    </div>
  </div>

  <div class="box-footer">
    <div class="btn-accept">
      <div class="form-check">
        <input
          type="checkbox"
          class="form-check-input"
          id="agreeTerms"
          (change)="onCheckboxChange($event)"
        />
        <label class="form-check-label" for="agreeTerms"
          >ข้าพเจ้ายอมรับในข้อกำหนดและเงื่อนไข</label
        >
      </div>
      <button
        class="btn"
        [disabled]="!isAgreeChecked"
        (click)="onAcceptClick()"
      >
        ยอมรับ
      </button>
    </div>
  </div>
  
</div>
