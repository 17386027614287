import { Component, AfterViewInit, Inject, PLATFORM_ID, ViewChild, ElementRef, OnInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-history-transport-cane',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './history-transport-cane.component.html',
  styleUrl: './history-transport-cane.component.css'
})
export class HistoryTransportCaneComponent implements OnInit{
  token: string | null = null;
  bp_code: any;
  legacy_bp_type: any;
  bp_role_data: { bp_code: string; legacy_bp_type: string; company: string; fullname: string; }[] = [];
  bpCodeArray: string[] = [];
  legacyBpTypeArray: string[] = [];
  nav_pills_css: string | null = 'd-none';
  nav_link_active_contractor: string | null = '';
  nav_link_active_quota: string | null = '';
  tab_pane_contractor_show_active: string | null = '';
  tab_pane_quota_show_active: string | null = '';
  nav_link_contractor_pending: string | null = '';
  nav_link_contractor_during: string | null = '';
  nav_link_contractor_success: string | null = '';
  nav_link_quota_pending: string | null = '';
  nav_link_quota_during: string | null = '';
  nav_link_quota_success: string | null = '';
  tab_pane_pending_contractor_show_active: string | null = '';
  tab_pane_during_contractor_show_active: string | null = '';
  tab_pane_success_contractor_show_active: string | null = '';
  tab_pane_pending_quota_show_active: string | null = '';
  tab_pane_during_quota_show_active: string | null = '';
  tab_pane_success_quota_show_active: string | null = '';
  
  role_type: string | null = '';

  data: any;
  data_list: any;
  html_list: string = '';
  data_list_con_pedding: any;
  data_list_con_inprogress: any;
  data_list_con_success: any;
  data_list_quo_pedding: any;
  data_list_quo_inprogress: any;
  data_list_quo_success: any;
  isLoadingAPI: boolean = false;
  isLoadingAPIdashboard: boolean = false;

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    private route: ActivatedRoute // เพิ่ม ActivatedRoute เพื่อดึงพารามิเตอร์จากเส้นทาง
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.token = localStorage.getItem('token');
      const storedbp_role_data = localStorage.getItem('bp_role_data');
      console.log(storedbp_role_data);

      this.bp_role_data = storedbp_role_data ? JSON.parse(storedbp_role_data) : [];
      console.log(this.bp_role_data);
      console.log(this.token);

      this.bpCodeArray = this.bp_role_data.map(item => item.bp_code);
      this.legacyBpTypeArray = this.bp_role_data.map(item => item.legacy_bp_type);

      console.log(this.bpCodeArray); // แสดงค่าของ bp_code
      console.log(this.legacyBpTypeArray); // แสดงค่าของ legacy_bp_type


     const hasContractor = this.legacyBpTypeArray.includes("CONTRACTOR");
     if(hasContractor){
     
      this.nav_pills_css = '';
      this.nav_link_active_contractor = 'active';
      this.tab_pane_contractor_show_active = 'show active';
      this.nav_link_contractor_pending = 'active';
      this.tab_pane_pending_contractor_show_active = 'show active';

      this.getCRHistory('CONTRUCTOR','pending');
     }else{
 
      this.nav_link_active_quota = 'active';
      this.tab_pane_quota_show_active = 'show active';
      this.nav_link_quota_pending = 'active';
      this.tab_pane_pending_quota_show_active = 'show active';

      this.getCRHistory('QUOTA','pending');
     }
    }
  }

  getCRHistory(roleType: string, harv_status: string): void {
    let storedbp_role_data: string | null = null;

    this.isLoadingAPI = false;
  
    if (isPlatformBrowser(this.platformId)) {
      storedbp_role_data = localStorage.getItem('bp_role_data');
    }
  
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'token': String(this.token),
    });
  
    const body = { 
      role: roleType, 
      harv_status: harv_status, 
      bp_role: storedbp_role_data || ''  // หาก storedbp_role_data เป็น null จะใช้ค่า default เป็น ''
    };
  
    this.apiService.post('getCRHistory', body, headers).subscribe(
      data => {
        this.data = data;
        console.log(this.data);
        if (this.data.code === 1) {
          console.log(this.data.data);
          this.data_list = this.data.data;
          // this.html_list = this.data_list;
          if(this.data.data != '' && this.data.data != null){
            this.html_list = this.generateHTML(this.data_list);
          }else{
            this.html_list = `<span class="text-center mt-3 font-size-16px">
                                  ไม่พบข้อมูลใบสั่งตัด
                                </span >`;
          }
          
          
          if(roleType == 'CONTRUCTOR'){
            if(harv_status == 'pending'){
              this.data_list_con_pedding = this.html_list;
            }else if(harv_status == 'inprogress'){
              this.data_list_con_inprogress = this.html_list;
            }else if(harv_status == 'success'){
              this.data_list_con_success = this.html_list;
            }
          }else if(roleType == 'QUOTA'){
            if(harv_status == 'pending'){
              this.data_list_quo_pedding = this.html_list;
              this.tab_pane_pending_quota_show_active = 'show active';
              this.tab_pane_during_quota_show_active = '';
              this.tab_pane_success_quota_show_active = '';
            }else if(harv_status == 'inprogress'){
              this.data_list_quo_inprogress = this.html_list;
              this.tab_pane_pending_quota_show_active = '';
              this.tab_pane_during_quota_show_active = 'show active';
              this.tab_pane_success_quota_show_active = '';
            }else if(harv_status == 'success'){
              this.data_list_quo_success = this.html_list;
              this.tab_pane_pending_quota_show_active = '';
              this.tab_pane_during_quota_show_active = '';
              this.tab_pane_success_quota_show_active = 'show active';
            }
          }

          this.isLoadingAPI = true;
          setTimeout(() => {
            this.isLoadingAPIdashboard = true;
          }, 1200);
        } else if (this.data.code === 2) {
          const message = this.data.message;
          console.log(message);
  
          if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem('msgError', message);
          }
  
          this.router.navigate(['/error-view']);
        }
      },
      error => {
        console.error('API call failed', error);
      }
    );
  }

  clickTabs(tabType: string): void {
    console.log(`Selected tab: ${tabType}`);
    // คุณสามารถเพิ่ม logic เพิ่มเติมได้ที่นี่
    if (tabType === 'CONTRUCTOR') {
      console.log('Handling logic for CONTRUCTOR tab');
      this.nav_link_active_contractor = 'active';
      this.tab_pane_contractor_show_active = 'show active';
      this.nav_link_contractor_pending = 'active';
      this.tab_pane_pending_contractor_show_active = 'show active';
      this.getCRHistory('CONTRUCTOR','pending');
    }else if (tabType === 'QUOTA'){
      console.log('Handling logic for QUOTA tab');
      this.nav_link_active_quota = 'active';
      this.tab_pane_quota_show_active = 'show active';
      this.nav_link_quota_pending = 'active';
      this.tab_pane_pending_quota_show_active = 'show active';
      this.getCRHistory('QUOTA','pending');
    }
  }

  generateHTML(dataList: any[]): string {
    let html = '';
    dataList.forEach(item => {
      console.log(item);
      let harvestNo = item.harvestNo;
      let faccode = item.faccode;
     
      let by_year = item.beYear;
     
      let detail_to = item.detail_to;
      let url_href = `order-cutting-cane?faccode=${faccode}&harvestNo=${harvestNo}`;
      if (detail_to == 'cr') {
        url_href = `order-cutting-cane-detail?faccode=${faccode}&harvestNo=${harvestNo}&by_year=${by_year}`;
      }
      
      html += `
        <div class="col-md-6 mb-3">
          <a href="${url_href}">
            <div class="box-data-order-cutting">
              <div class="row">
                <div class="col text-start p-lable">
                  <img class="img-icon-cane" src="image/icon/icon-calendar.svg" alt="Calendar Icon">
                  ${new Date(item.crateDate).toLocaleDateString('th-TH')}
                </div>
                <div class="col text-end p-header">
                  <img class="img-icon-cane" src="image/icon/icon-cane.svg" alt="Cane Icon">
                  แปลง ${item.plotString || 'ไม่ระบุ'}
                </div>
              </div>
              <div class="row">
                <div class="col-7 text-start p-header">
                  ${item.queueName || 'ไม่ระบุคิว'}
                </div>
                <div class="col text-end p-header">
                  ${item.queueNo || 'ไม่ระบุคิว'}
                </div>
              </div>
              <div class="row">
                <div class="col-7 text-start p-lable">
                  ชาวไร่: ${item.bpName || 'ไม่ระบุ'}
                </div>
                <div class="col text-end p-lable">
                <span class="badge ${item.status_css || 'bg-blue'}">${item.status_name || 'ไม่ระบุ'}</span>
                </div>
              </div>
            </div>
          </a>
        </div>
      `;
    });
    return html;
  }
}
