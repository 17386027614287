import { Component, OnInit, ViewChild, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ApiService } from '../services/api.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformBrowser, CommonModule } from '@angular/common';

@Component({
  selector: 'app-receipt-detail',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './receipt-detail.component.html',
  styleUrl: './receipt-detail.component.css'
})
export class ReceiptDetailComponent implements OnInit{
  data: any; 
  bp_role_data: string | null = null;
  firstName: string = 'ไม่มีชื่อ';
  
  isLoadingAPI: boolean = false;

  
  data_list: any; 
  html_list: string | null = null;
  token: string | null = null;

  cuttocrush_total: number | null = 0;
  cr_total: number | null = 0;

  contractors: string | null = null;
  quota: string | null = null;
  receipt_no: string | null = null;
  bp_code: string | null = null;

  cr_status: string | null = null;
  css_cr_status_2: string | null = '';
  css_cr_status_3: string | null = '';
  queueName: string | null = '';
  queueNo: string | null = '';
  plotString: string | null = '';
  send_to: string | null = null;
  estimate_to_factory: string | null = null;
  extimate_to_wt: string | null = null;
  confirm_time: string | null = null;
  confirm_datetime: string | null = null;
  date_confirm: string | null = null;
  time_confirm: string | null = null;
  harvest_photo: string | null = null;
  dlv_vehicle_license_plate: string | null = '-';
  dlvName: string | null = '-';
  all_weight_timestamp: string | null = '-';
  all_weight: string | null = '-';
  vehicle_weight_timestamp: string | null = '-';
  dlv_vehicle_weight: string | null = '-';
  caneweight: string | null = '-';

  cane_graden: string | null = '-';
  ccs: string | null = null;
  ccs_css: string | null = '';
  datetime_confirm: string | null = '-';
  loading_method: string | null = '-';
  plot_location: string | null = '-';
  zone: string | null = '-';
  sub_zone: string | null = '-';
  bpName: string | null = '-';
  receipt_no_photo: string | null = '';

  isPopupVisible = false;
  isPopupVisiblereceipt = false;

  isLoadingAPIGET: boolean = false;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: object // ตรวจสอบ Platform
  ) {}

  ngOnInit() {
    this.route.queryParamMap.subscribe(params => {
      this.receipt_no = params.get('receipt_no');
      console.log(this.receipt_no);
    });
    this.getReceiptNoDetail();
  }

  getReceiptNoDetail(): void {
    let storedbp_role_data: string | null = null;

    this.isLoadingAPI = false;
  
    if (isPlatformBrowser(this.platformId)) {
      storedbp_role_data = localStorage.getItem('bp_role_data');
      this.token = localStorage.getItem('token');
    }
  
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'token': String(this.token),
    });
  
    const body = { 
      receipt_no: this.receipt_no  // หาก storedbp_role_data เป็น null จะใช้ค่า default เป็น ''
    };
  
    this.apiService.post('getReceiptNoDetail', body, headers).subscribe(
      data => {
        this.data = data;
        console.log(this.data);
        if (this.data.code === 1) {
          console.log(this.data.data);

          console.log(this.data.data.all_weight);
         

          this.cr_status = this.data.data.cr_status;
          if (this.cr_status == '2') {
            this.css_cr_status_2 = 'active';
          } else if (this.cr_status == '3') {
            this.css_cr_status_2 = 'active';
            this.css_cr_status_3 = 'active';
          }

          this.bpName = this.data.data.quota_name ?? 'ไม่มีข้อมูล';
          this.bp_code = this.data.data.bp_code ? `(${this.data.data.bp_code})`:'-';
          this.queueName = this.data.data.queue_name ?? '-';
          this.queueNo = `รอบที่ ${this.data.data.round_no} คิวที่ ${this.data.data.queue_number}`;
          this.plotString = this.data.data.plot_code ?? '-';
          this.send_to = this.data.data.fac_name ?? '-';
          this.estimate_to_factory = this.data.data.estimate_to_factory ?? '-';
          this.extimate_to_wt = this.data.data.extimate_to_wt ?? '-';
          this.confirm_time = this.data.data.confirm_time ?? '-';
          // if (this.data.data.confirm_time) {
          //   this.convertGetDateTime_confirm(this.data.data.confirm_time);
          // }
          this.date_confirm = this.data.data.date_confirm ?? '-';
          this.time_confirm = this.data.data.time_confirm ?? '-';


          this.harvest_photo = this.data.data.harvest_photo ?? null;
          this.dlv_vehicle_license_plate = this.data.data.license_plate ?? '-';
          this.dlvName = this.data.data.vehicle_bp_name??'-';

          this.all_weight_timestamp = this.data?.data?.all_weight_timestamp ? this.data.data.all_weight_timestamp : '-';
          this.all_weight = this.data.data.all_weight;
          this.vehicle_weight_timestamp = this.data?.data?.vehicle_weight_timestamp ? this.data.data.vehicle_weight_timestamp : '-';
          this.dlv_vehicle_weight = this.data.data.vehicle_weight ?? '-';
          this.caneweight = this.data.data.cane_weight ?? '-';
          this.receipt_no = this.data.data.receipt_no ?? '-';
          this.receipt_no_photo = this.data.data.receipt_no_photo ?? '';

          this.cane_graden = this.data.data.cane_grade_name ?? '-';
          this.ccs = this.data.data.ccs;
          if (this.data.data.ccs > 0) {
            this.ccs_css = 'd-none';
          }

          this.datetime_confirm = this.data.data.confirm_time ?? '-';
          this.loading_method = this.data.data.loading_method ?? '-';
          this.plot_location = this.data.data.plot_location ?? '-';
          this.zone = this.data.data.zone ?? '-';
          this.sub_zone = this.data.data.sub_zone ?? '-';
          

          this.isLoadingAPI = true;
        } else if (this.data.code === 2) {
          const message = this.data.message;
          console.log(message);
  
          if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem('msgError', message);
          }
  
          this.router.navigate(['/error-view']);
        }
      },
      error => {
        console.error('API call failed', error);
      }
    );
    this.isLoadingAPIGET = true;
  }

  showPopup() {
    this.isPopupVisible = true;
  }

  closePopup() {
    this.isPopupVisible = false;
    this.isPopupVisiblereceipt = false;
  }

  showPopupReceipt() {
    this.isPopupVisiblereceipt = true;
  }

  shareImage() {
    if (this.harvest_photo) {
      const isAndroid = /android/i.test(navigator.userAgent); // ตรวจสอบว่าเป็น Android หรือไม่
  
      if (isAndroid) {
        // ดาวน์โหลดไฟล์โดยตรงสำหรับ Android
        this.downloadImage();
      } else {
        // ใช้ Web Share API สำหรับแพลตฟอร์มอื่น
        fetch(this.harvest_photo)
          .then((response) => {
            if (!response.ok) {
              throw new Error('ไม่สามารถโหลดรูปภาพได้');
            }
            return response.blob();
          })
          .then((blob) => {
            const file = new File([blob], 'harvest_photo.png', { type: blob.type });
            if (navigator.share) {
              navigator
                .share({
                  title: 'ใบสั่งตัด',
                  text: 'แชร์ภาพใบสั่งตัดไปยังผู้ติดต่อ',
                  files: [file], // แชร์ไฟล์โดยตรง
                })
                .then(() => console.log('แชร์สำเร็จ'))
                .catch((error) => console.error('เกิดข้อผิดพลาดในการแชร์:', error));
            } else {
              console.warn('Web Share API ไม่รองรับบนอุปกรณ์นี้');
              this.downloadImage(); // ดาวน์โหลดรูปภาพแทน
            }
          })
          .catch((error) => {
            console.error('เกิดข้อผิดพลาดในการโหลดรูปภาพ:', error);
            alert('ไม่สามารถโหลดรูปภาพได้');
          });
      }
    } else {
      console.error('ไม่มี URL ของรูปภาพที่จะใช้แชร์');
      alert('ไม่มีรูปภาพสำหรับแชร์');
    }
  }

  downloadImage() {
    const link = document.createElement('a');
    link.href = this.harvest_photo!;
    link.download = 'harvest_photo.png'; // กำหนดชื่อไฟล์
    link.click();
  }

  
  shareImagereceipt() {
    if (this.harvest_photo) {
      const isAndroid = /android/i.test(navigator.userAgent); // ตรวจสอบว่าเป็น Android หรือไม่
  
      if (isAndroid) {
        // ดาวน์โหลดไฟล์โดยตรงสำหรับ Android
        this.downloadImage();
      } else {
        // ใช้ Web Share API สำหรับแพลตฟอร์มอื่น
        fetch(this.harvest_photo)
          .then((response) => {
            if (!response.ok) {
              throw new Error('ไม่สามารถโหลดรูปภาพได้');
            }
            return response.blob();
          })
          .then((blob) => {
            const file = new File([blob], 'harvest_photo.png', { type: blob.type });
            if (navigator.share) {
              navigator
                .share({
                  title: 'ใบสั่งตัด',
                  text: 'แชร์ภาพใบสั่งตัดไปยังผู้ติดต่อ',
                  files: [file], // แชร์ไฟล์โดยตรง
                })
                .then(() => console.log('แชร์สำเร็จ'))
                .catch((error) => console.error('เกิดข้อผิดพลาดในการแชร์:', error));
            } else {
              console.warn('Web Share API ไม่รองรับบนอุปกรณ์นี้');
              this.downloadImage(); // ดาวน์โหลดรูปภาพแทน
            }
          })
          .catch((error) => {
            console.error('เกิดข้อผิดพลาดในการโหลดรูปภาพ:', error);
            alert('ไม่สามารถโหลดรูปภาพได้');
          });
      }
    } else {
      console.error('ไม่มี URL ของรูปภาพที่จะใช้แชร์');
      alert('ไม่มีรูปภาพสำหรับแชร์');
    }
  }

  convertDateTime(dateTimeString: string): string {
    if (dateTimeString && dateTimeString.trim() !== '') {
      // แยกส่วนวันที่ (DD/MM/BBBB) และเวลา (HH.MM) โดยใช้ substring
      const day = dateTimeString.substring(8, 10); // ดึงวันที่ (DD)
      const month = dateTimeString.substring(5, 7); // ดึงเดือน (MM)
      const year = String(Number(dateTimeString.substring(0, 4)) + 543); // แปลงปี ค.ศ. เป็น พ.ศ. (BBBB)
      const date = `${day}/${month}/${year}`;
  
      const hours = dateTimeString.substring(11, 13); // ดึงชั่วโมง (HH)
      const minutes = dateTimeString.substring(14, 16); // ดึงนาที (MM)
      const time = `${hours}.${minutes}`;
  
      return `${date} ${time}`;
    } else {
      return '';
    }
   
  }

  convertGetDateTime_confirm(dateTimeString: string): void {
    // สร้างวัตถุ Date จาก string
    const dateObj = new Date(dateTimeString);

    // แปลงวันที่เป็นรูปแบบ DD/MM/BBBB (ในปีพุทธศักราช)
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = dateObj.getFullYear() + 543; // แปลงเป็นปีพุทธศักราช
    this.date_confirm = `${day}/${month}/${year}`;

    // แปลงเวลาเป็นรูปแบบ HH.MM
    const hours = String(dateObj.getHours()).padStart(2, '0');
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    this.time_confirm = `${hours}.${minutes}`;


    const DateTime = `${day}/${month}/${year}` + ' ' + `${hours}.${minutes}`;
  }


}
