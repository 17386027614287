<div class="main-container">
    <div class="backdrop-loading" *ngIf="!isLoadingAPIdashboard">
        <div class="row">
            <div class="col text-center">
                <div class="loader"></div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row" *ngIf="!isLoadingAPI">
            <div class="col text-center">
                <div class="loader"></div>
            </div>
        </div>
        <div class="row" *ngIf="isLoadingAPI && !html_list">
            <div class="col-12 text-center">
                <h2>ไม่พบข้อมูลบิลรับอ้อย</h2>
            </div>
        </div>
        <div class="row" [innerHTML]="html_list"  *ngIf="isLoadingAPI">

        </div>
    </div>
</div>