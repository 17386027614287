<div class="container">
    <div class="content">
        <div class="progress-bar">
            <div class="step active" id="step_id_1">
                <div class="circle"><img src="image/icon/icon-success-primary.svg" loading="lazy"></div>
                <div class="label">ออกจากแปลง</div>
            </div>
            <div class="line"></div>
            <div class="step {{css_cr_status_2}}" id="step_id_2">
                <div class="circle"><span class="num">2</span><img src="image/icon/icon-success-primary.svg" loading="lazy"></div>
                <div class="label">ชั่งเข้า</div>
            </div>
            <div class="line"></div>
            <div class="step {{css_cr_status_3}}" id="step_id_3">
                <div class="circle"><span class="num">3</span><img src="image/icon/icon-success-primary.svg" loading="lazy"></div>
                <div class="label">ชั่งออก</div>
            </div>
        </div>
        
        <div class="box-fullname mt-3">
            <p class="p-farmer">ชาวไร่</p>
            <span class="fullname">{{bpName}}</span>
        </div>
        <div class="box-que mt-4">
            <span class="p-lable">ประเภทคิว</span>
            <span class="p-que-type">{{queueName}}</span>
        </div>
        <div class="box-que mt-4">
            <span class="p-lable">รอบที่/คิวที่</span>
            <span class="p-que-type">{{queueNo}}</span>
        </div>
        <div class="box-que mt-4">
            <span class="p-lable">เลขที่แปลงอ้อย</span>
            <span class="bage bg-blue">{{plotString}}</span>
        </div>
        <div class="box-que mt-3">
            <span class="p-lable">ส่งโรงงาน</span>
            <span class="p-que-type">{{send_to}}</span>
        </div>
        <!-- <div class="box-que mt-4">
            <span class="p-lable">คาดการณ์ถึงโรงงาน</span>
            <span class="p-que-type">{{estimate_to_factory}}</span>
        </div>
        <div class="box-que mt-4">
            <span class="p-lable">คาดการณ์เข้าชั่ง</span>
            <span class="p-que-type">{{extimate_to_wt}}</span>
        </div> -->
        <div class="box-que mt-4">
            <span class="p-lable"><img class="img-icon-clock" src="image/icon/icon-calendar.svg">วันที่ยืนยันหน้าแปลง</span>
            <span class="p-que-type">{{date_confirm}}</span>
        </div>
        <div class="box-que mt-4">
            <span class="p-lable"><img class="img-icon-clock" src="image/icon/icon-clock.svg">เวลาออกจากแปลง</span>
            <span class="p-que-type">{{time_confirm}}</span>
        </div>
        <div class="border-bottom mt-3"></div>
    
        <ul class="nav nav-pills mt-3 mb-3" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
                <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">ข้อมูลห้องชั่ง</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">คุณภาพอ้อย</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">ใบสั่งตัด</button>
            </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                <div class="box-que mt-4">
                    <span class="p-lable">ทะเบียนรถ</span>
                    <span class="p-que-type">{{dlv_vehicle_license_plate}}</span>
                </div>
                <div class="box-que mt-4">
                    <span class="p-lable">ผู้รับเหมา</span>
                    <span class="p-que-type">{{dlvName}}</span>
                </div>
                <div class="box-que mt-4">
                    <span class="p-lable">วันที่ชั่งเข้า</span>
                    <span class="p-que-type">{{all_weight_timestamp}}</span>
                </div>
                <div class="box-que mt-4">
                    <span class="p-lable">น้ำหนักชั่งเข้า</span>
                    <span class="p-que-type">{{all_weight}} ตัน</span>
                </div>
                <div class="box-que mt-4">
                    <span class="p-lable">วันที่ชั่งออก</span>
                    <span class="p-que-type">{{vehicle_weight_timestamp}}</span>
                </div>
                <div class="box-que mt-4">
                    <span class="p-lable">น้ำหนักชั่งออก</span>
                    <span class="p-que-type">{{dlv_vehicle_weight}} ตัน</span>
                </div>
                <div class="box-que mt-4">
                    <span class="p-lable">น้ำหนักสุทธิ</span>
                    <span class="p-que-type">{{caneweight}} ตัน</span>
                </div>
                <div class="box-que mt-4">
                    <span class="p-lable">เลขที่บิลรับอ้อย</span>
                    <span class="p-que-type">{{receipt_no}}</span>
                </div>
                <div class="box-que mt-4 mb-2">
                    <span class="bage bg-yellow w-100 text-center">โปรดตรวจสอบข้อมูลจริงที่บิลรับอ้อยอีกครั้ง</span>
                </div>
            </div>
            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                <div class="box-que mt-4">
                    <span class="p-lable">เกรดอ้อย</span>
                    <span class="p-que-type">{{cane_graden}}</span>
                </div>
                <div class="box-que mt-4">
                    <span class="p-lable">CCS</span>
                    <span class="p-que-type">{{ccs}}</span>
                </div>
                <!-- <div class="box-receipt_no_photo mt-4" *ngIf="receipt_no_photo">
                    <span class="p-lable">ภาพบิลรับอ้อย:</span>
                    <img src="{{receipt_no_photo}}">
                </div> -->
                <div class="box-receipt_no_photo mt-4" (click)="showPopupReceipt()" *ngIf="receipt_no_photo">
                    <span class="p-lable">ภาพบิลรับอ้อย:</span>
                    <div class="div-img mt-2">
                        <img class="img-order-bill-cutting" src="{{receipt_no_photo}}">
                    </div>
                    <div class="btn-share-img-order-bill-cutting">
                        <span>แชร์ภาพใบสั่งตัด</span>
                        <img class="img-share" src="image/icon/icon-share.svg">
                    </div>
                </div>
                <div class="popup" *ngIf="isPopupVisiblereceipt">
                    <div class="popup-overlay"></div>
                    <div class="box-img-order-bill-cutting mt-2">
                        <span class="close-icon" (click)="closePopup()">&times;</span>
                        <div class="div-img">
                            <img class="img-order-bill-cutting" src="{{receipt_no_photo}}" alt="Popup Image">
                        </div>
                        <div class="btn-share-img-order-bill-cutting" (click)="shareImagereceipt()">
                            <span>แชร์ภาพใบสั่งตัด</span>
                            <img class="img-share" src="image/icon/icon-share.svg">
                        </div>
                      
                    </div>
                </div>
                <div class="box-que mt-4 mb-1 {{ccs_css}}">
                    <span class="bage bg-yellow w-100 text-center">อยู่ในระหว่างตรวจคุณภาพ</span>
                </div>
                
            </div>
            <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                <div class="box-img-order-bill-cutting mt-2" (click)="showPopup()">
                    <div class="div-img">
                        <img class="img-order-bill-cutting" src="{{harvest_photo}}">
                    </div>
                    <div class="btn-share-img-order-bill-cutting">
                        <span>แชร์ภาพใบสั่งตัด</span>
                        <img class="img-share" src="image/icon/icon-share.svg">
                    </div>
                </div>
                <div class="popup" *ngIf="isPopupVisible">
                    <div class="popup-overlay"></div>
                    <div class="box-img-order-bill-cutting mt-2">
                        <span class="close-icon" (click)="closePopup()">&times;</span>
                        <div class="div-img">
                            <img class="img-order-bill-cutting" src="{{harvest_photo}}" alt="Popup Image">
                        </div>
                        <div class="btn-share-img-order-bill-cutting" (click)="shareImage()">
                            <span>แชร์ภาพใบสั่งตัด</span>
                            <img class="img-share" src="image/icon/icon-share.svg">
                        </div>
                      
                    </div>
                </div>
                <div class="box-que mt-4">
                    <span class="p-lable">วันที่ออกจากแปลง</span>
                    <span class="p-que-type text-end">{{datetime_confirm}}</span>
                </div>
                <div class="box-que mt-4">
                    <span class="p-lable">วิธีขึ้นอ้อย</span>
                    <span class="p-que-type">{{loading_method}}</span>
                </div>
                <!-- <div class="box-que mt-4">
                    <span class="p-lable">พิกัด</span>
                    <span class="p-que-type text-end">{{plot_location}}</span>
                </div> -->
                <div class="row">
                    <div class="col-2">
                        <span class="p-lable">พิกัด</span>
                    </div>
                    <div class="col text-end">
                        <span class="p-que-type text-end">{{plot_location}}</span>
                    </div>
                </div>
                <div class="box-que mt-4">
                    <span class="p-lable">เขตส่งเสริม</span>
                    <span class="p-que-type">{{zone}}</span>
                </div>
                <div class="box-que mt-4">
                    <span class="p-lable">นักส่งเสริม</span>
                    <span class="p-que-type">{{sub_zone}}</span>
                </div>
            </div>
        </div>
        
    </div>
</div>


<div *ngIf="!isLoadingAPIGET" class="backdrop-loading">
    <div class="loader"></div>
</div>
