import { Component, OnInit, ViewChild, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { ApiService } from '../services/api.service';
import { HttpClientModule } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';

interface ApiResponse {
  code: number;
  message: string;
  data: {
    html_text: string;
    factoryList: any[];
  };
}

@Component({
  selector: 'app-login-confirm-info',
  standalone: true,
  imports: [HttpClientModule, CommonModule],
  templateUrl: './login-confirm-info.component.html',
  styleUrls: ['./login-confirm-info.component.css']
})
export class LoginConfirmInfoComponent implements OnInit {
  @ViewChild('idCardInput') idCardInput!: ElementRef;
  @ViewChild('factorySelect') factorySelect!: ElementRef;

  data: any;
  uuid: string | null = null;

  isLoading: boolean = false;
  isError: boolean = false;
  isIdCardComplete: boolean = false; // ตรวจสอบว่าหมายเลขบัตรประชาชนครบ 13 หลักหรือไม่
  factoryList: any[] = [];

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const uuid = localStorage.getItem('uuid');
      if (uuid) {
        // this.uuid = btoa(uuid);
        this.uuid = uuid;
       
      }else{
        alert('พบข้อผิดพลาด กรุณาบล็อค/ปลดล็อคไลน์ มิตรชาวไร่ ใหม่อีกครั้ง และเข้าลงทะเบียนใหม่อีกครั้ง');
      }

      this.apiService.get<ApiResponse>('getTermandCondition').subscribe((response) => {
        this.data = response;
        console.log(response);

        this.factoryList = response.data.factoryList;
        localStorage.setItem('factoryList', JSON.stringify(this.factoryList));
      });

      
      const url_redirect = localStorage.getItem('url_redirect');
      console.log(url_redirect);
      const storedFactoryList = localStorage.getItem('factoryList');
      this.factoryList = storedFactoryList ? JSON.parse(storedFactoryList) : [];
      console.log(this.factoryList);
    }
  }

  onIdCardInput(): void {
    const idCard = this.idCardInput.nativeElement.value.trim();
    this.isIdCardComplete = idCard.length === 13; // ถ้ากรอกครบ 13 หลักให้ isIdCardComplete เป็น true
  }

  onAcceptClick(): void {
    const idCard = this.idCardInput.nativeElement.value.trim();
    const factoryCode = this.factorySelect.nativeElement.value;

    if (this.isIdCardComplete && factoryCode) {
      this.isLoading = true;
      this.isError = false;

      const body = { idcard: btoa(idCard), factoryCode: factoryCode, user_id: this.uuid };
      this.apiService.post('verifyAccount', body).subscribe(
        data => {
          this.isLoading = false;
          this.data = data;
          localStorage.setItem('idCard', idCard!);
        
          console.log(this.data);
      
          if(this.data.code == 1){
            this.router.navigate(['/login']);
          }else{
            this.isError = true;
          }
          
        },
        error => {
          this.isLoading = false;
          this.isError = true;
          console.error('API call failed', error);
        }
      );
    } else {
      console.error('Please enter all required fields.');
    }
  }
}
