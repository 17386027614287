import { Component, AfterViewInit, Inject, PLATFORM_ID, ViewChild, ElementRef, OnInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-q-to-weight-in-cane',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './q-to-weight-in-cane.component.html',
  styleUrl: './q-to-weight-in-cane.component.css'
})
export class QToWeightInCaneComponent implements OnInit{
  token: string | null = null;
  bp_code: any;
  legacy_bp_type: any;
  bp_role_data: { bp_code: string; legacy_bp_type: string; company: string; fullname: string; }[] = [];
  bpCodeArray: string[] = [];
  legacyBpTypeArray: string[] = [];
  nav_pills_css: string | null = 'd-none';
  nav_link_active_contractor: string | null = '';
  nav_link_active_quota: string | null = '';
  tab_pane_contractor_show_active: string | null = '';
  tab_pane_quota_show_active: string | null = '';
  nav_link_contractor: string | null = '';
  nav_link_contractor_pending: string | null = '';
  nav_link_contractor_during: string | null = '';
  nav_link_contractor_success: string | null = '';
  nav_link_quota: string | null = '';
  nav_link_quota_pending: string | null = '';
  nav_link_quota_during: string | null = '';
  nav_link_quota_success: string | null = '';
  tab_pane_pending_contractor_show_active: string | null = '';
  tab_pane_during_contractor_show_active: string | null = '';
  tab_pane_success_contractor_show_active: string | null = '';
  tab_pane_pending_quota_show_active: string | null = '';
  tab_pane_during_quota_show_active: string | null = '';
  tab_pane_success_quota_show_active: string | null = '';

  tab_pane_q_now_contractor_show_active: string | null = '';
  tab_pane_q_me_contractor_show_active: string | null = '';
  tab_pane_q_now_quota_show_active: string | null = '';
  tab_pane_q_me_quota_show_active: string | null = '';
  nav_link_q_now_contractor: string | null = '';
  nav_link_q_me_contractor: string | null = '';
  nav_link_q_now_quota: string | null = '';
  nav_link_q_me_quota: string | null = '';




  
  role_type: string | null = '';

  data: any;
  data_list: any;
  html_list: string = '';
  data_list_contractor: string = '';
  data_list_quota: string = '';
  data_list_q_me_contractor: any;
  data_list_q_me_quota: string | null = '';
  data_list_q_now_contractor: any;
  data_list_q_now_quota: string | null = '';

  isLoadingAPI: boolean = false;
  isLoadingAPIdashboard: boolean = false;

  rs_item: any;
 
  
  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    private route: ActivatedRoute // เพิ่ม ActivatedRoute เพื่อดึงพารามิเตอร์จากเส้นทาง
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.token = localStorage.getItem('token');
      const storedbp_role_data = localStorage.getItem('bp_role_data');
      console.log(storedbp_role_data);

      this.bp_role_data = storedbp_role_data ? JSON.parse(storedbp_role_data) : [];
      console.log(this.bp_role_data);
      console.log(this.token);

      this.bpCodeArray = this.bp_role_data.map(item => item.bp_code);
      this.legacyBpTypeArray = this.bp_role_data.map(item => item.legacy_bp_type);

      console.log(this.bpCodeArray); // แสดงค่าของ bp_code
      console.log(this.legacyBpTypeArray); // แสดงค่าของ legacy_bp_type


     const hasContractor = this.legacyBpTypeArray.includes("CONTRACTOR");
     console.log(hasContractor);
     if(hasContractor){
     
      this.nav_pills_css = '';
      this.nav_link_contractor = 'active';
      this.tab_pane_contractor_show_active = 'show active';
      this.nav_link_q_now_contractor = 'active';
      this.nav_link_active_contractor = 'active';
      this.tab_pane_q_now_contractor_show_active = 'show active';
     
      this.getCurrentQueue('CONTRUCTOR');
     }else{
      this.nav_link_quota = 'active';
      this.tab_pane_quota_show_active = 'show active';
      this.nav_link_q_now_quota = 'active';
      this.tab_pane_q_now_quota_show_active = 'show active';

      this.getCurrentQueue('QUOTA');
     }
    }

    setTimeout(() => {
      this.isLoadingAPIdashboard = true;
    }, 3000);
  }

  getCurrentQueue(roleType: string): void {
    let storedbp_role_data: string | null = null;

    this.isLoadingAPI = false;
  
    if (isPlatformBrowser(this.platformId)) {
      storedbp_role_data = localStorage.getItem('bp_role_data');
    }
  
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'token': String(this.token),
    });
  
    const body = { 
      role: roleType, 
      bp_role: storedbp_role_data || ''  // หาก storedbp_role_data เป็น null จะใช้ค่า default เป็น ''
    };
  
    this.apiService.post('getCurrentQueue', body, headers).subscribe(
      data => {
        this.data = data;
        console.log(this.data);
        if (this.data.code === 1) {
          console.log(this.data.data);
          this.data_list = this.data.data;
          // this.html_list = this.data_list;
          if(this.data.data != '' && this.data.data != null){
            this.html_list = this.generateHTML(this.data_list);
          }else{
            this.html_list = '';
          }
          
          
          if(roleType == 'CONTRUCTOR'){
            this.data_list_q_now_contractor = this.html_list;
          }else if(roleType == 'QUOTA'){
            this.data_list_q_now_quota = this.html_list;
          }

          this.isLoadingAPI = true;
        } else if (this.data.code === 2) {
          const message = this.data.message;
          console.log(message);
  
          if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem('msgError', message);
          }
  
          this.router.navigate(['/error-view']);
        }
      },
      error => {
        console.error('API call failed', error);
      }
    );
  }

  getMyQueue(roleType: string): void {
    let storedbp_role_data: string | null = null;

    this.isLoadingAPI = false;
  
    if (isPlatformBrowser(this.platformId)) {
      storedbp_role_data = localStorage.getItem('bp_role_data');
    }
  
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'token': String(this.token),
    });
  
    const body = { 
      role: roleType, 
      bp_role: storedbp_role_data || ''  // หาก storedbp_role_data เป็น null จะใช้ค่า default เป็น ''
    };
  
    this.apiService.post('getMyQueue', body, headers).subscribe(
      data => {
        this.data = data;
        console.log(this.data);
        if (this.data.code === 1) {
          console.log(this.data.data);
          this.data_list = this.data.data;
          // this.html_list = this.data_list;
          if(this.data.data != '' && this.data.data != null){
            this.html_list = this.generateHTML(this.data_list);
          }else{
            this.html_list = `<span class="text-center mt-3 font-size-16px">
                                  ไม่พบข้อมูลใบสั่งตัด
                                </span >`;
          }
          
          
          if(roleType == 'CONTRUCTOR'){
            this.data_list_q_me_contractor = this.html_list;
          }else if(roleType == 'QUOTA'){
            this.data_list_q_me_quota = this.html_list;
          }

          this.isLoadingAPI = true;
        } else if (this.data.code === 2) {
          const message = this.data.message;
          console.log(message);
  
          if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem('msgError', message);
          }
  
          this.router.navigate(['/error-view']);
        }
      },
      error => {
        console.error('API call failed', error);
      }
    );
  }

  clickTabs(tabType: string): void {
    console.log(`Selected tab: ${tabType}`);
    // คุณสามารถเพิ่ม logic เพิ่มเติมได้ที่นี่
    if (tabType === 'CONTRUCTOR') {
      console.log('Handling logic for CONTRUCTOR tab');
      this.nav_pills_css = '';
      this.nav_link_contractor = 'active';
      this.tab_pane_contractor_show_active = 'show active';
      this.nav_link_q_now_contractor = 'active';
      this.nav_link_active_contractor = 'active';
      this.tab_pane_q_now_contractor_show_active = 'show active';

      this.getCurrentQueue('CONTRUCTOR');
    }else if (tabType === 'QUOTA'){
      console.log('Handling logic for QUOTA tab');
       
      this.nav_link_quota = 'active';
      this.tab_pane_quota_show_active = 'show active';
      this.nav_link_q_now_quota = 'active';
      this.tab_pane_q_now_quota_show_active = 'show active';

      this.getCurrentQueue('QUOTA');
    }
  }

  generateHTML(dataList: { 
    result: { 
      harvestNo: string; 
      faccode: string; 
      beYear: string; 
      detail_to: string; 
      plotString?: string; 
      queueName?: string; 
      dateCreated?: string; 
      bpName?: string; 
      fromRoundNo?: string; 
      fromQueueNumber?: string; 
      toRoundNo?: string; 
      toQueueNumber?: string; 
    }[]; 
    facname?: string; 
  }[]): string {
    let html_main = '';
    
    dataList.forEach(item => {
      console.log(item);
      const facname = item.facname || 'ไม่ระบุโรงงาน'; // Fallback for missing facname
      let html = '';
      const result = item.result || [];
  
      result.forEach(rs_item => {
        console.log(rs_item);
        const harvestNo = rs_item.harvestNo??'';
        const faccode = rs_item.faccode;
        const by_year = rs_item.beYear;
        const queueName = rs_item.queueName;
        const detail_to = rs_item.detail_to; // Use from rs_item instead of item
        let url_href = 'javascript:void(0)';
        
        if(harvestNo != ''){
          url_href = `order-cutting-cane?faccode=${faccode}&harvestNo=${harvestNo}`;
          if (detail_to === 'cr') {
            url_href = `order-cutting-cane-detail?faccode=${faccode}&harvestNo=${harvestNo}&by_year=${by_year}`;
          }
        }
       
        html += `
          <div class="col-md-6 mb-3">
            <a href="${url_href}">
              <div class="box-data-order-cutting">
                <div class="row">
                  <div class="col text-start p-header">
                    <img class="img-icon-truck-cane" src="image/icon/icon-truck-cane.svg" alt="Cane Icon">
                    ${queueName || 'ไม่ระบุคิว'}
                  </div>
                  <div class="col-5 text-end p-lable">
                    ประกาศเรียก
                  </div>
                </div>
                <div class="row">
                  <div class="col-7 text-start p-header">
                    รอบที่: ${rs_item.fromRoundNo || 'ไม่ระบุ'} คิวที่: ${rs_item.fromQueueNumber || 'ไม่ระบุ'}
                  </div>
                  <div class="col text-end p-lable">
                    <img class="img-icon-cane" src="image/icon/icon-calendar.svg" alt="Calendar Icon">
                    ${rs_item.dateCreated ? new Date(rs_item.dateCreated).toLocaleDateString('th-TH') : 'ไม่ระบุวันที่'}
                  </div>
                </div>
                <div class="row">
                  <div class="col text-start p-header">
                    ถึงรอบที่: ${rs_item.toRoundNo || 'ไม่ระบุ'} คิวที่: ${rs_item.toQueueNumber || 'ไม่ระบุ'}
                  </div>
                  <div class="col text-end p-lable">
      
                   
                    <img class="img-icon-cane" src="image/icon/icon-clock.svg" alt="Clock Icon">
                    ${rs_item.dateCreated ? new Date(rs_item.dateCreated).toLocaleTimeString('th-TH', { hour: '2-digit', minute: '2-digit' }) : 'ไม่ระบุเวลา'}
                  </div>
                </div>
              </div>
            </a>
          </div>
        `;
      });
  
      html_main += `
        <div class="col-md-12 mb-2">
          <span class="span-facname">${facname}</span>
        </div>
      ` + html;
    });
  
    return html_main;
  }
   
}
