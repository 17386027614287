<div class="main-container">
  <div class="backdrop-loading" *ngIf="!isLoadingAPIdashboard">
    <div class="row">
        <div class="col text-center">
            <div class="loader"></div>
        </div>
    </div>
  </div>
  <div class="container">
    <ul
      class="nav nav-pills mt-3 mb-3 {{ nav_pills_css }}"
      id="pills-main-tab"
      role="tablist"
    >
      <li class="nav-item" role="presentation">
        <button
          class="nav-link {{ nav_link_active_contractor }}"
          id="pills-home-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-home"
          type="button"
          role="tab"
          aria-controls="pills-home"
          aria-selected="true"
          (click)="clickTabs('CONTRUCTOR')"
        >
          ผู้รับเหมา
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link {{ nav_link_active_quota }}"
          id="pills-profile-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-profile"
          type="button"
          role="tab"
          aria-controls="pills-profile"
          aria-selected="false"
          (click)="clickTabs('QUOTA')"
        >
          ชาวไร่
        </button>
      </li>
    </ul>
    <div class="tab-content" id="pills-main-tabContent">
      <div
        class="tab-pane fade {{ tab_pane_contractor_show_active }}"
        id="pills-home"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
      >
        <ul class="nav nav-status mt-3 mb-3" id="status-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link {{ nav_link_contractor_pending }}"
              id="status-waiting-tab"
              data-bs-toggle="pill"
              data-bs-target="#status-waiting"
              type="button"
              role="tab"
              aria-controls="status-waiting"
              aria-selected="true"
              (click)="getHarvestHistory('CONTRUCTOR', 'pending')"
            >
              รอดำเนินการ
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link {{ nav_link_contractor_during }}"
              id="status-transport-tab"
              data-bs-toggle="pill"
              data-bs-target="#status-transport"
              type="button"
              role="tab"
              aria-controls="status-transport"
              aria-selected="false"
              (click)="getHarvestHistory('CONTRUCTOR', 'inprogress')"
            >
              ระหว่างขนส่ง
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link {{ nav_link_contractor_success }}"
              id="status-success-tab"
              data-bs-toggle="pill"
              data-bs-target="#status-success"
              type="button"
              role="tab"
              aria-controls="status-success"
              aria-selected="false"
              (click)="getHarvestHistory('CONTRUCTOR', 'success')"
            >
              ขนส่งเสร็จสิ้น
            </button>
          </li>
        </ul>
        <div class="tab-content" id="status-tabContent">
          <div
            class="tab-pane fade {{ tab_pane_pending_contractor_show_active }}"
            id="status-waiting"
            role="tabpanel"
            aria-labelledby="status-waiting-tab"
          >
            <div class="row" *ngIf="!isLoadingAPI">
              <div class="col text-center">
                <div class="loader"></div>
              </div>
            </div>
            <div
              class="row"
              [innerHTML]="data_list_con_pedding"
              *ngIf="isLoadingAPI"
            >
              <!-- <div class="col-md-6">
                  <a href="order-cutting-cane">
                      <div class="box-data-order-cutting">
                          <div class="row">
                              <div class="col-7 text-start p-header"><img class="img-icon-cane" src="image/icon/icon-cane.svg">แปลง 110506212</div>
                              <div class="col text-end p-header">คิวควบคุม_พ่วง</div>
                          </div>
                          <div class="row">
                              <div class="col-7 text-start p-lable"><img class="img-icon-cane" src="image/icon/icon-mark-blue.svg">หนองคอนไทย หมู่ 7</div>
                              <div class="col text-end p-lable"><img class="img-icon-cane" src="image/icon/icon-calendar.svg">21/10/2024</div>
                          </div>
                          <div class="row">
                              <div class="col text-start p-lable">ชาวไร่ :สมพงษ์ สุขใจ</div>
                              <div class="col text-end p-lable"><img class="img-icon-cane" src="image/icon/icon-clock.svg">18:00</div>
                          </div>
                      </div>
                  </a>
              </div> -->
            </div>
          </div>
          <div
            class="tab-pane fade {{ tab_pane_during_contractor_show_active }}"
            id="status-transport"
            role="tabpanel"
            aria-labelledby="status-transport-tab"
          >
            <div class="row" *ngIf="!isLoadingAPI">
              <div class="col text-center">
                <div class="loader"></div>
              </div>
            </div>
            <!-- เนื้อหาในสถานะระหว่างขนส่ง -->
            <div
              class="row"
              [innerHTML]="data_list_con_inprogress"
              *ngIf="isLoadingAPI"
            ></div>
          </div>
          <div
            class="tab-pane fade {{ tab_pane_success_contractor_show_active }}"
            id="status-success"
            role="tabpanel"
            aria-labelledby="status-success-tab"
          >
            <div class="row" *ngIf="!isLoadingAPI">
              <div class="col text-center">
                <div class="loader"></div>
              </div>
            </div>
            <!-- เนื้อหาในสถานะขนส่งเสร็จสิ้น -->
            <div
              class="row"
              [innerHTML]="data_list_con_success"
              *ngIf="isLoadingAPI"
            ></div>
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade {{ tab_pane_quota_show_active }}"
        id="pills-profile"
        role="tabpanel"
        aria-labelledby="pills-profile-tab"
      >
        <ul class="nav nav-status mt-3 mb-3" id="status-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link {{ nav_link_quota_pending }}"
              id="status-waiting-tab"
              data-bs-toggle="pill"
              data-bs-target="#status-waiting"
              type="button"
              role="tab"
              aria-controls="status-waiting"
              aria-selected="true"
              (click)="getHarvestHistory('QUOTA', 'pending')"
            >
              รอดำเนินการ
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link {{ nav_link_quota_during }}"
              id="status-transport-tab"
              data-bs-toggle="pill"
              data-bs-target="#status-transport"
              type="button"
              role="tab"
              aria-controls="status-transport"
              aria-selected="false"
              (click)="getHarvestHistory('QUOTA', 'inprogress')"
            >
              ระหว่างขนส่ง
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link {{ nav_link_quota_success }}"
              id="status-success-tab"
              data-bs-toggle="pill"
              data-bs-target="#status-success"
              type="button"
              role="tab"
              aria-controls="status-success"
              aria-selected="false"
              (click)="getHarvestHistory('QUOTA', 'success')"
            >
              ขนส่งเสร็จสิ้น
            </button>
          </li>
        </ul>
        <div class="tab-content" id="status-tabContent">
          <div
            class="tab-pane fade {{ tab_pane_pending_quota_show_active }}"
            id="status-waiting"
            role="tabpanel"
            aria-labelledby="status-waiting-tab"
          >
            <div class="row" *ngIf="!isLoadingAPI">
              <div class="col text-center">
                <div class="loader"></div>
              </div>
            </div>
            <div
              class="row"
              [innerHTML]="data_list_quo_pedding"
              *ngIf="isLoadingAPI"
            >
              <!-- <div class="col-md-6">
                <a href="order-cutting-cane">
                                  <div class="box-data-order-cutting">
                                      <div class="row">
                                          <div class="col-7 text-start p-header"><img class="img-icon-cane" src="image/icon/icon-cane.svg">แปลง 110506212</div>
                                          <div class="col text-end p-header">คิวควบคุม_พ่วง</div>
                                      </div>
                                      <div class="row">
                                          <div class="col text-start p-lable"><img class="img-icon-cane" src="image/icon/icon-mark-blue.svg">หนองคอนไทย หมู่ 7</div>
                                          <div class="col text-end p-lable"><img class="img-icon-cane" src="image/icon/icon-calendar.svg">21/10/2024</div>
                                      </div>
                                      <div class="row">
                                          <div class="col text-start p-lable">ชาวไร่ :สมพงษ์ สุขใจ</div>
                                          <div class="col text-end p-lable"><img class="img-icon-cane" src="image/icon/icon-clock.svg">18:00</div>
                                      </div>
                                  </div>
                              </a>
              </div> -->
            </div>
          </div>
          <div
            class="tab-pane fade {{ tab_pane_during_quota_show_active }}"
            id="status-transport"
            role="tabpanel"
            aria-labelledby="status-transport-tab"
          >
            <div class="row" *ngIf="!isLoadingAPI">
              <div class="col text-center">
                <div class="loader"></div>
              </div>
            </div>
            <!-- เนื้อหาในสถานะระหว่างขนส่ง -->
            <div
              class="row"
              [innerHTML]="data_list_quo_inprogress"
              *ngIf="isLoadingAPI"
            ></div>
          </div>
          <div
            class="tab-pane fade {{ tab_pane_success_quota_show_active }}"
            id="status-success"
            role="tabpanel"
            aria-labelledby="status-success-tab"
          >
            <div class="row" *ngIf="!isLoadingAPI">
              <div class="col text-center">
                <div class="loader"></div>
              </div>
            </div>
            <!-- เนื้อหาในสถานะขนส่งเสร็จสิ้น -->
            <div
              class="row"
              [innerHTML]="data_list_quo_success"
              *ngIf="isLoadingAPI"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
