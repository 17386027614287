<div class="container">
    <div class="content">
        <div class="box-fullname">
            <div >
                <p class="p-farmer">ชาวไร่</p>
                <span class="fullname">{{bpName}}</span>
            </div>
            <img  *ngIf="bpTelephone" class="img-telephone" src="image/icon/icon-telephone.svg" (click)="callPhoneNumber()"  loading="lazy">
        </div>
        
        <div class="box-que mt-4">
            <span class="p-lable">ประเภทคิว</span>
            <span class="p-que-type">{{queueName}}</span>
        </div>
        <div class="box-que mt-4">
            <span class="p-lable">รอบที่/คิวที่</span>
            <span class="p-que-type">{{queueNo}}</span>
        </div>
        <div class="box-que mt-4">
            <span class="p-lable">เลขที่แปลงอ้อย</span>
            <span class="bage {{bg_status_css_harvestNo}}" style="position: relative;bottom: 8px;">{{plotString}}</span>
        </div>
        <div class="box-map mt-2 {{map_css}}">
            <div class="div-map">
                <div #map id="map" style="width: 100%; height: 100%;"></div>
            </div>
            <div class="btn-show-map" (click)="showRoute()">
                <span>แสดงเส้นทาง</span>
                <img class="img-car" src="image/icon/icon-car-white.svg"  loading="lazy">
            </div>
        </div>
        <div class="box-que mt-3">
            <span class="p-lable">ส่งโรงงาน</span>
            <span class="p-que-type">{{send_to}}</span>
        </div>
        <div class="box-que mt-4">
            <span class="p-lable">คาดการณ์ถึงโรงงาน</span>
            <span class="p-que-type">{{estimate_to_factory}}</span>
        </div>
        <div class="box-que mt-4">
            <span class="p-lable">คาดการณ์เข้าชั่ง</span>
            <span class="p-que-type">{{extimate_to_wt}}</span>
        </div>
        <div class="box-que mt-4">
            <span class="p-lable"><img class="img-icon-clock" src="image/icon/icon-calendar.svg"  loading="lazy">วันที่ยืนยันหน้าแปลง</span>
            <span class="p-que-type">{{dateNow}}</span>
        </div>
        <div class="box-que mt-4">
            <span class="p-lable"><img class="img-icon-clock" src="image/icon/icon-clock.svg"  loading="lazy">เวลาออกจากแปลง</span>
            <span class="p-que-type box-time-out {{bg_time_out}}"><img class="img-icon-clock {{btn_css}}" src="image/icon/icon-clock.svg"  loading="lazy"><span class="time"><input #txtTimeInput id="txt_time" name="txt_time" type="time" [(ngModel)]="timeNow" class="time-input" [attr.readonly]="inputtime_readonly ? true : null" /></span></span>
        </div>
        <div *ngIf="harvest_photo"  class="{{harvest_photo_css}}">
            <div class="box-order-bill-cutting">
                <span class="p-lable">ใบสั่งตัด</span>
            </div>
            <div class="box-img-order-bill-cutting mt-2" (click)="showPopup()">
                <div class="div-img">
                <img class="img-order-bill-cutting" src="{{harvest_photo}}"  loading="lazy">
                </div>
                <div class="btn-share-img-order-bill-cutting" (click)="shareImage()">
                    <span>แชร์ภาพใบสั่งตัด</span>
                    <img class="img-share" src="image/icon/icon-share.svg"  loading="lazy">
                </div>
            </div>
        </div>
        
        <div class="popup" *ngIf="isPopupVisible">
            <div class="popup-overlay"></div>
            <div class="box-img-order-bill-cutting mt-2">
                <span class="close-icon" (click)="closePopup()">&times;</span>
                <div class="div-img">
                    <img class="img-order-bill-cutting" src="{{harvest_photo}}"  loading="lazy">
                </div>
                <div class="btn-share-img-order-bill-cutting" (click)="shareImage()">
                    <span>แชร์ภาพใบสั่งตัด</span>
                    <img class="img-share" src="image/icon/icon-share.svg"  loading="lazy">
                </div>
            </div>
        </div>

        <div class="box-btn-savedata {{plotString_css}}">
            <a href="javascript:void(0)" id="btn-save" class="btn bg-blue {{btn_css}}" data-bs-toggle="modal" data-bs-target="#modal_confrim">บันทึกข้อมูล</a>
        </div>
    </div>

</div>

<div *ngIf="!isLoadingAPIGET" class="backdrop-loading">
    <div class="loader"></div>
</div>

  
<div class="modal fade" id="modal_confrim" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered" role="document">
        <div class="modal-content modal-sm">
            <div class="modal-header">
                <div class="modal-title text-center w-100">ยืนยันการขนส่งอ้อยเข้าโรงงาน</div>
            </div>
            <div class="modal-body">
                <div class="bage {{bg_status_css_harvestNo_modal}} text-center">
                <span>เลขแปลง: </span><span>{{plotString}}</span>
                </div>
                <div class="box-fullname-modal fullname-modal text-center">
                <span>ชาวไร่: </span><span>{{bpName}}</span>
                </div>
                <div class="text-center p-timeout">เวลาออกจากแปลง</div>
                <div class="box-time-out-modal">
                <span class="p-que-type box-time-out {{bg_time_out}} w-100 text-center" style="width: 188px !important;">
                    <img class="img-icon-clock " src="image/icon/icon-clock.svg"  loading="lazy">
                    <input #txtTimeInput id="txt_time" name="txt_time" type="time" [(ngModel)]="timeNow" class="time-input" [attr.readonly]="inputtime_readonly ? true : null" />
                </span>
                </div>
            </div>
            <div class="modal-footer justify-content-center">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" [disabled]="isLoading">ย้อนกลับ</button>
                <div class="box-btn-confirm">
                <button type="button" id="btn-confirm" class="btn btn-primary" (click)="openSuccessModal()" [disabled]="isLoading">ยืนยัน</button>
                <i class="loading-icon" *ngIf="isLoading"></i>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <p class="p-msgError">{{msgError}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="modal_confrim_success" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered" role="document">
        <div class="modal-content modal-sm">
            <div class="modal-header">
                <div class="modal-title"></div>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <img src="image/icon/icon-success-primary.svg"  loading="lazy">
                </div>
                <div class="text-confrim-success text-center">
                    ยืนยันสำเร็จ
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="modal_alert_location" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered" role="document">
        <div class="modal-content modal-sm">
            <div class="modal-header">
                <div class="modal-title"></div>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <img src="image/icon/icon_alert.svg"  loading="lazy">
                </div>
                <div class="text-confrim-success text-center mt-3">
                    กรุณาเปิดการใช้งาน ตำแหน่งปัจจุบัน/GPS และอนุญาตการเข้าถึงตำแหน่งปัจจุบันด้วย
                </div>
            </div>
        </div>
    </div>
</div>
  