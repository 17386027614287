import { Component, OnInit, ViewChild, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ApiService } from '../services/api.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformBrowser, CommonModule } from '@angular/common';
import { count } from 'console';

interface ApiResponse {
  
}
@Component({
  selector: 'app-home',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})
export class HomeComponent implements OnInit {

  data: any; 
  bp_role_data: string | null = null;
  firstName: string = 'ไม่มีชื่อ';
  
  isLoadingAPI: boolean = false;

  
  data_list: any; 
  html_list: string | null = null;
  token: string | null = null;

  cuttocrush_total: number | null = 0;
  cr_total: number | null = 0;
  count_data_list: number | null = 0;

  contractors: string | null = null;
  quota: string | null = null;
  text_bpRole: string | null = null;

  isLoadingAPIdashboard: boolean = false;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: object // ตรวจสอบ Platform
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      const bpRoleData = localStorage.getItem('bp_role_data'); // ดึงข้อมูลจาก localStorage
      if (bpRoleData) {
        try {
          const bpRoleDataArray = JSON.parse(bpRoleData); // แปลง JSON string เป็น array
          const index = 0; // ระบุ index ที่ต้องการ (ตัวอย่าง: index 0)
          const fullname = bpRoleDataArray[index]?.fullname || 'ไม่มีข้อมูล'; // ดึง fullname ของ index 0
          this.firstName = fullname.split(' ')[0] || 'ไม่มีชื่อ';

          console.log(bpRoleDataArray);
          this.contractors = bpRoleDataArray.some((item: any) => item.legacy_bp_type === "CONTRACTOR");
          console.log(this.contractors);
          this.quota = bpRoleDataArray.some((item: any) => item.legacy_bp_type === "QUOTA");
          console.log(this.quota);

          if(this.contractors && this.quota){
            this.text_bpRole = 'ผู้รับเหมา/ชาวไร่';
          }else if(this.contractors && !this.quota){
            this.text_bpRole = 'ผู้รับเหมา';
          }else if(!this.contractors && this.quota){
            this.text_bpRole = 'ชาวไร่';
          }

        } catch (error) {
          console.error('Error parsing JSON:', error);
        }
      } else {
        console.log('bp_role_data is null or undefined');
      }

      const UUID = localStorage.getItem('uuid');
      console.log(UUID);
      
      

      setTimeout(() => {
        this.getHomeScreen();
      }, 1000);

      setTimeout(() => {
        this.isLoadingAPIdashboard = true;
      }, 3000);
    }
  }

  logout():void{
    if (isPlatformBrowser(this.platformId)) {
      const UUID = localStorage.getItem('uuid');
      if(UUID){
        const body = { user_id: UUID };
        this.apiService.post('getLogout', body).subscribe(data => {
          console.log(data);
          this.data = data; 
          if(this.data.code == 1){
            
            localStorage.clear();
            
            this.router.navigate(['/login']);
          }
        
        });
      }else{
        localStorage.clear();
            
        this.router.navigate(['/login']);
      }
    }
  }

  getHomeScreen(): void {
    let storedbp_role_data: string | null = null;

    this.isLoadingAPI = false;
  
    if (isPlatformBrowser(this.platformId)) {
      storedbp_role_data = localStorage.getItem('bp_role_data');
      this.token = localStorage.getItem('token');
    }
  
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'token': String(this.token),
    });
  
    const body = { 
      bp_role: storedbp_role_data || ''  // หาก storedbp_role_data เป็น null จะใช้ค่า default เป็น ''
    };
  
    this.apiService.post('getHomeScreen', body, headers).subscribe(
      data => {
        this.data = data;
        console.log(this.data);
        if (this.data.code === 1) {
          console.log(this.data.data);
          this.data_list = this.data.data;
          // this.html_list = this.data_list;
          
          if(this.data.data != '' && this.data.data != null){
            this.html_list = this.generateHTML(this.data_list);
            this.count_data_list = this.count(this.data_list);
          }else{
            this.html_list = `<span class="text-center mt-3 font-size-16px">
                                  ไม่พบข้อมูลใบสั่งตัด
                                </span >`;
            this.count_data_list = 0;               
          }

          this.cuttocrush_total = this.data.cuttocrush_total;
          this.cr_total = this.data.cr_total;
          
          

          this.isLoadingAPI = true;
        } else if (this.data.code === 2) {
          const message = this.data.message;
          console.log(message);
  
          if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem('msgError', message);
          }
  
          this.router.navigate(['/error-view']);
        }
      },
      error => {
        console.error('API call failed', error);
      }
    );
  }

  generateHTML(dataList: any[]): string {
    let html = '';
    dataList.forEach(item => {
      // console.log(item);
      let harvestNo = item.harvestNo;
      let faccode = item.faccode;
      let by_year = item.beYear;
     
      let detail_to = item.detail_to;
      let url_href = `order-cutting-cane?faccode=${faccode}&harvestNo=${harvestNo}`;
      if (detail_to == 'cr') {
        url_href = `order-cutting-cane-detail?faccode=${faccode}&harvestNo=${harvestNo}&by_year=${by_year}`;
      }
      
      html += `
        <div class="col-md-6 margin-bottom-10px">
          <a href="${url_href}">
            <div class="box-history-cutting-cane-list ${item.status_css || 'bg-blue'}">
              <div class="row">
                <div class="col text-start p-lable">
                  <img class="img-icon-cane" src="image/icon/calendar_white.svg" alt="Calendar Icon">
                  ${new Date(item.crateDate).toLocaleDateString('th-TH')}
                </div>
                <div class="col text-end p-lable">
       
                </div>
              </div>
              <div class="row">
                <div class="col-7 text-start p-header font-size-18px">
                  <img class="img-icon-cane" src="image/icon/cane_white.svg" alt="Cane Icon">
                  ${item.plotString || 'ไม่ระบุ'}
                </div>
                <div class="col text-end p-header">
                  ${item.queueName || 'ไม่ระบุคิว'}
                </div>
              </div>
              <div class="row">
                <div class="col text-start p-lable mb-0">
                  ${item.bpName || 'ไม่ระบุ'}
                </div>
                <div class="col-auto text-end p-lable mb-0">
                  ${item.queueNo || 'ไม่ระบุคิว'}
                </div>
              </div>
            </div>
          </a>
        </div>
      `;
    });
    return html;
  }

  count(list: any[]): number {
    return list.length; // Custom logic here if needed
  }
}
