import { Component, OnInit, ViewChild, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatformBrowser, CommonModule } from '@angular/common';
import liff from '@line/liff';
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-login-confirm-success',
  standalone: true,
  templateUrl: './login-confirm-success.component.html',
  styleUrls: ['./login-confirm-success.component.css']
})
export class LoginConfirmSuccessComponent implements OnInit{
  private liffid = environment.liffid;

  
  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const bp_role_data = localStorage.getItem('bp_role_data');
      // alert('bp_role_data: '+bp_role_data);
      const token = localStorage.getItem('token');
      // alert('token: '+token);   
      const people_id = localStorage.getItem('people_id');
      // alert('people_id: '+people_id);
      const idCard = localStorage.getItem('idCard');
      // alert('idCard: '+idCard);
    }
    if (navigator.userAgent.includes("Line")) {
      const idCard = localStorage.getItem('idCard');
      // alert('idCard: '+idCard);
    } 
  }

  async navigateToHome() {
    try {
      if (navigator.userAgent.includes("Line")) {
        // ตรวจสอบว่า LIFF ถูกโหลดหรือไม่
        await liff.init({ liffId: this.liffid }); // แทนที่ YOUR_LIFF_ID ด้วย LIFF ID ของคุณ
        liff.closeWindow(); // ปิดหน้าต่าง LINE Browser
      } else {
       alert('กรุณาคลิกกากบาทเพื่อปิดหน้าเว็ป')
      }
    } catch (error) {
      console.error('เกิดข้อผิดพลาด:', error);
      alert('ไม่สามารถปิดหน้าต่างได้');
    }
  }
}
