import { Component } from '@angular/core';

@Component({
  selector: 'app-sugarcane-leave',
  standalone: true,
  imports: [],
  templateUrl: './sugarcane-leave.component.html',
  styleUrl: './sugarcane-leave.component.css'
})
export class SugarcaneLeaveComponent {

}
