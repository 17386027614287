<div class="main-container">
  <div class="backdrop-loading" *ngIf="!isLoadingAPIdashboard">
    <div class="row">
        <div class="col text-center">
            <div class="loader"></div>
        </div>
    </div>
  </div>
  <div class="container">
    <ul
      class="nav nav-pills mt-3 mb-3 {{ nav_pills_css }}"
      id="pills-main-tab"
      role="tablist"
    >
      <li class="nav-item" role="presentation">
        <button
          class="nav-link {{ nav_link_contractor }}"
          id="pills-home-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-home"
          type="button"
          role="tab"
          aria-controls="pills-home"
          aria-selected="true"
          (click)="clickTabs('CONTRUCTOR')"
        >
          ผู้รับเหมา
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link {{ nav_link_quota }}"
          id="pills-profile-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-profile"
          type="button"
          role="tab"
          aria-controls="pills-profile"
          aria-selected="false"
          (click)="clickTabs('QUOTA')"
        >
          ชาวไร่
        </button>
      </li>
    </ul>
    <div class="tab-content" id="pills-main-tabContent">
      <!-- Tab ผู้รับเหมา -->
      <div
        class="tab-pane fade {{ tab_pane_contractor_show_active }}"
        id="pills-home"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
      >
        <ul
          class="nav nav-pills mt-3 mb-3"
          id="list-que-contractor-tab"
          role="tablist"
        >
          <li class="nav-item" role="presentation">
            <button
              class="nav-link {{ nav_link_q_now_contractor }}"
              id="list-que-now-contractor-tab"
              data-bs-toggle="pill"
              data-bs-target="#list-que-now-contractor"
              type="button"
              role="tab"
              aria-controls="list-que-now-contractor"
              aria-selected="true"
              (click)="getCurrentQueue('CONTRUCTOR')"
            >
              คิวลานนอก
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link {{ nav_link_q_me_contractor }}"
              id="list-que-my-quota-tab"
              data-bs-toggle="pill"
              data-bs-target="#list-que-my-quota"
              type="button"
              role="tab"
              aria-controls="list-que-my-quota"
              aria-selected="false"
              (click)="getMyQueue('CONTRUCTOR')"
            >
              คิวของฉัน
            </button>
          </li>
        </ul>
        <div class="tab-content" id="list-que-contractor-tabContent">
          <div
            class="tab-pane fade {{ tab_pane_q_now_contractor_show_active }}"
            id="list-que-now-contractor"
            role="tabpanel"
            aria-labelledby="list-que-now-contractor-tab"
          >
            <!-- เนื้อหา คิวเข้าชั่งปัจจุบัน -->
            <div class="row" *ngIf="!isLoadingAPI">
              <div class="col text-center">
                <div class="loader"></div>
              </div>
            </div>
            <div
              class="row"
              [innerHTML]="data_list_q_now_contractor"
              *ngIf="isLoadingAPI"
            ></div>
          </div>
          <div
            class="tab-pane fade {{ tab_pane_q_me_contractor_show_active }}"
            id="list-que-my-quota"
            role="tabpanel"
            aria-labelledby="list-que-my-quota-tab"
          >
            <!-- เนื้อหา คิวของฉัน -->
            <div class="row" *ngIf="!isLoadingAPI">
              <div class="col text-center">
                <div class="loader"></div>
              </div>
            </div>
            <div
              class="row"
              [innerHTML]="data_list_q_me_contractor"
              *ngIf="isLoadingAPI"
            ></div>
          </div>
        </div>
      </div>
      <!-- Tab ชาวไร่ -->
      <div
        class="tab-pane fade {{ tab_pane_quota_show_active }}"
        id="pills-profile"
        role="tabpanel"
        aria-labelledby="pills-profile-tab"
      >
        <ul
          class="nav nav-pills mt-3 mb-3"
          id="list-que-farmer-tab"
          role="tablist"
        >
          <li class="nav-item" role="presentation">
            <button
              class="nav-link {{ nav_link_q_now_quota }}"
              id="list-que-now-farmer-tab"
              data-bs-toggle="pill"
              data-bs-target="#list-que-now-farmer"
              type="button"
              role="tab"
              aria-controls="list-que-now-farmer"
              aria-selected="true"
              (click)="getCurrentQueue('QUOTA')"
            >
              คิวลานนอก
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link {{ nav_link_q_me_quota }}"
              id="list-que-my-farmer-tab"
              data-bs-toggle="pill"
              data-bs-target="#list-que-my-farmer"
              type="button"
              role="tab"
              aria-controls="list-que-my-farmer"
              aria-selected="false"
              (click)="getMyQueue('QUOTA')"
            >
              คิวของฉัน
            </button>
          </li>
        </ul>
        <div class="tab-content" id="list-que-farmer-tabContent">
          <div
            class="tab-pane fade {{ tab_pane_q_now_quota_show_active }}"
            id="list-que-now-farmer"
            role="tabpanel"
            aria-labelledby="list-que-now-farmer-tab"
          >
            <!-- เนื้อหา คิวเข้าชั่งปัจจุบัน -->
            <div class="row" *ngIf="!isLoadingAPI">
              <div class="col text-center">
                <div class="loader"></div>
              </div>
            </div>
            <div
              class="row"
              [innerHTML]="data_list_q_now_quota"
              *ngIf="isLoadingAPI"
            ></div>
          </div>
          <div
            class="tab-pane fade {{ tab_pane_q_me_quota_show_active }}"
            id="list-que-my-farmer"
            role="tabpanel"
            aria-labelledby="list-que-my-farmer-tab"
          >
            <!-- เนื้อหา คิวของฉัน -->
            <div class="row" *ngIf="!isLoadingAPI">
              <div class="col text-center">
                <div class="loader"></div>
              </div>
            </div>
            <div
              class="row"
              [innerHTML]="data_list_q_me_quota"
              *ngIf="isLoadingAPI"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
