import { Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { HomeComponent } from './home/home.component';
import { LoginConfirmComponent } from './login-confirm/login-confirm.component';
import { LoginConfirmInfoComponent } from './login-confirm-info/login-confirm-info.component';
import { LoginConfirmSuccessComponent } from './login-confirm-success/login-confirm-success.component';
import { LoginConfirmPdpaComponent } from './login-confirm-pdpa/login-confirm-pdpa.component';
import { OrderCuttingCaneComponent } from './order-cutting-cane/order-cutting-cane.component';
import { OrderCuttingCaneDetailComponent } from './order-cutting-cane-detail/order-cutting-cane-detail.component';
import { HistoryTransportCaneComponent } from './history-transport-cane/history-transport-cane.component';
import { OrderCuttingCaneListComponent } from './order-cutting-cane-list/order-cutting-cane-list.component';
import { QToWeightInCaneComponent } from './q-to-weight-in-cane/q-to-weight-in-cane.component';
import { FullcalendarCaneComponent } from './fullcalendar-cane/fullcalendar-cane.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ErrorViewComponent } from './error-view/error-view.component';
import { PhotoComponent } from './photo/photo.component';
import { CallweightqueueComponent } from './callweightqueue/callweightqueue.component';
import { BillordercaneComponent } from './billordercane/billordercane.component';
import { SugarcaneLeaveComponent } from './sugarcane-leave/sugarcane-leave.component';
import { TransportVnasComponent } from './transport-vnas/transport-vnas.component';
import { ReceiptDetailComponent } from './receipt-detail/receipt-detail.component';



export const routes: Routes = [
    {
        path: '',
        component: MainLayoutComponent, // ใช้ Layout หลัก
        children: [
            { path: 'home', component: HomeComponent,data: { title: 'หน้าแรก' }  },
            { path: 'history-transport-cane', component: HistoryTransportCaneComponent,data: { title: 'ประวัติการขนส่งอ้อย' }  },
            {
                path: 'order-cutting-cane-list',
                component: OrderCuttingCaneListComponent,
                data: { title: 'รายการใบสั่งตัดอ้อย' }
            },
            {
                path: 'q-to-weight-in-cane',
                component: QToWeightInCaneComponent,
                data: { title: 'คิวลานนอก' }
            },
            {
                path: 'order-cutting-cane',
                component: OrderCuttingCaneComponent,
                data: { title: 'ยืนยันการขนส่งอ้อยเข้าโรงงาน' }
            },
            {
                path: 'order-cutting-cane-detail',
                component: OrderCuttingCaneDetailComponent,
                data: { title: 'ข้อมูลการขนส่งอ้อยเข้าโรงงาน' }
            },
            {
                path: 'fullcalendar',
                component: FullcalendarCaneComponent,
                data: { title: 'ปฏิทินการส่งอ้อย' }
            },
            {
                path: 'dashboard',
                component: DashboardComponent,
                data: { title: 'ภาพรวมของฉัน' }
            },
            {
                path: 'callweightqueue',
                component: CallweightqueueComponent,
                data: { title: 'เรียกคิวเข้าชั่ง' }
            },
            {
                path: 'billordercane',
                component: BillordercaneComponent,
                data: { title: 'บิลรับอ้อย' }
            },
            {
                path: 'sugarcane_leave',
                component: SugarcaneLeaveComponent,
                data: { title: 'ขนใบอ้อย' }
            },
            {
                path: 'transport_vnas',
                component: TransportVnasComponent,
                data: { title: 'ขนวีแนส' }
            },
            {
                path: 'bill_detail',
                component: ReceiptDetailComponent,
                data: { title: 'ข้อมูลการขนส่งอ้อยเข้าโรงงาน(บิล)' }
            },
            { path: '', redirectTo: 'home', pathMatch: 'full' } // เริ่มต้นที่ home
        ]
    },
    {
        path: 'login',
        component: LoginComponent,
        data: { title: 'ยืนยันเบอร์โทรศัพท์' }
    },
    {
        path: 'share_image',
        component: PhotoComponent,
        data: { title: 'แชร์รูปภาพ' }
    },
    {
        path: 'login-confirm',
        component: LoginConfirmComponent,
        data: { title: 'ยืนยันเบอร์โทรศัพท์' }
    },
    {
        path: 'login-confirm-info',
        component: LoginConfirmInfoComponent,
        data: { title: 'ยืนยันข้อมูลส่วนตัว' }
    },
    {
        path: 'login-confirm-success',
        component: LoginConfirmSuccessComponent,
        data: { title: 'ลงทะเบียนสำเร็จ' }
    },
    // {
    //     path: 'login-confirm-pdpa',
    //     component: LoginConfirmPdpaComponent,
    //     data: { title: 'Terms & Conditions' }
    // },
    {
        path: 'login-confirm-terms-conditions',  //3361000662294
        component: LoginConfirmPdpaComponent,
        data: { title: 'Terms & Conditions' }
    },
    {
        path: 'error-view',
        component: ErrorViewComponent,
        data: { title: 'พบข้อผิดพลาด' }
    },
    { path: '**', redirectTo: 'home' } // หากหาเส้นทางไม่เจอให้ไปที่ login
];
