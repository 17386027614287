import { Component, OnInit, ViewChild, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ApiService } from '../services/api.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformBrowser, CommonModule } from '@angular/common';


@Component({
  selector: 'app-billordercane',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './billordercane.component.html',
  styleUrl: './billordercane.component.css'
})
export class BillordercaneComponent implements OnInit{
  data: any; 
  bp_role_data: string | null = null;
  firstName: string = 'ไม่มีชื่อ';
  
  isLoadingAPI: boolean = false;
  isLoadingAPIdashboard: boolean = false;
  
  data_list: any; 
  html_list: string | null = null;
  token: string | null = null;

  cuttocrush_total: number | null = 0;
  cr_total: number | null = 0;

  contractors: string | null = null;
  quota: string | null = null;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: object // ตรวจสอบ Platform
  ) {}

  ngOnInit() {
    this.getReceiptHistory();
  }

  getReceiptHistory(): void {
    let storedbp_role_data: string | null = null;

    this.isLoadingAPI = false;
  
    if (isPlatformBrowser(this.platformId)) {
      storedbp_role_data = localStorage.getItem('bp_role_data');
      this.token = localStorage.getItem('token');
    }
  
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'token': String(this.token),
    });
  
    const body = { 
      bp_role: storedbp_role_data || ''  // หาก storedbp_role_data เป็น null จะใช้ค่า default เป็น ''
    };
  
    this.apiService.post('getReceiptHistory', body, headers).subscribe(
      data => {
        this.data = data;
        console.log(this.data);
        if (this.data.code === 1) {
          console.log(this.data.data);
          this.data_list = this.data.data;
          // this.html_list = this.data_list;
          if(this.data.data != '' && this.data.data != null){
            this.html_list = this.generateHTML(this.data_list);
          }else{
            this.html_list = `<span class="text-center mt-3 font-size-16px">
                                  ไม่พบข้อมูลใบสั่งตัด
                                </span >`;
          }

          this.cuttocrush_total = this.data.cuttocrush_total;
          this.cr_total = this.data.cr_total;
          
          

          this.isLoadingAPI = true;

          setTimeout(() => {
            this.isLoadingAPIdashboard = true;
          }, 1200);
        } else if (this.data.code === 2) {
          const message = this.data.message;
          console.log(message);
  
          if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem('msgError', message);
          }
  
          this.router.navigate(['/error-view']);
        }
      },
      error => {
        console.error('API call failed', error);
      }
    );
  }

  generateHTML(dataList: any[]): string {
    let html = '';
    dataList.forEach(item => {
      // console.log(item);
 
      let receipt_no = item.receipt_no;


      let url_href = `bill_detail?receipt_no=${receipt_no}`;
      
      
      html += `
        <div class="col-md-6 mb-3">
          <a href="${url_href}">
            <div class="box-history-cutting-cane-list">
              <div class="row">
                <div class="col-8 text-start p-lable-cr">
                  ${item.receipt_no || 'ไม่ระบุคิว'}
                </div>
                <div class="col-4 text-end p-lable">
                  <img class="img-icon-cane" src="image/icon/icon-calendar.svg" alt="Calendar Icon">
                  ${item.arrival_time}
                </div>
              </div>
              <div class="row">
                <div class="col-6 text-start p-header pr-0">
                  น้ำหนักอ้อย ${item.caneweight || '-'} ตัน
                </div>
                <div class="col-6 text-end p-header">
                  แปลง ${item.plotstring || 'ไม่ระบุ'}
                </div>
              </div>
              <div class="row">
                <div class="col text-start p-lable-grade mb-0">
                  เกรด: ${item.cane_grade_name || 'ไม่ระบุ'}
                </div>
                <div class="col-auto text-end p-lable mb-0">
                  CCS ${item.ccs || 'ไม่ระบุ'}
                </div>
              </div>
            </div>
          </a>
        </div>
      `;
    });
    return html;
  }
}
