<div class="login-container">
    <div class="container">
        <div class="login-content text-center">
            <div class="box-login">
                <div class="row justify-content-center">
                    <!-- <p class="login-header">มิตรชาวไร่</p> -->
                    
                    <img class="icon-img-logo" src="image/icon/icon-logo-mitrphol.svg">
                    <div class="col-md-6">
                        <form class="login-form">
                            <div class="form-group">
                                <label for="phone" class="form-label">กรอกหมายเลขโทรศัพท์</label>
                                <input type="number" class="form-control" id="phone" #phoneInput placeholder="00-0000-0000" maxlength="10" (input)="onPhoneInput()">
                            </div>
                    
                            <button type="button" class="btn btn-primary btn-block" (click)="onAcceptClick()" [disabled]="!isPhoneComplete">เข้าใช้งาน</button>
                            <small class="mt-3" *ngIf="isError">{{msgError}}</small>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  