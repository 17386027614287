import { Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-photo',
  standalone: true,
  imports: [],
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.css'],
})
export class PhotoComponent {
  @ViewChild('fallbackLink', { static: true }) fallbackLink!: ElementRef<HTMLAnchorElement>;

  imageUrl: string = 'https://oneagri.khontamweb.com/img/image/harvest_photo/HO6768MPK00010_30.png'; // ใช้ URL ของรูปภาพจริง
  imageName: string = 'harvest_photo.png'; // กำหนดชื่อไฟล์ที่ต้องการ

  shareImage() {
    if (navigator.share) {
      // ใช้ Web Share API ถ้ารองรับ
      navigator
        .share({
          title: 'แชร์รูปภาพ',
          text: 'ดูรูปภาพนี้ที่ฉันจะแชร์!',
          url: this.imageUrl,
        })
        .then(() => console.log('แชร์สำเร็จ'))
        .catch((error) => console.error('เกิดข้อผิดพลาดในการแชร์:', error));
    } else {
      console.warn('Web Share API ไม่รองรับบนอุปกรณ์นี้');
      // ใช้ fallback ดาวน์โหลดรูปภาพแทน
      this.fallbackLink.nativeElement.click();
    }
  }
}
