import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Inject, PLATFORM_ID, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import SolidGauge from 'highcharts/modules/solid-gauge';
import { HighchartsChartModule } from 'highcharts-angular';
import { ApiService } from '../services/api.service';
import { RouterModule } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformBrowser, CommonModule } from '@angular/common';



HighchartsMore(Highcharts);
SolidGauge(Highcharts);

interface Facecode {
  id: number;
  name: string;
  value: string;
}

interface Beyear {
  name: string;
  value: string;
}

interface Bpcodequota {
  name: string;
  value: string;
}

interface Bpcodecon {
  name: string;
  value: string;
}

interface CCSRanking {
  id: number;
  plot_code: string;
  cane_weight: string;
  avg_ccs: string;
}

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [HighchartsChartModule, CommonModule],
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;
  primaryColor: string = '#001DFF';

  data: any;

  firstName: string = 'ไม่มีชื่อ';

  isLoadingAPI: boolean = false;
  isLoading: boolean = false;
  isLoadingAPIdashboard: boolean = false;


  data_list: any;
  html_list: string | null = null;
  token: string | null = null;

  beYear: string | null = null;

  bp_code: any;
  legacy_bp_type: any;
  bp_role_data: { bp_code: string; legacy_bp_type: string; company: string; fullname: string; }[] = [];
  bpCodeArray: string[] = [];
  legacyBpTypeArray: string[] = [];
  msgError: string | null = null;

  fresh_sugarcane: number | null = 0;
  sugarcane_on_fire: number | null = 0;
  sugarcane_other: number | null = 0;
  contractor_cane_weight: number | null = 0;

  fresh_sugarcane_con: number | null = 0;
  sugarcane_on_fire_con: number | null = 0;
  round_contractor: number | null = 0;
  transport_contractor: number | null = 0;

  contractor_cane_weight_css: string | null = 'd-none';
  legacyBpType: string | null = 'QUOTA';

  BpTypeCONTRACTOR: string | null = '';
  BpTypeQUOTA: string | null = '';

  private circumference: number = 2 * Math.PI * 45; // ความยาวรอบวง (r=45)

  chartInstance: Highcharts.Chart | undefined;

  months: {}[] = [];
  currentIndex: number = 0;
  months_tran: {}[] = [];
  currentIndex_tran: number = 0;

  month: number | null = 0;
  year: number | null = 0;

  month_tran: number | null = 0;
  year_tran: number | null = 0;

  text_months = '';
  text_months_tran = '';


  maxccs: number | number = 0;
  minccs: number | number = 0;
  data_center_ccs: number | number = 0;

  nav_link_active_contractor: string | null = '';
  nav_link_active_quota: string | null = '';
  tab_pane_contractor_show_active: string | null = '';
  tab_pane_quota_show_active: string | null = '';

  list_facecode: Facecode[] = [];
  list_beyear: Beyear[] = [];
  list_bpcode_quota: Bpcodequota[] = [];
  list_bpcode_contractor: Bpcodecon[] = [];

  list_CCSRanking: CCSRanking[] = [];

  facecode_val = '';
  beyear_val = '';
  bpcode_quota_val = '';
  bpcode_contractor_val = '';
  data_max_ton_string: string | null = '';

  @ViewChild('beyearSelect') beyearSelect!: ElementRef;
  @ViewChild('facecodeSelect') facecodeSelect!: ElementRef;

  @ViewChild('bpcode_quota') bpcode_quota!: ElementRef;
  @ViewChild('bpcode_contractor') bpcode_contractor!: ElementRef;
  @ViewChild('bpcode_quota_tap') bpcode_quota_tap!: ElementRef;
  @ViewChild('bpcode_contractor_tap') bpcode_contractor_tap!: ElementRef;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: object // ตรวจสอบ Platform
  ) { }

  @ViewChild('dataTable') table!: ElementRef;
  dataTable: any;


  ngOnInit(): void {
    this.renderDataDashboard();

    if (isPlatformBrowser(this.platformId)) {
      const storedbp_role_data = localStorage.getItem('bp_role_data');
      this.bp_role_data = storedbp_role_data ? JSON.parse(storedbp_role_data) : [];

      this.bpCodeArray = this.bp_role_data.map(item => item.bp_code);
      this.legacyBpTypeArray = this.bp_role_data.map(item => item.legacy_bp_type);

      this.currentIndex = 0;
      this.currentIndex_tran = 0;
      if (this.legacyBpTypeArray.includes('CONTRACTOR') && this.legacyBpTypeArray.includes('QUOTA')) {
        // กรณีที่มีทั้ง CONTRACTOR และ QUOTA
        this.legacyBpType = 'CONTRACTOR_QUOTA';
        this.BpTypeCONTRACTOR = 'CONTRACTOR';
        this.BpTypeQUOTA = 'QUOTA';
        this.renderDataCCS();
        this.renderDataTransport();

        this.nav_link_active_quota = 'active';
        this.tab_pane_quota_show_active = 'show active';
        this.clickTabs('QUOTA');
      } else if (this.legacyBpTypeArray.includes('CONTRACTOR')) {
        // กรณีที่มีเฉพาะ CONTRACTOR
        this.legacyBpType = 'CONTRACTOR';
        this.BpTypeCONTRACTOR = 'CONTRACTOR';
        this.renderDataTransport();
      } else if (this.legacyBpTypeArray.includes('QUOTA')) {
        // กรณีที่มีเฉพาะ QUOTA
        this.legacyBpType = 'QUOTA';
        this.BpTypeQUOTA = 'QUOTA';
        this.renderDataCCS();
      }


    }

    this.sortTable('avg_ccs');

    setTimeout(() => {
      this.isLoadingAPIdashboard = true;
    }, 3000);
    console.log('test-123');
  }


  // ngAfterViewInit(): void {
  //   console.log(this.isLoadingAPI);
  // }

  // Configuration for solid gauge chart
  chartOptions: Highcharts.Options = {
    chart: {
      type: 'solidgauge',
      height: '180px',
      backgroundColor: 'transparent'
    },
    title: { text: '' },
    pane: {
      startAngle: -90,
      endAngle: 90,
      background: [],
    },
    tooltip: {
      enabled: true, // เปิด tooltip หากต้องการ
    },
    yAxis: {
      min: 0,
      max: 0,
      lineWidth: 0,
      tickWidth: 0,
      gridLineWidth: 0,
      labels: { enabled: false },
    },
    plotOptions: {
      solidgauge: {
        linecap: 'round',
        dataLabels: {
          y: -10,
          borderWidth: 0,
          useHTML: true,
          format: `
          <div style="text-align: center;">
            <div style="font-size:24px; font-weight:700; color:#404040;">0 ตัน</div>
            <div style="font-size:12px; color:#4C6EF5; font-weight:700;">เป้าหมาย</div>
            <div style="font-size:12px; color:#4C6EF5; font-weight:700;">0 ตัน</div>
          </div>
        `,
        },
      },
    },
    series: [
      {
        type: 'solidgauge',
        name: 'Completion',
        data: [{ y: 0 }],
        innerRadius: '60%',
        radius: '100%',
        color: '#4C6EF5',
        dataLabels: { enabled: true },
        zIndex: 1,
      },
    ],
  };

  // Configuration for column chart
  columnChartOptions: Highcharts.Options = {
    chart: {
      type: 'column',
      height: '290px',
      backgroundColor: '#FAFAFA'
    },
    title: { text: '' },
    xAxis: { categories: ['อา', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'] },
    yAxis: { title: { text: '' } },
    series: [
      {
        type: 'column',
        name: 'เดือนที่ผ่านมา',
        data: [],
        color: '#8ADEFD'
      },
      {
        type: 'column',
        name: 'ปัจจุบัน',
        data: [],
        color: '#4C6EF5'
      }
    ]
  };

  // Configuration for line chart
  lineChartOptions: Highcharts.Options = {
    chart: {
      type: 'area',
      height: '192px',
    },
    title: { text: '' },
    xAxis: { categories: [] },
    yAxis: { visible: false, min: 0 },
    tooltip: { shared: true, valueSuffix: ' หน่วย' },
    plotOptions: {
      area: {
        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, '#4C6EF5'],
            [1, 'rgba(177, 185, 248, 0)']
          ]
        },
        marker: {
          radius: 5,
          fillColor: '#001DFF',
        },
        lineWidth: 2,
        states: { hover: { lineWidth: 3 } },
        threshold: null,
      }
    },
    series: [{
      type: 'area',
      name: 'ยอดขาย',
      data: [],
      color: '#001DFF',
      marker: {
        enabled: true,
        symbol: 'circle',
        radius: 6,
        fillColor: '#001DFF',
      },
      dataLabels: {
        enabled: true,
        format: '{y}',
        style: { fontSize: '12px', fontWeight: 'bold' }
      }
    }]
  };

  lineChartOptions_transport: Highcharts.Options = {
    chart: {
      type: 'area',
      height: '192px',
    },
    title: { text: '' },
    xAxis: { categories: [] },
    yAxis: { visible: false, min: 0 },
    tooltip: { shared: true, valueSuffix: ' หน่วย' },
    plotOptions: {
      area: {
        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, '#4C6EF5'],
            [1, 'rgba(177, 185, 248, 0)']
          ]
        },
        marker: {
          radius: 5,
          fillColor: '#001DFF',
        },
        lineWidth: 2,
        states: { hover: { lineWidth: 3 } },
        threshold: null,
      }
    },
    series: [{
      type: 'area',
      name: 'ยอดขาย',
      data: [],
      color: '#001DFF',
      marker: {
        enabled: true,
        symbol: 'circle',
        radius: 6,
        fillColor: '#001DFF',
      },
      dataLabels: {
        enabled: true,
        format: '{y}',
        style: { fontSize: '12px', fontWeight: 'bold' }
      }
    }]
  };

  gauge_chartOptions: Highcharts.Options = {
    chart: {
      type: 'solidgauge',
      height: 180,
      width: 180,
      spacing: [0, 0, 0, 0],
      margin: [0, 0, 0, 0], // กำหนด margin รอบกราฟ
      plotBorderWidth: 0,
      plotShadow: false,
      style: {
        margin: '0 auto', // จัดให้อยู่ตรงกลาง
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    title: undefined,
    pane: {
      startAngle: -150,
      endAngle: 150,
      background: [
        {
          outerRadius: '90%',
          innerRadius: '70%',
          backgroundColor: '#E9EDF0', // พื้นหลังสำหรับ yAxis 1
          borderWidth: 0,
          shape: 'arc',
        }
      ],
    },
    yAxis: [
      {
        // yAxis ชุดที่ 1
        min: 0,
        max: 100,
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: undefined,
        labels: {
          enabled: false,
        },
        stops: [
          [0.1, '#fff'], // สีฟ้าสำหรับ yAxis 1
          [1, '#fff'],
        ],
      },
      {
        // yAxis ชุดที่ 2
        min: 0,
        max: 100, // ขอบเขตแตกต่างจาก yAxis 1
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: undefined,
        labels: {
          enabled: false,
        },
        stops: [
          [0.1, '#4C6EF5'], // สีแดงสำหรับ yAxis 2
          [1, '#4C6EF5'],
        ],
      },
    ],
    plotOptions: {
      solidgauge: {
        rounded: true,
        dataLabels: {
          enabled: true,
          useHTML: true,
          borderWidth: 0,
          style: {
            textAlign: 'center', // จัดข้อความให้อยู่ตรงกลาง
          },
        },
      },
    },
    tooltip: {
      enabled: false, // เปิดการแสดงผล tooltip
      pointFormat: '{series.name}: <b>{point.percentage:.0f}%</b>'
    },
    series: [
      {
        type: 'solidgauge',
        name: 'ทั้งหมด',
        data: [
          {
            y: 0,
            dataLabels: {
              useHTML: true,
              format: `
                <div style="text-align:center; margin-top:-40px;">
                  <span><img src="/image/icon/icon_user.svg" alt=""></span>
                  <br>
                  <span style="font-size:20px; font-weight:700; color:#404040;">0 ตัน</span>
                  <br>
                  <span style="font-size:10px; font-weight:700; color:#4C6EF5;">สัญญาตัน<br>0 ตัน</span>
                </div>
              `,
            },
          },
        ],
        yAxis: 0, // เชื่อมกับ yAxis ชุดที่ 1
      },
      {
        type: 'solidgauge',
        name: 'ส่งไปแล้ว',
        data: [
          {
            y: 0,
            dataLabels: {
              useHTML: true,
              formatter: function (this: Highcharts.PointLabelObject): string {

                // คืนค่ารูปแบบ HTML
                return `
                  <div style="
                    position: absolute;
                    left: -35px;
                    top: 47px;
                    transform: translate(-50%, -50%);
                    text-align: center;
                    border-radius: 100%;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    background-color: #fff;
                    align-items: center;
                    justify-content: center;
                    border: 4px solid #4C6EF5;
                    font-size: 14px;">
                    <span style="font-size:14px; font-weight:bold; color:#000;">0%</span>
                  </div>
                `;
              },
            },
          },
        ],
        yAxis: 1, // เชื่อมกับ yAxis ชุดที่ 2
      },
    ],
  };

  gauge_css_chartOptions: Highcharts.Options = {
    chart: {
      type: 'solidgauge',
      height: 180,
      width: 180,
      spacing: [0, 0, 0, 0],
      margin: [0, 0, 0, 0], // กำหนด margin รอบกราฟ
      plotBorderWidth: 0,
      plotShadow: false,
      style: {
        margin: '0 auto', // จัดให้อยู่ตรงกลาง
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    title: undefined,
    pane: {
      startAngle: -90,
      endAngle: 90,
      background: [
        {
          outerRadius: '90%',
          innerRadius: '70%',
          backgroundColor: '#E9EDF0',
          // backgroundColor: {
          //   linearGradient: { x1: 0, y1: 0, x2: 1, y2: 0 }, // ไล่ระดับแนวนอน
          //   stops: [
          //     [0, '#FF6B6B'], // สีแดงเริ่มต้น
          //     [0.5, '#FF8C8C'], // สีแดงอ่อน
          //     [0.75, '#6B8CFF'], // สีฟ้าอ่อน
          //     [1, '#4C6EF5'] // สีฟ้าสิ้นสุด
          //   ]
          // },
          borderWidth: 0,
          shape: 'arc',
        }
      ],
    },
    yAxis: [
      {
        // yAxis ชุดที่ 1
        min: 0,
        max: 100,
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: undefined,
        labels: {
          enabled: false,
        },
        stops: [
          [0, '#fff'], // สีเริ่มต้น
          [0.5, '#fff'], // ไล่ระดับไปสีแดงอ่อน
          [1, '#fff'] // สีแดงเข้ม (สิ้นสุด)
        ]
      },
      {
        min: 0,
        max: 100, // ขอบเขตของค่า yAxis
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: undefined,
        labels: {
          enabled: false, // ปิดการแสดงตัวเลข
        },
        stops: [
          [0, '#FF6B6B'], // เริ่มต้นสีแดง
          // [0.3, '#FF8C8C'], // ไล่ไปสีแดงอ่อน
          // [0.6, '#6B8CFF'], // ไล่ไปสีฟ้าอ่อน
          [0.6, '#4C6EF5'], // สิ้นสุดสีฟ้า
        ],
      },

    ],
    plotOptions: {
      solidgauge: {
        rounded: true,
        dataLabels: {
          enabled: true,
          useHTML: true,
          borderWidth: 0,
          style: {
            textAlign: 'center', // จัดข้อความให้อยู่ตรงกลาง
          },
        },
      },
    },
    tooltip: {
      enabled: false, // เปิดการแสดงผล tooltip
      pointFormat: '{series.name}: <b>{point.percentage:.0f}%</b>'
    },
    series: [
      {
        type: 'solidgauge',
        name: 'ทั้งหมด',
        data: [
          {
            y: 0,
            dataLabels: {
              useHTML: true,
              format: `
                <div style="text-align:center; margin-top: -15px;">
                  <span style="font-size:12px; font-weight:700; color:#2E2E30;">ค่าเฉลี่ย CCS</span>
                </div>
              `,
            },
          },
        ],
        yAxis: 0, // เชื่อมกับ yAxis ชุดที่ 1
      },
      {
        type: 'solidgauge',
        name: 'ส่งไปแล้ว',
        data: [
          {
            y: 0,
            dataLabels: {
              useHTML: true,
              formatter: function (this: Highcharts.PointLabelObject): string {

                // คืนค่ารูปแบบ HTML
                return `
                  <div style="
                    position: absolute;
                    left: -60px;
                    top: -8px;
                    transform: translate(-50%, -50%);
                    text-align: center;
                    border-radius: 100%;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    background-color: #fff;
                    align-items: center;
                    justify-content: center;
                    border: 4px solid #FF6B6B;>
                    <span style="font-size:14px; font-weight:bold; color:#000;">0</span>
                  </div>
                `;
              },
            },
          },
        ],
        yAxis: 1, // เชื่อมกับ yAxis ชุดที่ 2
      },
    ],
  };

  getDashboard(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.token = localStorage.getItem('token');
      const storedbp_role_data = localStorage.getItem('bp_role_data');
      this.bp_role_data = storedbp_role_data ? JSON.parse(storedbp_role_data) : [];
      console.log(this.bp_role_data);
      console.log(this.token);

      this.bpCodeArray = this.bp_role_data.map(item => item.bp_code);
      this.legacyBpTypeArray = this.bp_role_data.map(item => item.legacy_bp_type);

      console.log(this.bpCodeArray); // แสดงค่าของ bp_code
      console.log(this.legacyBpTypeArray); // แสดงค่าของ legacy_bp_type

      if (this.legacyBpTypeArray.includes('CONTRACTOR')) {

      }


    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'token': String(this.token)
    });
    const body = { bp_role: this.bp_role_data, faccode: this.facecode_val, beyear: this.beyear_val, bpcode_quota: this.bpcode_quota_val, bpcode_contractor: this.bpcode_contractor_val };
    this.apiService.post('getDashboard', body, headers).subscribe(
      data => {

        this.data = data;
        console.log(this.data);
        if (this.data.code == 1) {
          this.beYear = this.data.data.beYear;
          console.log(this.beYear);
          if(this.list_bpcode_quota.length == 0 && this.list_bpcode_contractor.length == 0){
            this.list_bpcode_quota = this.data.data.data_bpcode_quota;
            this.list_bpcode_contractor = this.data.data.data_bpcode_contractor;
          }
  

          this.updateCharts(this.data.data);
          this.contractor_cane_weight = this.data.data.data_contractor;
        } else {
          const message = this.data.message;
          this.msgError = message;
          this.isLoadingAPI = true;
        }

      },
      error => {
        this.isLoadingAPI = true;
        console.error('API call failed', error);
      }
    );
  }

  updateCharts(data: any): void {
    console.log(data);

    // console.log(data.data_quota.data_center);
    this.fresh_sugarcane = data.data_quota.fresh_sugarcane;
    this.sugarcane_on_fire = data.data_quota.sugarcane_on_fire;
    this.fresh_sugarcane_con = data.data_contractor.fresh_sugarcane;
    this.sugarcane_on_fire_con = data.data_contractor.sugarcane_on_fire;
    this.round_contractor = data.data_contractor.round_contractor;
    this.transport_contractor = data.data_contractor.transport_contractor;
    this.data_max_ton_string = data.data_max_ton_string;
    const data_value = data.data_quota.data_value;
    this.gauge_chartOptions = {
      chart: {
        type: 'solidgauge',
        height: 180,
        width: 280,
        spacing: [0, 0, 0, 0],
        margin: [0, 0, 0, 0], // กำหนด margin รอบกราฟ
        plotBorderWidth: 0,
        plotShadow: false,
        style: {
          margin: '0 auto', // จัดให้อยู่ตรงกลาง
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      },
      title: undefined,
      pane: {
        startAngle: -150,
        endAngle: 150,
        background: [
          {
            outerRadius: '90%',
            innerRadius: '70%',
            backgroundColor: '#E9EDF0', // พื้นหลังสำหรับ yAxis 1
            borderWidth: 0,
            shape: 'arc',
          }
        ],
      },
      yAxis: [
        {
          // yAxis ชุดที่ 1
          min: 0,
          max: (data.data_quota.data_max > 0) ? data.data_quota.data_max : 100,
          lineWidth: 0,
          tickWidth: 0,
          minorTickInterval: undefined,
          labels: {
            enabled: false,
          },
          stops: [
            [0.1, '#fff'], // สีฟ้าสำหรับ yAxis 1
            [1, '#fff'],
          ],
        },
        {
          // yAxis ชุดที่ 2
          min: 0,
          max: (data.data_quota.data_max  > 0)? data.data_quota.data_max:100, // ขอบเขตแตกต่างจาก yAxis 1
          lineWidth: 0,
          tickWidth: 0,
          minorTickInterval: undefined,
          labels: {
            enabled: false,
          },
          stops: [
            [0.1, '#4C6EF5'], // สีแดงสำหรับ yAxis 2
            [1, '#4C6EF5'],
          ],
        },
      ],
      plotOptions: {
        solidgauge: {
          rounded: true,
          dataLabels: {
            enabled: true,
            useHTML: true,
            borderWidth: 0,
            style: {
              textAlign: 'center', // จัดข้อความให้อยู่ตรงกลาง
            },
          },
        },
      },
      tooltip: {
        enabled: false, // เปิดการแสดงผล tooltip
        pointFormat: '{series.name}: <b>{point.percentage:.0f}%</b>'
      },
      series: [
        {
          type: 'solidgauge',
          name: 'ทั้งหมด',
          data: [
            {
              y: 0,
              dataLabels: {
                useHTML: true,
                format: `
                  <div style="text-align:center; margin-top:-40px;">
                    <span><img src="/image/icon/icon_user.svg" alt=""></span>
                    <br>
                    <span style="font-size: 16px; font-weight:bold; color:#404040;">${data_value} ตัน</span>
                    <br>
                    <span style="font-size: 10px; font-weight:bold; color:#4C6EF5;">สัญญาตัน<br>${this.data_max_ton_string} ตัน</span>
                  </div>
                `,
              },
            },
          ],
          yAxis: 0, // เชื่อมกับ yAxis ชุดที่ 1
        },
        {
          type: 'solidgauge',
          name: 'ส่งไปแล้ว',
          data: [
            {
              y: data.data_quota.data_min,
              dataLabels: {
                useHTML: true,
                formatter: function (this: Highcharts.PointLabelObject): string {
                  // percentage (0-100)
                  const percent = Math.round(this.percentage);

                  let left, top;

                  if (percent <= 10) {
                    top = 47 + (percent / 10) * (32 - 47);
                    left = -35 + (percent / 10) * (-48 - -35);
                  } else if (percent <= 15) {
                    top = 32 + ((percent - 10) / 5) * (16 - 32);
                    left = -48 + ((percent - 10) / 5) * (-58 - -48);
                  } else if (percent <= 20) {
                    top = 16 + ((percent - 15) / 5) * (0 - 16);
                    left = -58 + ((percent - 15) / 5) * (-60 - -58);
                  } else if (percent <= 25) {
                    top = 0 + ((percent - 20) / 5) * (-15 - 0);
                    left = -60; // ค่าคงที่
                  } else if (percent <= 30) {
                    top = -15 + ((percent - 25) / 5) * (-31 - -15);
                    left = -60 + ((percent - 25) / 5) * (-56 - -60);
                  } else if (percent <= 35) {
                    top = -31 + ((percent - 30) / 5) * (-44 - -31);
                    left = -56 + ((percent - 30) / 5) * (-47 - -56);
                  } else if (percent <= 40) {
                    top = -44 + ((percent - 35) / 5) * (-55 - -44);
                    left = -47 + ((percent - 35) / 5) * (-36 - -47);
                  } else if (percent <= 45) {
                    top = -55 + ((percent - 40) / 5) * (-62 - -55);
                    left = -36 + ((percent - 40) / 5) * (-21 - -36);
                  } else if (percent <= 50) {
                    top = -62 + ((percent - 45) / 5) * (-65 - -62);
                    left = -21 + ((percent - 45) / 5) * (-6 - -21);
                  } else if (percent <= 55) {
                    top = -65; // ค่าคงที่
                    left = -6 + ((percent - 50) / 5) * (10 - -6);
                  } else if (percent <= 60) {
                    top = -65 + ((percent - 55) / 5) * (-60 - -65);
                    left = 10 + ((percent - 55) / 5) * (26 - 10);
                  } else if (percent <= 65) {
                    top = -60 + ((percent - 60) / 5) * (-52 - -60);
                    left = 26 + ((percent - 60) / 5) * (39 - 26);
                  } else if (percent <= 70) {
                    top = -52 + ((percent - 65) / 5) * (-40 - -52);
                    left = 39 + ((percent - 65) / 5) * (50 - 39);
                  } else if (percent <= 75) {
                    top = -40 + ((percent - 70) / 5) * (-26 - -40);
                    left = 50 + ((percent - 70) / 5) * (57 - 50);
                  } else if (percent <= 80) {
                    top = -26 + ((percent - 75) / 5) * (-11 - -26);
                    left = 57 + ((percent - 75) / 5) * (62 - 57);
                  } else if (percent <= 85) {
                    top = -11 + ((percent - 80) / 5) * (6 - -11);
                    left = 62 + ((percent - 80) / 5) * (60 - 62);
                  } else if (percent <= 90) {
                    top = 6 + ((percent - 85) / 5) * (21 - 6);
                    left = 60 + ((percent - 85) / 5) * (56 - 60);
                  } else if (percent <= 95) {
                    top = 21 + ((percent - 90) / 5) * (34 - 21);
                    left = 56 + ((percent - 90) / 5) * (48 - 56);
                  } else if (percent <= 100) {
                    top = 34 + ((percent - 95) / 5) * (45 - 34);
                    left = 48 + ((percent - 95) / 5) * (35 - 48);
                  }else if (percent > 100) {
                    top = 45 ;
                    left = 35 ;
                  }

                  // Debug ตำแหน่งใน console
                  console.log(`left: ${left}px; top: ${top}px;`);
                  console.log(`percent = ${percent}`);

                  return `
                            <div style="
                              position: absolute;
                              left: ${left}px;
                              top: ${top}px;
                              transform: translate(-50%, -50%);
                              text-align: center;
                              width: 40px;
                              height: 40px;
                              border-radius: 50%;
                              background-color: #fff;
                              border: 4px solid #4C6EF5;
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              z-index: 9999;">
                              <span style="font-size:14px; font-weight:bold; color:#000;">
                                ${Math.round(this.point?.percentage ?? 0)}%
                              </span>
                            </div>
                        `;
                },
              },
            },
          ],
          yAxis: 1, // เชื่อมกับ yAxis ชุดที่ 2
        },
      ],
    };

    this.maxccs = data.data_ccs.data_max_ccs;
    this.minccs = data.data_ccs.data_min_ccs;
    this.data_center_ccs = data.data_ccs.data_center_ccs;
    console.log('data_center_ccs ' + this.data_center_ccs);
    console.log('maxccs ' + this.maxccs);

    this.gauge_css_chartOptions = {
      chart: {
        type: 'solidgauge',
        height: 180,
        width: 180,
        spacing: [0, 0, 0, 0],
        margin: [0, 0, 0, 0], // กำหนด margin รอบกราฟ
        plotBorderWidth: 0,
        plotShadow: false,
        style: {
          margin: '0 auto', // จัดให้อยู่ตรงกลาง
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      },
      title: undefined,
      pane: {
        startAngle: -90,
        endAngle: 90,
        background: [
          {
            outerRadius: '90%',
            innerRadius: '70%',
            backgroundColor: '#E9EDF0', // พื้นหลังสำหรับ yAxis 1
            borderWidth: 0,
            shape: 'arc',
          }
        ],
      },
      yAxis: [
        {
          // yAxis ชุดที่ 1
          min: 0,
          max: (this.maxccs > 0) ? this.maxccs : 100,
          lineWidth: 0,
          tickWidth: 0,
          minorTickInterval: undefined,
          labels: {
            enabled: false,
          },
          stops: [
            [0.1, '#E9EDF0'],
            [1, '#E9EDF0'],
          ],

        },
        {
          min: 0,
          max: (this.maxccs > 0) ? this.maxccs : 100,
          lineWidth: 0,
          tickWidth: 0,
          minorTickInterval: undefined,
          labels: {
            enabled: false, // ปิดการแสดงตัวเลข
          },
          stops: [
            [0, '#FF6B6B'], // สีแดงเริ่มต้น
            [0.25, '#FF8C8C'], // สีแดงอ่อน
            [0.5, '#6B8CFF'], // สีฟ้าอ่อน
            [1, '#4C6EF5'] // สีฟ้าสิ้นสุด
          ],
        },

      ],
      plotOptions: {
        solidgauge: {
          rounded: true,
          dataLabels: {
            enabled: true,
            useHTML: true,
            borderWidth: 0,
            style: {
              textAlign: 'center', // จัดข้อความให้อยู่ตรงกลาง
            },
          },
        },
      },
      tooltip: {
        enabled: false, // เปิดการแสดงผล tooltip
        pointFormat: '{series.name}: <b>{point.percentage:.0f}%</b>'
      },
      series: [
        {
          type: 'solidgauge',
          name: 'ทั้งหมด',
          data: [
            {
              y: 0,
              dataLabels: {
                useHTML: true,
                format: `
                  <div style="text-align:center; margin-top: -15px;">
                    <span style="font-size:12px; font-weight:700; color:#2E2E30;">ค่าเฉลี่ย CCS</span>
                  </div>
                `,
              },
            },
          ],
          yAxis: 0, // เชื่อมกับ yAxis ชุดที่ 1
        },
        {
          type: 'solidgauge',
          name: 'ส่งไปแล้ว',
          data: [
            {
              y: this.data_center_ccs,
              dataLabels: {
                useHTML: true,
                formatter: function (this: Highcharts.PointLabelObject): string {
                  // ตรวจสอบว่า this.series และ this.percentage มีค่า
                  if (!this.series || this.percentage === undefined || this.percentage === null) {
                    return ''; // กรณีไม่มีข้อมูล
                  }

                  // percentage (0-100)
                  const percent = Math.round(this.percentage);

                  // สร้างสูตรคำนวณตำแหน่ง left และ top
                  // let left = -63 + (percent / 100) * (63 * 2); // เปลี่ยนจาก -63 ไป 63
                  let left

                  let top;
                  let bg_color;
                  bg_color = '#FF6B6B';
           
                    

                  if (percent == 0) {
                    top = -8;
                    left = -60;
                  } else if (percent <= 25) {
                      top = -8 + (percent / 25) * (-41 - -8);
                      left = -60 + (percent / 25) * (-48 - -60);
                  } else if (percent <= 50) {
                      bg_color = '#4C6EF5';
                      top = -41 + ((percent - 25) / 25) * (-66 - -41);
                      left = -48 + ((percent - 25) / 25) * (-9 - -48);
                  } else if (percent <= 75) {
                      bg_color = '#4C6EF5';
                      top = -66 + ((percent - 50) / 25) * (-51 - -66);
                      left = -9 + ((percent - 50) / 25) * (39 - -9);
                  } else if (percent > 75){
                      bg_color = '#4C6EF5';
                      top = -51 + ((percent - 75) / 25) * (-8 - -51);
                      left = 39 + ((percent - 75) / 25) * (60 - 39);
                  } else if (percent == 100){
                    bg_color = '#4C6EF5';
                    top = -8 ;
                    left = 60 ;
                }
                
            

                  // Debug ตำแหน่งใน console
                  console.log(`left: ${left}px; top: ${top}px;`);
                  console.log(`percent = ${percent}`);

                  // คืนค่ารูปแบบ HTML พร้อมตำแหน่ง
                  return `
                    <div style="
                      position: absolute;
                      left: ${left}px;
                      top: ${top}px;
                      transform: translate(-50%, -50%);
                      text-align: center;
                      border-radius: 100%;
                      width: 40px;
                      height: 40px;
                      display: flex;
                      background-color: #fff;
                      align-items: center;
                      justify-content: center;
                      border: 4px solid ${bg_color};">
                      <span style="font-size:14px; font-weight:bold; color:#000;">
                        ${this.y}
                      </span>
                    </div>
                  `;
                },


              },
            },
          ],
          yAxis: 1, // เชื่อมกับ yAxis ชุดที่ 2
        },
      ],
    };


  }

  renderDataCCS(): void {
    // if (isPlatformBrowser(this.platformId)) {
    //   this.token = localStorage.getItem('token');
    //   const storedbp_role_data = localStorage.getItem('bp_role_data');
    //   this.bp_role_data = storedbp_role_data ? JSON.parse(storedbp_role_data) : [];
    //   console.log(this.bp_role_data);
    //   console.log(this.token);
    // }
    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'token': String(this.token)
    // });
    // const body = { bp_role: this.bp_role_data };
    // this.apiService.post('renderDataCCS', body, headers).subscribe(
    //   data => {

    //     this.data = data;
    //     console.log(this.data);
    //     if (this.data.code == 1) {
    //       // console.log(this.data.data.month);
    //       this.months = [
    //         {
    //           month: this.data.data.month,
    //           year: this.data.data.year
    //         }
    //       ];
    //       console.log(this.months);
    //       this.month = this.data.data.month;
    //       this.year = this.data.data.year;
    //       this.logMonthAndYear();
    //     } else {
    //       const message = this.data.message;
    //       this.msgError = message;
    //       this.isLoadingAPI = true;
    //     }

    //   },
    //   error => {
    //     this.isLoadingAPI = true;
    //     console.error('API call failed', error);
    //   }
    // );
  }

  renderDataTransport(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.token = localStorage.getItem('token');
      const storedbp_role_data = localStorage.getItem('bp_role_data');
      this.bp_role_data = storedbp_role_data ? JSON.parse(storedbp_role_data) : [];
      console.log(this.bp_role_data);
      console.log(this.token);
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'token': String(this.token)
    });
    const body = { bp_role: this.bp_role_data };
    this.apiService.post('renderDataTransport', body, headers).subscribe(
      data => {

        this.data = data;
        console.log(this.data);
        if (this.data.code == 1) {
          this.months_tran = [
            {
              month_tran: this.data.data.month,
              year_tran: this.data.data.year
            }
          ];
          console.log(this.months_tran);
          this.month_tran = this.data.data.month;
          this.year_tran = this.data.data.year;
          this.logMonthAndYear_tran();
        } else {
          const message = this.data.message;
          this.msgError = message;
          this.isLoadingAPI = true;
        }

      },
      error => {
        this.isLoadingAPI = true;
        console.error('API call failed', error);
      }
    );
  }

  get currentMonth() {
    return this.months[this.currentIndex];
  }

  nextMonth(): void {
    // ตรวจสอบเดือนและปีล่าสุดที่แสดง
    let month = Number(this.month) ?? 1; // แปลงเป็นตัวเลขและกำหนดค่าเริ่มต้น
    let year = Number(this.year) ?? new Date().getFullYear(); // แปลงเป็นตัวเลขและใช้ปีปัจจุบันเป็นค่าเริ่มต้น
    year = year - 543;
    // เพิ่มเดือน
    if (month === 12) {
      month = 1;
      year++;
    } else {
      month++;
    }

    // อัปเดตค่าเดือนและปีล่าสุด
    this.month = (month);
    this.year = (year);

    this.logMonthAndYear();
  }

  prevMonth(): void {
    // ตรวจสอบเดือนและปีล่าสุดที่แสดง
    let month = Number(this.month) ?? 1; // แปลงเป็นตัวเลขและกำหนดค่าเริ่มต้น
    let year = Number(this.year) ?? new Date().getFullYear(); // แปลงเป็นตัวเลขและใช้ปีปัจจุบันเป็นค่าเริ่มต้น
    year = year - 543;
    // ลดเดือน
    if (month === 1) {
      month = 12;
      year--;
    } else {
      month--;
    }

    // อัปเดตค่าเดือนและปีล่าสุด
    this.month = (month);
    this.year = (year);

    this.logMonthAndYear();
  }

  nextMonth_tran(): void {
    // ตรวจสอบเดือนและปีล่าสุดที่แสดง
    let month = Number(this.month_tran) ?? 1; // แปลงเป็นตัวเลขและกำหนดค่าเริ่มต้น
    let year = Number(this.year_tran) ?? new Date().getFullYear(); // แปลงเป็นตัวเลขและใช้ปีปัจจุบันเป็นค่าเริ่มต้น
    year = year - 543;
    // เพิ่มเดือน
    if (month === 12) {
      month = 1;
      year++;
    } else {
      month++;
    }

    // อัปเดตค่าเดือนและปีล่าสุด
    this.month_tran = (month);
    this.year_tran = (year);

    this.logMonthAndYear_tran();
  }

  prevMonth_tran(): void {
    console.log(this.year_tran);
    // ตรวจสอบเดือนและปีล่าสุดที่แสดง
    let month = Number(this.month_tran) ?? 1; // แปลงเป็นตัวเลขและกำหนดค่าเริ่มต้น
    let year = Number(this.year_tran) ?? new Date().getFullYear(); // แปลงเป็นตัวเลขและใช้ปีปัจจุบันเป็นค่าเริ่มต้น
    year = year - 543;
    // ลดเดือน
    if (month === 1) {
      month = 12;
      year--;
    } else {
      month--;
    }

    // อัปเดตค่าเดือนและปีล่าสุด
    this.month_tran = (month);
    this.year_tran = (year);
    this.logMonthAndYear_tran();
  }

  logMonthAndYear(): void {
    this.month = this.month;
    this.year = Number(this.year) + 543;

    const monthNumber: number = Number(this.month);// ตัวอย่าง: เดือนเมษายน
    const monthName: string = this.convertMonthToThai(monthNumber);
    console.log(`เดือนที่ ${monthNumber}: ${monthName}`);
    this.text_months = `${monthName} ${this.year}`;
    // console.log(this.text_months);
    this.getDataCCS();
  }

  logMonthAndYear_tran(): void {
    this.month_tran = this.month_tran;
    this.year_tran = Number(this.year_tran) + 543;
    // console.log(this.month_tran);
    const monthNumber: number = Number(this.month_tran);// ตัวอย่าง: เดือนเมษายน
    const monthName: string = this.convertMonthToThai(monthNumber);
    // console.log(`เดือนที่ ${monthNumber}: ${monthName}`);
    this.text_months_tran = `${monthName} ${(this.year_tran)}`;

    this.getDataTransport();
  }

  getDataCCS(): void {
    // if (isPlatformBrowser(this.platformId)) {
    //   this.token = localStorage.getItem('token');
    //   const storedbp_role_data = localStorage.getItem('bp_role_data');
    //   this.bp_role_data = storedbp_role_data ? JSON.parse(storedbp_role_data) : [];
    //   console.log(this.bp_role_data);
    //   console.log(this.token);
    // }
    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'token': String(this.token)
    // });
    // const body = { month: this.month, year: (this.year! - 543), bp_role: this.bp_role_data, faccode: this.facecode_val };
    // this.apiService.post('getDataCCS', body, headers).subscribe(
    //   data => {

    //     this.data = data;
    //     console.log(this.data);
    //     if (this.data.code == 1) {
    //       // console.log(this.data.data);
    //       // const lineCategories = Object.values(this.data.data).map(value => (value as [string, number])[0]); // ดึงวันที่
    //       // const lineSeriesData = Object.values(this.data.data).map(value => (value as [string, number])[1]); // ดึงค่า
    //       const lineCategories = Object.keys(this.data.data).map(key => String(key)); // แปลง keys เป็น string[]
    //       const lineSeriesData = Object.values(this.data.data) as number[]; // บังคับให้เป็น number[]
    //       // console.log(lineCategories);
    //       // Line Chart
    //       this.lineChartOptions = {
    //         chart: {
    //           type: 'area',
    //           height: '192px',
    //         },
    //         title: { text: '' },
    //         xAxis: {
    //           categories: lineCategories, // ใช้วันที่
    //         },
    //         yAxis: { visible: false, min: 0 },
    //         // tooltip: { shared: true, valueSuffix: ' หน่วย' },
    //         tooltip: {
    //           enabled: true, // เปิดการแสดงผล tooltip
    //           useHTML: true, // ใช้ HTML สำหรับการจัดรูปแบบ
    //           formatter: function () {
    //             return `
    //               <div style="text-align: left;">
    //                 <span style="font-weight: bold;border-bottom:1px solid #B1B1B1;font-size: 12px;">วันที่ ${this.key}</span>
    //                 <br>
    //                 <span style="font-weight: bold;color: #344BFD; font-size: 12px;">ค่า CCS<br><b>${this.y}<b></span>
    //               </div>
    //             `;
    //           },
    //           backgroundColor: '#fff', // สีพื้นหลัง
    //           borderColor: '#4C6EF5', // สีขอบ
    //           borderRadius: 8, // ความโค้งของ tooltip
    //           borderWidth: 1, // ความหนาของขอบ
    //           shadow: true, // แสดงเงา
    //           style: {
    //             color: '#555555', // สีตัวอักษร
    //             fontSize: '12px',
    //           },
    //         },
    //         plotOptions: {
    //           area: {
    //             fillColor: {
    //               linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
    //               stops: [
    //                 [0, '#4C6EF5'],
    //                 [1, 'rgba(177, 185, 248, 0)'],
    //               ],
    //             },
    //             marker: {
    //               radius: 5,
    //               fillColor: '#001DFF',
    //             },
    //             lineWidth: 2,
    //             states: { hover: { lineWidth: 3 } },
    //             threshold: null,
    //           },
    //         },
    //         series: [
    //           {
    //             type: 'area',
    //             name: 'วันที่',
    //             data: lineSeriesData, // ใช้ข้อมูลจาก data_line
    //             color: '#001DFF',
    //             marker: {
    //               enabled: true,
    //               symbol: 'circle',
    //               radius: 6,
    //               fillColor: '#001DFF',
    //             },
    //             dataLabels: {
    //               enabled: true,
    //               format: '{y}',
    //               style: { fontSize: '12px', fontWeight: 'bold' },
    //             },
    //           },
    //         ],
    //       };
    //     } else {
    //       const message = this.data.message;
    //       this.msgError = message;
    //       this.isLoadingAPI = true;
    //     }

    //   },
    //   error => {
    //     this.isLoadingAPI = true;
    //     console.error('API call failed', error);
    //   }
    // );
  }

  getDataTransport(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.token = localStorage.getItem('token');
      const storedbp_role_data = localStorage.getItem('bp_role_data');
      this.bp_role_data = storedbp_role_data ? JSON.parse(storedbp_role_data) : [];
      console.log(this.bp_role_data);
      console.log(this.token);
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'token': String(this.token)
    });
    const body = { month: this.month_tran, year: (this.year_tran! - 543), bp_role: this.bp_role_data, faccode: this.facecode_val , bpcode_contractor: this.bpcode_contractor_val };
    this.apiService.post('getDataTransport', body, headers).subscribe(
      data => {

        this.data = data;
        console.log(this.data);
        if (this.data.code == 1) {
          // console.log(this.data.data);
          // const lineCategories = Object.values(this.data.data).map(value => (value as [string, number])[0]); // ดึงวันที่
          // const lineSeriesData = Object.values(this.data.data).map(value => (value as [string, number])[1]); // ดึงค่า
          const lineCategories = Object.keys(this.data.data).map(key => String(key)); // แปลง keys เป็น string[]
          const lineSeriesData = Object.values(this.data.data) as number[]; // บังคับให้เป็น number[]
          // const lineSeriesData = Array.from({ length: 31 }, () => Math.floor(Math.random() * 100));
          // console.log(lineCategories);
          console.log(lineSeriesData);

          // Line Chart
          this.lineChartOptions_transport = {
            chart: {
              type: 'area',
              height: '192px',
            },
            title: { text: '' },
            xAxis: {
              categories: lineCategories, // ใช้วันที่
            },
            yAxis: { visible: false, min: 0 },
            tooltip: {
              enabled: true, // เปิดการแสดงผล tooltip
              useHTML: true, // ใช้ HTML สำหรับการจัดรูปแบบ
              formatter: function () {
                return `
                  <div style="text-align: center;">
                    <span style="font-weight: bold;border-bottom:1px solid #B1B1B1;font-size: 12px;">วันที่ ${this.key}</span>
                    <br>
                    <span style="font-weight: bold;color: #344BFD; font-size: 12px;">${this.y} เที่ยว</span>
                  </div>
                `;
              },
              backgroundColor: '#fff', // สีพื้นหลัง
              borderColor: '#4C6EF5', // สีขอบ
              borderRadius: 8, // ความโค้งของ tooltip
              borderWidth: 1, // ความหนาของขอบ
              shadow: false, // แสดงเงา
              style: {
                color: '#555555', // สีตัวอักษร
                fontSize: '12px',
              },
            },
            plotOptions: {
              area: {
                fillColor: {
                  linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                  stops: [
                    [0, '#4C6EF5'],
                    [1, 'rgba(177, 185, 248, 0)'],
                  ],
                },
                marker: {
                  radius: 5,
                  fillColor: '#001DFF',
                },
                lineWidth: 2,
                states: { hover: { lineWidth: 3 } },
                threshold: null,
              },
            },
            series: [
              {
                type: 'area',
                name: 'จำนวนเที่ยว',
                data: lineSeriesData, // ใช้ข้อมูลจาก data_line
                color: '#001DFF',
                marker: {
                  enabled: true,
                  symbol: 'circle',
                  radius: 2,
                  fillColor: '#001DFF',
                },
                dataLabels: {
                  enabled: true,
                  format: '{y}',
                  style: { fontSize: '12px', fontWeight: 'bold' },
                },
              },
            ],
          };
        } else {
          const message = this.data.message;
          this.msgError = message;
          this.isLoadingAPI = true;
        }

      },
      error => {
        this.isLoadingAPI = true;
        console.error('API call failed', error);
      }
    );
  }

  clickTabs(tabType: string): void {
    console.log(`Selected tab: ${tabType}`);
    // คุณสามารถเพิ่ม logic เพิ่มเติมได้ที่นี่
    if (tabType === 'CONTRUCTOR') {
      console.log('Handling logic for CONTRUCTOR tab');
      this.nav_link_active_contractor = 'active';
      this.tab_pane_contractor_show_active = 'show active';
    } else if (tabType === 'QUOTA') {
      console.log('Handling logic for QUOTA tab');
      this.nav_link_active_quota = 'active';
      this.tab_pane_quota_show_active = 'show active';
    }
  }

  convertMonthToThai(month: number): string {
    // ชื่อเดือนภาษาไทยแบบเต็ม
    const monthsInThai: string[] = [
      'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม',
      'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม',
      'พฤศจิกายน', 'ธันวาคม'
    ];

    // ตรวจสอบว่าหมายเลขเดือนอยู่ในช่วงที่ถูกต้อง (1 ถึง 12)
    if (month < 1 || month > 12) {
      throw new Error('หมายเลขเดือนต้องอยู่ระหว่าง 1 ถึง 12');
    }

    // คืนค่าชื่อเดือนภาษาไทย
    return monthsInThai[month - 1];
  }

  renderDataDashboard(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.token = localStorage.getItem('token');
      const storedbp_role_data = localStorage.getItem('bp_role_data');
      this.bp_role_data = storedbp_role_data ? JSON.parse(storedbp_role_data) : [];
      console.log(this.bp_role_data);
      console.log(this.token);
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'token': String(this.token)
    });
    const body = { bp_role: this.bp_role_data };
    this.apiService.post('renderDataDashboard', body, headers).subscribe(
      data => {

        this.data = data;
        console.log(this.data);
        if (this.data.code == 1) {
          this.list_facecode = this.data.data.faccode;
          this.list_beyear = this.data.data.beyear;
          console.log(this.list_facecode);
          this.facecode_val = this.data.data.faccode[0].value;
          this.beyear_val = this.data.data.beyear[0].value;

          this.getDashboard();
          this.getCCSRanking();
        } else {
          const message = this.data.message;
          this.msgError = message;
          this.isLoadingAPI = true;
        }

      },
      error => {
        this.isLoadingAPI = true;
        console.error('API call failed', error);
      }
    );
  }

  // เรียกใช้เมื่อ facecode เปลี่ยน
  onFacecodeChange() {
    const selectedFacecode = this.facecodeSelect.nativeElement.value;
    console.log('Selected Facecode:', selectedFacecode);
    this.facecode_val = selectedFacecode;

    this.getDashboard();
    this.getDataTransport();
    this.getCCSRanking();
  }

  // เรียกใช้เมื่อ beyear เปลี่ยน
  onBeyearChange() {
    const selectedBeyear = this.beyearSelect.nativeElement.value;
    console.log('Selected Beyear:', selectedBeyear);
    this.beyear_val = selectedBeyear;

    this.getDashboard();
    this.getDataTransport();
    this.getCCSRanking();
  }

  onbpCodeQuotaChange(){
    const bpcode_quota = this.bpcode_quota.nativeElement.value;
    console.log('Selected bpcode_quota:', bpcode_quota);
    this.bpcode_quota_val = bpcode_quota;
    this.getDashboard();
    this.getCCSRanking();
  }

  onbpCodeConChange(){
    const bpcode_contractor = this.bpcode_contractor.nativeElement.value;
    console.log('Selected bpcode_contractor:', bpcode_contractor);
    this.bpcode_contractor_val = bpcode_contractor;
    this.getDashboard();
    this.getDataTransport();
  }

  onbpCodeQuotaTapChange(){
    const bpcode_quota = this.bpcode_quota_tap.nativeElement.value;
    console.log('Selected bpcode_quota_tap:', bpcode_quota);
    this.bpcode_quota_val = bpcode_quota;
    this.getDashboard();
    this.getCCSRanking();
  }

  onbpCodeConTapChange(){
    const bpcode_contractor = this.bpcode_contractor_tap.nativeElement.value;
    console.log('Selected bpcode_contractor_tap:', bpcode_contractor);
    this.bpcode_contractor_val = bpcode_contractor;
    this.getDashboard();
    this.getDataTransport();
  }

  sortKey: keyof typeof this.list_CCSRanking[0] = 'id'; 
  sortDirection: string = '';

  get sortedData() {
    return [...this.list_CCSRanking].sort((a, b) => {
      if (!this.sortKey) return 0;
  
      let valueA = a[this.sortKey] ?? ''; // จัดการค่า null หรือ undefined
      let valueB = b[this.sortKey] ?? '';
  
      if (this.sortKey === 'cane_weight' || this.sortKey === 'avg_ccs') {
        valueA = parseFloat((valueA as string).replace(/,/g, '').replace('ตัน', '').trim()) || 0;
        valueB = parseFloat((valueB as string).replace(/,/g, '').replace('ตัน', '').trim()) || 0;
      }
  
      if (valueA < valueB) return this.sortDirection === 'asc' ? -1 : 1;
      if (valueA > valueB) return this.sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  }
  
  sortTable(key: keyof typeof this.list_CCSRanking[0]) {
    if (this.sortKey === key) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortKey = key;
      this.sortDirection = 'asc';
    }
  }
  

  getCCSRanking(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.token = localStorage.getItem('token');
      const storedbp_role_data = localStorage.getItem('bp_role_data');
      this.bp_role_data = storedbp_role_data ? JSON.parse(storedbp_role_data) : [];
      console.log(this.bp_role_data);
      console.log(this.token);
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'token': String(this.token)
    });
    const body = { bp_role: this.bp_role_data, faccode: this.facecode_val, beyear: this.beyear_val, bpcode_quota: this.bpcode_quota_val };
    this.apiService.post('getCCSRanking', body, headers).subscribe(
      data => {

        this.data = data;
        console.log(this.data);
        if (this.data.code == 1) {
          this.list_CCSRanking = this.data.data;
 
        } else {
          const message = this.data.message;
          this.msgError = message;
          this.isLoadingAPI = true;
        }

      },
      error => {
        this.isLoadingAPI = true;
        console.error('API call failed', error);
      }
    );
  }
}
