<div class="main-container-callweightqueue">
    <div class="backdrop-loading" *ngIf="!isLoadingAPIdashboard">
        <div class="row">
            <div class="col text-center">
                <div class="loader"></div>
            </div>
        </div>
    </div>
    <div class="container">
   
        <div class="row" >
            <div class="col-12">
                <div class="form-group">
                    <label for="" class="form-label">โรงงาน</label>
                    <select name="factory_code_id" id="factory_code_id" class="form-select"  (change)="onFactoryChange($event)">>
                        <option *ngFor="let factory of factoryList" [value]="factory.value">{{ factory.name }}</option>
                        <!-- <option value="MPK">MPK</option> -->
                    </select>
                </div>
            </div>
        </div>
        <div class="row mt-3" *ngIf="!isLoadingAPI">
            <div class="col text-center">
                <div class="loader"></div>
            </div>
        </div>
        <div class="row mt-3" *ngIf="isLoadingAPI">
            <div class="col-12 ">
                <div style="border: 1px solid #E5E5E5;"></div>
            </div>
            <div class="col-12 mt-3">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th width="40%" class="text-center bg-blue">เรียกคิวเข้าชั่ง</th>
                                <th colspan="2" class="text-center bg-blue">เริ่มต้น</th>
                                <th colspan="2" class="text-center bg-blue">สิ้นสุด</th>
                            </tr>
                            <tr>
                                <th width="40%" class="bg-text-blue">ชื่อคิว</th>
                                <th class="text-center bg-text-blue">รอบที่</th>
                                <th class="text-center bg-text-blue">คิวที่</th>
                                <th class="text-center bg-text-blue">รอบที่</th>
                                <th class="text-center bg-text-blue">คิวที่</th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- <tr>
                                <td>วิ่งตรง-สีล้อ</td>
                                <td class="text-center color-brown">1</td>
                                <td class="text-center color-brown">1</td>
                                <td class="text-center color-brown">200</td>
                                <td class="text-center color-brown">100</td>
                            </tr>
                            <tr>
                                <td>วิ่งตรง-สีล้อ</td>
                                <td class="text-center color-brown">1</td>
                                <td class="text-center color-brown">1</td>
                                <td class="text-center color-brown">200</td>
                                <td class="text-center color-brown">100</td>
                            </tr> -->
                         
                            <tr *ngFor="let list of data_list">
                                <td>{{list.name}}</td>
                                <td class="text-center color-brown">{{list.fromRoundNo}}</td>
                                <td class="text-center color-brown">{{list.lateQueueNumber}}</td>
                                <td class="text-center color-brown">{{list.toRoundNo}}</td>
                                <td class="text-center color-brown">{{list.toQueueNumber}}</td>
                            </tr>
                            <tr *ngIf="data_list.length == 0">
                                <td class="text-center" colspan="5">{{message}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>